.debug-summary__row {
	border-bottom: 1px solid var(--w-color-gray-lighter);
}

.debug-summary__row-searchable {
	cursor: pointer;
}

.debug-summary__key-cell,
.debug-summary__value-cell,
.debug-summary__search-cell {
	padding: 20px 5px 5px 5px;
}

.debug-summary__key-cell {
	font-size: 14px;
	font-weight: bold;
	text-align: right;
	width: 8rem;
}

.debug-summary__value-cell {
	font-size: .9285rem; /* 13px to ensure that SWID values fit... */
	width: auto;
}

.debug-summary__search-cell {
	padding: 0;
	vertical-align: bottom;
	text-align: center;
	width: 2rem;
}

.debug-summary__key,
.debug-summary__value {
	cursor: default;
}

.debug-summary__row-searchable .debug-summary__key,
.debug-summary__row-searchable .debug-summary__value {
	cursor: pointer;
}

.debug-summary__row-searchable .debug-summary__search {
	align-items: center;
	cursor: pointer;
	display: inline-flex;
	justify-content: center;
	opacity: 0;
}

.debug-summary__row-searchable:hover .debug-summary__search {
	opacity: 1;
}

.debug-summary__row-searchable:hover .debug-summary__search--hide {
	opacity: 0;
}

.debug-summary__value-copyable {
	cursor: copy;
	position: relative;
}

.debug-summary__row-searchable .debug-summary__value-copyable {
	cursor: copy;
}

.debug-summary__row-searchable .debug-summary__search {
	opacity: 0;
}

.debug-summary__value-copyable--copied:after {
	animation: copied-display 2s .2s;
	background: rgba(255,255,255,.95);
	border-radius: 334243423px;
	bottom: -20px;
	box-shadow: 0 2px 10px rgba(0,0,0,.15);
	color: #008bcc;
	content: 'Copied to clipboard';
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	left: 10px;
	min-width: 122px;
	opacity: 0;
	padding: 2px 10px;
	pointer-events: none;
	position: absolute;
	z-index: 13;
}
