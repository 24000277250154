@import '../../wham/scss/utilities/variables';

.logo {
  left: 50px;
  position: absolute;
  top: 50px;
  z-index: 2;
}

.version {
  bottom: 30px;
  color: $color-white;
  left: 50px;
  opacity: 0.67;
  position: absolute;
}

.layout {
  background: $color-gray-dark;
  border: 10px solid $color-white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;
}

.row {
  align-content: stretch;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  overflow-x: hidden;
}

.column {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  height: 100%;
  justify-content: center;
  max-width: 100%;
}

.content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 3rem;
}

.header_text {
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 0.05em;
  text-align: center;
}

.submit {
  width: 100%;
}

.message {
  position: relative;
}

.message_warn {
  background-color: $color-warning;
  color: $color-white;
  min-width: 350px;
  padding: 1.4286rem 2.14286rem;
}

.message_content {
  align-items: center;
  display: flex;
}

.message_icon {
  display: inline;
  margin: 0 1rem;
}

.error {
  background: #e8402a;
  border-top: 10px solid $color-white;
  border-right: 10px solid $color-white;
  border-left: 10px solid $color-white;
  display: flex;
  left: 0;
  position: absolute;
  height: 130px;
  top: 0;
  width: 100vw;
}

.error_text {
  color: $color-white;
  font-size: 1.35rem;
  margin: 32px auto;
  max-width: 50%;
}
