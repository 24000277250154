.simulator__content-upper {
	display: flex;
}

.simulator__transactions {
  width: 40%;
  margin-left: 10px;
}

.simulator__transactions__header {
  display: flex;
  justify-content: space-between;
}

.simulator__transactions__clear-all {
  cursor: pointer;
  color: var(--w-color-danger);
}

.simulator__transactions__expand-collapse {
  cursor: pointer;
}

.simulator__transaction td {
  min-height: unset;
  padding-top: 0;
  padding-bottom: 0;
}

.simulator__transaction--selected {
  background-color: var(--w-color-primary);
}

.simulator__transaction__brkdur {
  display: flex;
  align-items: center;
}

.simulator__transaction__remove {
  cursor: pointer;
  text-align: right;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  width: 25px;
  height: 25px;
}
