@import 'helpers';


@keyframes progress-circular-rotate {
 100%{
  transform: rotate(-360deg);
 }
}

@keyframes progress-circular-dash {
  0%{
    stroke-dasharray: 25, 100;
    stroke-dashoffset: -45;
 }
 50%{
   stroke-dasharray: 45;
   stroke-dashoffset: 0;
 }
 100%{
   stroke-dasharray: 25, 100;
   stroke-dashoffset: -45;
 }
}

.w-progress-circular {
  &__background,
  &__progress {
    fill: none;
  }

  &__background {
    stroke: rgba($color-primary, .2);
  }

  &__progress {
    stroke: $color-primary;
  }
}



.w-progress-circular--inverse {
  .w-progress-circular__background {
    stroke: rgba($color-white, .2);
  }

  .w-progress-circular__progress {
    stroke: $color-white;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.w-progress-circular--indeterminate {
  .w-progress-circular__container {
    animation-name: progress-circular-rotate;
    animation-duration: .67s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; //TODO: Make variable
    animation-play-state: paused;
    opacity: 0;
  }

  .w-progress-circular__background {
    stroke: transparent;
  }

  .w-progress-circular__progress {
    animation: progress-circular-dash 4s ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    stroke-dasharray: 0;
  }
  &.w-progress-circular--active {
    .w-progress-circular__container {
      opacity: 1;
    }
    .w-progress-circular__progress,
    .w-progress-circular__container {
      animation-play-state: running;
    }
  }
}
