@import 'helpers';

.w-message {
  position: relative;

  &__content {
    color: $color-gray;
    background: $color-gray-lighter;
    min-width: 350px;
    padding: $spacing-medium $spacing-large;

    &:empty {
      display: none;
    }
  }

  .w-close-btn {
    display: none;
    position: absolute;
    right: $spacing-small;
    top: 50%;
    transform: translateY(-50%);
  }

  &--floating {
    position: fixed;
    z-index: $z-index-message;
    bottom: -2rem;
    left: calc(50vw - 175px);
    transition: $transition-fast;
    box-shadow: $shadow-depth-3;
    opacity: 0;

    &--active {
      opacity: 1;
      bottom: 2rem;
    }

    .w-close-btn {
      display: inline-block;
    }
  }

  &--primary {
    .w-message__content {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &--danger {
    .w-message__content {
      background-color: $color-danger;
      color: $color-white;
    }
  }

  &--warning {
    .w-message__content {
      background-color: $color-warning;
      color: $color-white;
    }
  }

  &--success {
    .w-message__content {
      background-color: $color-success;
      color: $color-white;
    }
  }


}
