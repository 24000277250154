@import 'helpers';

.w-app-bar__context {
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  &__primary,
  &__secondary {
    display: block;
    text-align: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__primary {
    color: $color-gray-dark;
    font-weight: $font-weight-semibold;
  }

  &__secondary {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    color: $color-gray-light;
  }
}
