@import 'helpers';

.w-drop-area {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 0 5px $color-gray-lighter;

  &--overlay {
    cursor: inherit;
    box-shadow: none;

    .w-drop-area__message {
      display: none;
      visibility: hidden;
    }
  }

  &--active {
    .w-drop-area__message {
      display: none;
      visibility: hidden;
    }

    .w-drop-area__active-message {
      z-index: $z-index-modal;
      visibility: visible;
      background: rgba($color-primary, .9);
      color: #fff;
    }
    &.w-drop-area--accepted {
      .w-drop-area__active-message {
        background: rgba($color-primary, .9);
      }

      .w-drop-area__active-message--accepted {
        display: block;
      }

      .w-drop-area__active-message--rejected {
        display: none;
      }
    }

    &.w-drop-area--rejected {
      .w-drop-area__active-message {
        background: rgba($color-danger, .9);
      }
      .w-drop-area__active-message--accepted {
        display: none;
      }

      .w-drop-area__active-message--rejected {
        display: block;
      }
    }
  }
}

.w-drop-area__message,
.w-drop-area__active-message {
  visibility: hidden;
  pointer-events: none;
  display: none;
  padding: $spacing-medium;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.w-drop-area__message {
  visibility: visible;
  color: $color-gray-light;
}

.w-drop-area__icon {
  font-size: 72px;
}

.w-drop-area__title {
  display: block;
  font-size: $font-size-xlarge;
  font-weight: $font-weight-light;
}
