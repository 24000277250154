.macro-splitter__header,
.macro-splitter__url {
  margin: 0;
}

.macro-splitter__header {
  margin-bottom: 2rem;
  position: relative;
}

.macro-splitter__header-control {
  display: flex;
}

.macro-splitter__header-toggle {
  cursor: pointer;
  font-size: 1.4rem;
  margin: 0 0 1rem .5rem;
  padding: 0;
}

.macro-splitter__header-line {
  border-top: 1px solid var(--w-color-gray-lighter);
  height: 1px;
  position: absolute;
  top: 33px;
  width: 100%;
}

.macro-splitter__header-content,
.macro-splitter__url-content {
  border: 1px solid var(--w-color-gray-lighter);
  cursor: pointer;
  padding: .8rem;
}

.macro-modal__tabs .macro-splitter__header-content,
.macro-modal__tabs .macro-splitter__url-content {
  border: initial;
  cursor: initial;
  padding: 0;
}

.macro-splitter__header-content {
  transform-origin: top center;
  transition: opacity .5s, transform .5s ease-in-out;
}

.macro-splitter__header-content--expanded {
  opacity: 1;
  transform: scaleY(1);
}

.macro-splitter__header-content--collapsed {
  opacity: 0;
  transform: scaleY(0);
}

.macro-splitter__url {
  transform-origin: top center;
  transition: transform .5s ease-in-out;
}

.macro-splitter__url--expanded {
  transform: translateY(0);
}

.macro-splitter__url--collapsed {
  transform: translateY(-80px);
}

.macro-modal__tabs .macro-splitter__url--collapsed {
  transform: translateY(-50px);
}
