.creative-summary-record {
  display: flex;
  margin: 0 0 10px 0;
  width: 100%;
}

.creative-summary-record:hover {
  box-shadow: 0 0 0 2px var(--w-color-primary);
  text-decoration: none;
}

.creative-summary-record .w-icon:hover {
  cursor: pointer;
}

.creative-summary-record__tile {
  background: #fff;
  border: 1px solid var(--w-color-gray-lighter);
  color: var(--w-color-gray-light);
  display: grid;
  grid-template:
          "head head timestamp" 50px
          "vast path duration" 25px
          "vast path controls" 25px
          "vast path error" 25px
          / 175px minmax(auto, 450px) 225px;
  max-width: 850px;
  min-width: 340px;
  width: calc(100vw - 480px);
}

.creative-summary-record__head {
  align-items: center;
  display: flex;
  grid-area: head;
  padding: 0 14px;
}

.creative-summary-record__head-icon {
  color: var(--w-color-gray);
}

.creative-summary-record__head-icon--ready {
  color: var(--w-color-success);
}

.creative-summary-record__head-icon--blocked {
  color: var(--w-color-danger);
}

.creative-summary-record__head-icon--pending {
  color: var(--w-color-alternative);
}

.creative-summary-record__head-icon--retranscode {
  color: var(--w-color-warning);
}

.creative-summary-record__head-mezz {
  color: var(--w-color-gray);
  font-size: 22px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100vw - 760px);
}

.creative-summary-record__vasts {
  display: flex;
  flex-direction: column;
  grid-area: vast;
  padding: 0 14px;
}

.creative-summary-record__vast {
  align-items: center;
  display: flex;
  height: 25px;
}

.creative-summary-record__vast-id {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creative-summary-record__paths {
  display: flex;
  flex-direction: column;
  grid-area: path;
}

.creative-summary-record__vast-paths {
  height: 25px;
}

.creative-summary-record__timestamp {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-area: timestamp;
  padding: 0 14px;
}

.creative-summary-record__duration {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-area: duration;
  padding: 0 14px;
}

.creative-summary-record__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-area: controls;
  padding: 0 14px;
}

.creative-summary-record__controls-button {
  border: none;
  box-shadow: none;
  display: flex;
  height: 25px;
  justify-content: center;
  padding: 0;
  width: 25px;
}

.creative-summary-record__controls-button:focus {
  box-shadow: none;
}

.creative-summary-record__controls-button:hover {
  color: var(--w-color-primary);
  box-shadow: none;
}

.creative-summary-record__controls-icon {
  font-size: 20px;
}

.creative-summary-record__controls-button .creative-summary-record__controls-icon:hover {
  color: var(--w-color-primary);
  cursor: pointer;
}

.creative-summary-record__play-loading {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-left: 10.5px;
  padding: 6px 0 0 0;
  width: 25px;
}

.creative-summary-record__play-playing {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-left: 10.5px;
  padding: 0;
  width: 25px;
}

.creative-summary-record__play-error {
  align-items: center;
  color: var(--w-color-warning);
  display: flex;
  font-size: 20px;
  height: 25px;
  justify-content: center;
  margin-left: 10.5px;
  padding: 0 4px 0 1px;
}

.creative-summary-record__redo-loading {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: center;
  margin-right: 10.5px;
  padding: 6px 0 0 0;
  width: 25px;
}

.creative-summary-record__redo-done {
  align-items: center;
  color: var(--w-color-gray-lighter);
  display: flex;
  font-size: 20px;
  height: 25px;
  justify-content: center;
  margin-right: 10.5px;
  padding: 0 2px 0 3px;
}

.creative-summary-record__redo-error {
  align-items: center;
  color: var(--w-color-warning);
  display: flex;
  font-size: 20px;
  height: 25px;
  justify-content: center;
  margin-right: 10.5px;
  padding: 0 2px 0 3px;
}

.creative-summary-record__error {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-area: error;
  padding: 0 14px;
}

.creative-summary-record__error-text {
  color: var(--w-color-danger);
}

.creative-summary-record__copyable {
  cursor: copy;
}

.creative-summary-record__copied {
  cursor: default;
}
