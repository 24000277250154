@import '../../wham/scss/utilities/variables';
@import '../../scss/mixins/buttons';
@import '../../scss/mixins/clipboard';
@import '../../scss/mixins/icons';
@import '../../scss/mixins/utilities';

$cta-width: 135px;

.section {
  display: flex;
  flex-direction: column;
  margin: 36px 0 0 0;
  max-width: 950px;
  padding: 30px 20px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 78px;

  &_left {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - #{$cta-width});

    &_upper {
      align-items: center;
      display: flex;
      height: 25px;
      margin: 0 0 5px 0;
    }

    &_lower {
      align-items: center;
      display: flex;
      height: 21px;
    }
  }

  &_right {
    display: flex;
    flex-direction: column;
    width: $cta-width;
  }
}

.mezz {
  @include ellipsis-truncate;

  color: $color-gray;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  height: 25px;
  line-height: 25px;
  padding: 0 0 0 5px;
  width: 100%;

  &_wrap {
    display: flex;
    width: calc(100% - 50px);
  }
}

.button {
  @include button;

  &__safari {
    padding: 0 20px 32px 20px;
  }
}

.play {
  background-color: $color-primary;
  color: $color-white;
  width: $cta-width;

  &:disabled {
    opacity: .7;
  }

  &:hover {
    border-color: $color-primary-dark;
    background-color: $color-primary-dark;
  }

  &_loading {
    margin: 6px 11px 0 -10px;
  }

  &_playing {
    margin: 0 10px 0 -13px;
    width: 20px;
  }

  &_icon {
    @include icon;
    margin: 0 8px 0 -15px;
    pointer-events: none;
  }
}

.validate {
  margin-top: 10px;
  width: $cta-width;

  &_failed {
    border-color: $color-danger;
  }

  &_checking {
    margin: 6px 18px 0 -18px;
  }

  &_icon {
    @include icon;

    font-size: 20px;
    margin: 0 16px 0 -20px;
    pointer-events: none;

    &_failed {
      color: $color-danger;
    }
  }
}

.redo {
  margin-top: 10px;
  width: $cta-width;

  &_error {
    border-color: $color-warning;
  }

  &_redoing {
    margin: 6px 8px 0 2px;
  }

  &_icon {
    @include icon;

    font-size: 20px;
    margin: 0 8px 0 -2px;
    pointer-events: none;

    &_error {
      color: $color-warning;
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  flex-direction: column;
}

.copyable {
  @include copy-to-clipboard;
  display: flex;
}

.copied {
  @include copied-to-clipboard;
}

.player {
  &_cancel {
    @include player-button;

    &_icon {
      @include player-button-icon;
    }
  }
}
