.header-mega-menu__control {
	align-items: center;
	border-right: 1px solid var(--w-color-gray-lighter);
	border-bottom: 1px solid var(--w-color-gray-lighter);
	display: flex;
	height: 70px;
	justify-content: center;
	width: 140px;
}

.header-mega-menu__button {
	align-items: center;
	background-color: var(--w-color-white);
	border: none;
	color: var(--w-color-gray);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin: 0;
	outline: none;
	padding: 24px 0 0 0;
	position: relative;
	width: 100%;
}

.header-mega-menu__button--open {
	background-color: var(--w-color-primary);
	color: var(--w-color-white);
	z-index: 10002;
}

.header-mega-menu__button:hover {
	background-color: var(--w-color-primary);
	color: var(--w-color-white);
}

.header-mega-menu__more {
	cursor: pointer;
	color: var(--w-color-white);
}

.header-mega-menu__more--open,
.header-mega-menu__more--open:hover {
	color: var(--w-color-primary);
}

.header-mega-menu__icon {
	cursor: pointer;
}

.header-mega-menu__text {
	color: var(--w-color-gray);
	cursor: pointer;
	font-size: 1.1rem;
	font-weight: 500;
}

.header-mega-menu__button:hover .header-mega-menu__text {
	color: var(--w-color-white);
}

.header-mega-menu__content {
	background-color: var(--w-color-white);
	box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
	display: flex;
	flex-direction: column;
	left: 140px;
	position: absolute;
	top: 70px;
	z-index: 10002;
}

.header-mega-menu__menu {
	display: flex;
	max-height: calc(100vh - 140px);
}

.header-mega-menu__scroll {
	align-items: center;
	display: flex;
	height: 1rem;
	justify-content: center;
}

.header-mega-menu__scroll-icon {
	color: var(--w-color-white);
	font-size: 1.4rem;
}

.header-mega-menu__scroll-icon--show {
	color: var(--w-color-gray-light);
}

.header-mega-menu__sections {
	display: flex;
	flex-direction: column;
	margin: 0 1rem;
	overflow-y: scroll;
}

.header-mega-menu__content--styled .header-mega-menu__sections {
	flex-wrap: wrap;
	overflow-y: initial;
}

.header-mega-menu__section {
	margin: .5rem;
	width: 200px;
}

.header-mega-menu__section-title {
	color: var(--w-color-gray);
	font-size: 1.1rem;
	font-weight: bold;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.header-mega-menu__option {
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.header-mega-menu__option--selected {
	background-color: var(--w-color-primary);
	color: var(--w-color-white);
}

.header-mega-menu__option--selected .header-mega-menu__option-link {
	color: var(--w-color-white);
	font-weight: bold;
}

.header-mega-menu__option:hover {
	background-color: var(--w-color-primary-light);
	cursor: pointer;
}

.header-mega-menu__option.header-mega-menu__option--selected:hover {
	background-color: var(--w-color-primary);
}

.header-mega-menu__option-link {
	color: inherit;
	display: flex;
	padding: 1rem .5rem;
	text-decoration: none;
}

.header-mega-menu__option-link:hover {
	text-decoration: none;
}

.header-mega-menu__background {
	height: 100vh;
	left: 0;
	position: absolute;
	top: 0;
	width: 100vw;
	z-index: 10001;
}
