.dashboard-tile {
	align-items: center;
	background-color: var(--w-color-white);
	border-radius: 5px;
	box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .1);
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	height: 200px;
	margin: 15px;
	max-width: 300px;
	min-width: 300px;
	width: 300px;
}

.dashboard-tile__link {
	border: 1px solid var(--w-color-gray-light);
	border-radius: 5px;
	height: 100%;
	width: 100%;
}

.dashboard-tile__link:hover {
	box-shadow: 0 0 0 2px var(--w-color-primary);
	text-decoration: none;
}

.dashboard-tile__container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.dashboard-tile__upper {
	display: flex;
	height: 70px;
	max-height: 70px;
	min-height: 70px;
}

.dashboard-tile__icon-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	max-width: 70px;
	min-width: 70px;
	width: 70px;
}

.dashboard-tile__icon {
	color: var(--w-color-gray);
	cursor: pointer;
	font-size: 36px;
}

.dashboard-tile__icon--rules {
	color: var(--w-color-alternative);
}

.dashboard-tile__icon--transactions {
	color: var(--w-color-primary);
}

.dashboard-tile__icon--creatives {
	color: var(--w-color-success);
}

.dashboard-tile__icon--analytics {
	color: var(--w-color-danger);
}

.dashboard-tile__lower {
	display: flex;
	height: 100%;
}

.dashboard-tile__title-wrap {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: calc(100% - 70px);
}

.dashboard-tile__title {
	color: var(--w-color-gray);
	font-size: 22px;
	font-weight: bold;
	padding: 0 2.5rem 0 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dashboard-tile__caption-wrap {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
}

.dashboard-tile__caption {
	color: var(--w-color-gray);
	font-size: 16px;
}
