@import 'helpers';

.w-image {
  max-width: 100%;
  max-height: 100%;

  &__caption {
    width: 90%;
    display: block;
    text-align: center;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    color: $color-gray-light;
    margin: 0 auto;
  }
}

.w-image--sizing-fit {
  display: block;
  height: 100%;
  width: 100%;

  img {
    object-fit: scale-down;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

.w-image--sizing-contain {
  display: block;
  height: 100%;
  width: 100%;

  img {
    object-fit: contain;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

.w-image--sizing-fill {
  height: 100%;
  width: 100%;
  display: block;

  img {
    object-fit: cover;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

.w-image--expandable {
  cursor: zoom-in;

  &.w-image--expanded {
    cursor: zoom-out;
    position: fixed;
    z-index: $z-index-modal;
    display: block;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    background: rgba($color-white, .8);

    img {
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
      display: block;
      max-width: 90vw;
      max-height: 90vh;
      object-fit: contain;
    }
  }
}
