.documentation-view {
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	width: 100%;
	overflow-y: scroll;
}

.documentation-view__header-row {
	display: flex;
	justify-content: center;
}

.documentation-view__content-row {
	max-width: 60rem;
	margin: 0 auto;
	padding: 2rem;
}

.documentation-view__content-row p {
	margin: 1em 0;
}

.documentation-view__content-row .w-heading {
	margin: .5em 0;
}

.documentation-view__content-row .w-heading--level-1 {
	font-size: var(--w-font-size-xxlarge);
}

.documentation-view__content-row .w-heading--level-2 {
	font-size: var(--w-font-size-xlarge);
}

.documentation-view__content-row .w-heading--level-3 {
	font-size: var(--w-font-size-large);
}

.documentation-view__content-row .w-heading--level-4 {
	font-size: var(--w-font-size-medium);
	font-weight: var(--w-font-weight-bold);
	margin: 1em 0;
}

.documentation-view__content-row .w-heading--level-5 {
	font-size: var(--w-font-size-medium);
}

.documentation-view__content-row table,
.documentation-view__content-row th,
.documentation-view__content-row td {
	border: 1px solid var(--w-color-gray-lighter);
	padding: .5em;
}
