.uri-list__header {
	font-weight: var(--w-font-weight-semibold);
	font-size: 11px;
	padding: 10px;
}

.uri-list__header a {
	float: right
}

.uri-list__list {
	max-height: calc(100vh - 200px);
	overflow-y: scroll;
}
