.w-app-bar__search__field:before {
  display: none;
}

.uri-field {
  height: 100%;
  max-width: 50vw;
}

.uri-field__heading {
  font-weight: bold;
  text-align: center;
}

.create-edit-rule {
  height: 100%;
  background: var(--w-color-off-white);
}

.create-edit-rule__message {
  width: 100%;
  text-align: center;
}

.create-edit-rule__message > .w-message__content {
  color: var(--w-color-white);
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.create-edit-rule__message--changed > .w-message__content {
  background-color: var(--w-color-alternative)
}

.create-edit-rule__message-action {
  color: var(--w-color-white);
  padding-left: .8rem;
  text-decoration: underline;
}

.create-edit-rule--content {
  height: 100%;
  opacity: 1;
  transition: opacity 1s, visibility 1s;
}

.create-edit-rule--content--hidden {
  opacity: 0;
  visibility: hidden;
}

.split-view-wrapper {
  height: 100%;
}

.split-view-wrapper .w-layout__column {
  height: auto;
  width: 44vw;
}

.conditions-editor__heading {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.edit-macro {
  display: flex;
  flex-direction: column;
}

.edit-macro__url {
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

.edit-macro__url--invalid textarea {
  border: 1px solid var(--w-color-danger);
}

.edit-macro__url--invalid textarea:focus {
  border: 1px solid transparent;
  box-shadow: 0 0 0 2px var(--w-color-danger);
}

.edit-macro__warn {
  color: var(--w-color-danger);
  display: inline-block;
  height: 35px;
  padding: .3rem 0;
  width: 100%;
}

.edit-macro__warn--hide {
  opacity: 0;
}

.edit-macro__warn--show {
  opacity: 1;
}

.is-global .ad-field {
  max-width: 36vw;
}

.ad-field {
  height: 100%;
  min-height: 20vh;
  align-items: center;
  word-break: break-all;
  white-space: normal;
  font-size: 16px;
}

.ad-field .w-tabs {
  height: 100%;
}

.ad-field .textpart-key {
  color: var(--w-color-success);
}

.ad-field .textpart-value {
  color: var(--w-color-primary-dark);
}

.ad-field .textpart-macro {
  color: var(--w-color-alternative);
}

.ad-field .textpart-optional {
  color: var(--w-color-danger-dark);
}

.save-rule {
  width: 100%;
  margin: 2rem 0;
}

.error-message {
  width: 100%;
  text-align: center;
  background: red;
  color: white;
}

.param-table tr td textarea {
  color: var(--w-color-primary);
}

.param-table tr td:first-child input {
  color: var(--w-color-success);
}

.in-page-modal--content > .w-layout.w-layout--spacing-default {
  width: 100%;
}

.conditions-editor {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.conditions-editor__sub {
  color: #1a1a1a;
  font-weight: bold;
  margin: 0 0 1rem 0;
}

.condition-group {
  display: flex;
  margin-bottom: 6px;
}

.condition-group__name {
  flex: 4;
}

.condition-group__name > .w-select {
  width: 100%;
}

.condition-group__name--add select {
  color: var(--w-color-gray-light);
  font-style: italic;
}

.condition-group__value {
  display: block;
  flex: 7;
  position: relative;
}

.condition-group__value-button {
  display: inline;
  width: 4rem;
}

.condition-group__value-button > .w-btn {
  border: none;
  padding: 0;
  width: calc(100% - .4rem);
  z-index: 5;
}

.condition-group__value-button > .is-button {
  box-shadow: 0 0 0 1px var(--w-color-success);
}

.condition-group__value-button > .is-button:focus,
.condition-group__value-button > .is-button:hover {
  color: var(--w-color-success);
}

.condition-group__value-button > .is-not-button {
  box-shadow: 0 0 0 1px var(--w-color-danger);
}

.condition-group__value-button > .is-not-button:focus,
.condition-group__value-button > .is-not-button:hover {
  color: var(--w-color-danger);
}

.condition-group__value-input {
  display: inline-block;
  width: calc(100% - 4rem);
}

.condition-group .w-badge--small {
  position: absolute;
  align-self: center;
  left: -8px;
  background: var(--w-color-primary);
  margin-top: -10px;
}

.condition-group .w-input--text {
  margin-left: 1px;
  width: 100%;
}

.condition-group input {
  border-radius: 0 .225rem .225rem 0;
}

.condition-group button {
  border-radius: .225rem 0 0 .225rem;
  margin-left: 0.5rem;
}

.condition-group .condition-group__remove-icon {
  align-self: center;
  color: var(--w-color-gray-lighter);
  cursor: pointer;
  padding: 0 0.5rem;
  position: absolute;
  opacity: 0;
  top: 5px;
  right: -40px;
  z-index: 5;
}

.condition-group:hover .condition-group__remove-icon,
.condition-group .condition-group__remove-icon:hover {
  opacity: 1;
}

.condition-group . w-input--fullwidth {
  width: 100%;
}

.is-button {
  max-width: 3.5rem;
  border-color: var(--w-color-success);
  background: var(--w-color-success-lighter);
  color: var(--w-color-success);
}

.is-not-button {
  max-width: 3.5rem;
  border-color: var(--w-color-danger);
  background: var(--w-color-danger-lighter);
  color: var(--w-color-danger);
}

.priority-sorter-wrapper {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
  margin-top: 4rem;
}

.priority-sorter {
  border: 1px solid var(--w-color-gray-lighter);
  padding: 0.5rem;
  width: 100%;
  height: 200px;
  overflow: auto;
}

.sortable-item {
  padding: 0.6rem;
  color: var(--w-color-gray-light);
}

.sortable-item.active {
  color: black;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  position: relative;
}

.sortable-item .w-middle-truncate {
  width: calc(100% - 5rem);
}

.sortable-item.active:hover {
  background: var(--w-color-off-white);
}

.sortable-handle {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.dragging-helper-class {
  top: 0;
  padding: 0;
  height: 1.5rem !important;
  border-bottom: 0.2rem solid var(--w-color-primary);
  color: white  !important;
  text-align: center;
}

.sortable-item--dragging{
  display: none;
}

.dragging-helper-class .sortable-item--label {
  display: none;
}

.dragging-helper-class .sortable-item--dragging {
  display: inline;
  height: 1rem;
  background: var(--w-color-primary);
  position: relative;
  top: 0.7rem;
  border-radius: 0.5rem;
  padding: 0 0.6rem;
}

.w-layout.w-layout--spacing-default >
.w-layout__row>.w-layout__column >
.w-layout__column__content {
  padding: 3rem;
}

#rule-name {
  font-size: 28px;
  line-height: 68px;
  height: unset;
  border: none;
  outline: 0;
  padding: 0 3rem;
  background: var(--w-color-off-white);
}

.rule-input-wrapper {
  width: 100%;
  border-bottom: 1px solid var(--w-color-gray-lighter);
  display: flex;
  justify-content: center;
}

.priority-helper-wrapper {
  position: relative;
  text-align: center;
  height: 0;
}

.higher-helper,
.lower-helper {
  visibility: hidden;
  top: -12px;
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 11px;
  width: 85px;
  height: 18px;
  border-radius: 9px;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,0.1);
  background-color: #ffffff;
}

.priority-sorter-wrapper:hover .higher-helper,
.priority-sorter-wrapper:hover .lower-helper {
  visibility: visible;
}

.auto-move {
  border: 1px solid var(--w-color-gray-lighter);
  border-bottom: 0;
  padding: 11px;
}

.auto-move span {
  color: var(--w-color-primary);
  font-size: 11px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 500;
}

.header-wrapper .w-app-bar-2 {
  transition: background 0.5s;
}

.header-wrapper:not(.is-active) .w-app-bar-2 {
  background: var(--w-color-gray);
}

.header-wrapper:not(.is-active) .left-default-wrapper {
  color: white;
}

.header-wrapper:not(.is-active) .w-app-bar__context__primary,
.header-wrapper:not(.is-active) .w-app-bar__context__secondary {
  color: white;
}

.header-wrapper .w-app-bar-2 .w-app-bar__nav-right,
.header-wrapper .w-app-bar__context,
.header-wrapper .left-default-wrapper {
  flex-grow: 1;
  max-width: 33%;
}

.header-wrapper .w-app-bar-2 .w-app-bar__nav-left {
  flex-grow: 0;
}

.create-edit-rule.is-global .global-rule-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.create-edit-rule.is-global .global-rule-warning .w-message__content {
  color: white;
  text-align: center;
  background: var(--w-color-alternative);
}

.create-edit-rule .w-app-bar__nav-item div {
  display: flex;
}

.create-edit-rule .clone-button span + span {
  font-size: var(--w-font-size-medium);
}

.create-edit-rule__enter-url {
  cursor: pointer;
}

.rule-header-wrapper .w-layout__column__content {
  padding: 0;
}

.header-wrapper:not(.is-active) .header {
  background-color: var(--w-color-gray);
}

.header-wrapper:not(.is-active) .rule-header-nav {
  color: var(--w-color-white);
}

.rule-header-nav {
  align-items: center;
  display: flex;
  width: 200px;
}

.rule-header-back {
  cursor: pointer;
  margin: 0;
  padding: 0 1rem;
}

.rule-name--untitled {
  font-style: italic;
}

.rule-header-nav .w-app-bar__subject__navigation {
  display: flex;
}

.rule-header-logo {
  display: flex;
}

.rule-header-name {
  padding: 0 0 0 1rem;
}

.rule-header-controls {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin: 0 10px;
  width: 200px;
}

.rule-header-controls-group {
  display: flex;
}

.rule-content-failed {
  padding: 36px 0 0 0;
}

.rule-builder-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rule-builder__cannot-edit {
  font-weight: bold;
  color: var(--w-color-danger);
}
