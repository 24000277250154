.whitelist-sidebar-item {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 2rem;
	position: relative;
}

.whitelist-sidebar-item__icon--clickable {
	align-items: center;
	display: inline-flex;
	height: 38px;
	justify-content: center;
	opacity: 0;
	position: absolute;
	right: 4px;
	top: 50%;
	transform: translateY(-50%);
	width: 38px;
}

.whitelist-sidebar-item:hover .whitelist-sidebar-item__icon--clickable {
	cursor: pointer;
	opacity: 1;
}

.whitelist-sidebar-item__description {
	cursor: default;
	display: flex;
	font-weight: 400;
}

.whitelist-sidebar-item__description span {
	padding: 0 0.6rem 0 0;
}
