.creatives-filter-by-status {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.creatives-filter-by-status__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.creatives-filter-by-status__title-wrapper {
    align-items: center;
    display: flex;
}

.creatives-filter-by-status__title-icon {
    color: var(--w-color-gray-light);
    cursor: pointer;
    font-size: 1.4rem;
}

.creatives-filter-by-status__title {
    color: var(--w-color-gray-dark);
    font-weight: 600;
}

.creatives-filter-by-status__content {
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 0;
}

.creatives-filter-by-status__option {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.creatives-filter-by-status__option-wrapper {
    align-items: center;
    display: flex;
    height: 21px;
    position: relative;
    width: 100%;
}

.creatives-filter-by-status__option-input {
    cursor: pointer;
}

.creatives-filter-by-status--pending .creatives-filter-by-status__option-input {
    cursor: default;
}

.creatives-filter-by-status__option-label {
    background-color: var(--w-color-off-white);
    color: var(--w-color-gray-dark);
    cursor: pointer;
    max-width: 140px;
    overflow: hidden;
    padding: 0 0 0 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.creatives-filter-by-status--pending .creatives-filter-by-status__option-label {
    color: var(--w-color-gray-light);
    cursor: default;
}

.creatives-filter-by-status__option-label.creatives-filter-by-status__option-label--expanded {
    border-radius: 4px;
    box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
    left: 12px;
    overflow: visible;
    margin: 0 2px;
    max-width: initial;
    position: absolute;
    padding: 0 5px;
    top: 0;
    width: initial;
    z-index: 1;
}

.creatives-filter-by-status__option:hover .creatives-filter-by-status__option-label {
    color: var(--w-color-primary);
}

.creatives-filter-by-status--pending .creatives-filter-by-status__option:hover .creatives-filter-by-status__option-label {
    color: var(--w-color-gray-light);
}
