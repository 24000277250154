.anchor-control-left {
  position: relative;
  float: left;
  z-index: 99;
  display: flex;
  font-size: 16px;
  padding: 0 2rem;
}

.anchor-control-right {
  position: relative;
  z-index: 99;
  float: right;
  display: flex;
  font-size: 16px;
  padding: 0 2rem;
}

.transaction-navigation-tab {
  width: 100%;
}

