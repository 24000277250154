@charset 'UTF-8';

@import 'base/all';
@import 'helpers';

:root {
  /* Colors */
  --w-color-primary: #{$color-primary};
  --w-color-primary-light: #{$color-primary-light};
  --w-color-primary-lighter: #{$color-primary-lighter};
  --w-color-primary-dark: #{$color-primary-dark};
  --w-color-primary-darker: #{$color-primary-darker};

  --w-color-success: #{$color-success};
  --w-color-success-light: #{$color-success-light};
  --w-color-success-lighter: #{$color-success-lighter};
  --w-color-success-dark: #{$color-success-dark};
  --w-color-success-darker: #{$color-success-darker};

  --w-color-warning: #{$color-warning};
  --w-color-warning-light: #{$color-warning-light};
  --w-color-warning-lighter: #{$color-warning-lighter};
  --w-color-warning-dark: #{$color-warning-dark};
  --w-color-warning-darker: #{$color-warning-darker};

  --w-color-danger: #{$color-danger};
  --w-color-danger-light: #{$color-danger-light};
  --w-color-danger-lighter: #{$color-danger-lighter};
  --w-color-danger-dark: #{$color-danger-dark};
  --w-color-danger-darker: #{$color-danger-darker};

  --w-color-alternative: #{$color-alternative};
  --w-color-alternative-light: #{$color-alternative-light};
  --w-color-alternative-lighter: #{$color-alternative-lighter};
  --w-color-alternative-dark: #{$color-alternative-dark};
  --w-color-alternative-darker: #{$color-alternative-darker};

  --w-color-gray: #{$color-gray};
  --w-color-gray-light: #{$color-gray-light};
  --w-color-gray-lighter: #{$color-gray-lighter};
  --w-color-gray-dark: #{$color-gray-dark};
  --w-color-off-white: #{$color-off-white};
  --w-color-white: #{$color-white};

  --w-color-extended-pink: #{$color-extended-pink};
  --w-color-extended-pink-light: #{$color-extended-pink-light};
  --w-color-extended-pink-dark: #{$color-extended-pink-dark};

  --w-color-extended-magenta: #{$color-extended-magenta};
  --w-color-extended-magenta-light: #{$color-extended-magenta-light};
  --w-color-extended-magenta-dark: #{$color-extended-magenta-dark};

  --w-color-extended-cyan: #{$color-extended-cyan};
  --w-color-extended-cyan-light: #{$color-extended-cyan-light};
  --w-color-extended-cyan-dark: #{$color-extended-cyan-dark};

  --w-color-extended-teal: #{$color-extended-teal};
  --w-color-extended-teal-light: #{$color-extended-teal-light};
  --w-color-extended-teal-dark: #{$color-extended-teal-dark};

  --w-color-extended-lime: #{$color-extended-lime};
  --w-color-extended-lime-light: #{$color-extended-lime-light};
  --w-color-extended-lime-dark: #{$color-extended-lime-dark};

  --w-color-extended-orange: #{$color-extended-orange};
  --w-color-extended-orange-light: #{$color-extended-orange-light};
  --w-color-extended-orange-dark: #{$color-extended-orange-dark};


  /* Font families */
  --w-font-family-sans: #{$font-family-sans-serif};
  --w-font-family-mono: #{$font-family-monospace};


  /* Font sizes */
  --w-font-size-xxxlarge: #{$font-size-xxxlarge};
  --w-font-size-xxlarge: #{$font-size-xxlarge};
  --w-font-size-xlarge: #{$font-size-xlarge};
  --w-font-size-large: #{$font-size-large};
  --w-font-size-medium: #{$font-size-medium};
  --w-font-size-small: #{$font-size-small};


  /* Font weights */
  --w-font-weight-light: #{$font-weight-light};
  --w-font-weight-normal: #{$font-weight-normal};
  --w-font-weight-semibold: #{$font-weight-semibold};
  --w-font-weight-bold: #{$font-weight-bold};


  /* Corner radii */
  --w-corner-radii-small: #{$corner-radii-small};
  --w-corner-radii-medium: #{$corner-radii-medium};
  --w-corner-radii-large: #{$corner-radii-large};
  --w-corner-radii-pill: #{$corner-radii-pill};
  --w-corner-radii-circle: #{$corner-radii-circle};


  /* Spacing */
  --w-spacing-xsmall: #{$spacing-xsmall};
  --w-spacing-small: #{$spacing-small};
  --w-spacing-medium: #{$spacing-medium};
  --w-spacing-large: #{$spacing-large};
  --w-spacing-xlarge: #{$spacing-xlarge};
  --w-spacing-xxlarge: #{$spacing-xxlarge};


  /* Shadow depth */
  --w-shadow-depth-1: #{$shadow-depth-1};
  --w-shadow-depth-2: #{$shadow-depth-2};
  --w-shadow-depth-3: #{$shadow-depth-3};
  --w-shadow-depth-4: #{$shadow-depth-4};
  --w-shadow-depth-5: #{$shadow-depth-5};

  /* z-index depth */
  --w-z-index-rock-bottom: #{$z-index-rock-bottom};
  --w-z-index-appbar: #{$z-index-appbar};
  --w-z-index-progress: #{$z-index-progress};
  --w-z-index-overlay: #{$z-index-overlay};
  --w-z-index-tooltip: #{$z-index-tooltip};
  --w-z-index-action-button: #{$z-index-action-button};
  --w-z-index-pane: #{$z-index-pane};
  --w-z-index-message: #{$z-index-message};
  --w-z-index-modal: #{$z-index-modal};
  --w-z-index-arbitrarily-high: #{$z-index-arbitrarily-high};


  /* Gradients */
  --w-gradient-gray-lighter: #{$gradient-gray-lighter};
  --w-gradient-gray-light: #{$gradient-gray-light};
  --w-gradient-gray: #{$gradient-gray};
  --w-gradient-gray-dark: #{$gradient-gray-dark};

  --w-gradient-primary: #{$gradient-primary};
  --w-gradient-danger: #{$gradient-danger};
  --w-gradient-warning: #{$gradient-warning};
  --w-gradient-success: #{$gradient-success};
}
