@import 'helpers';

.w-tooltip {
  width: auto;
  min-width: 20px;
  font-size: $font-size-small;
  display: none;
  position: absolute;
  z-index: $z-index-tooltip;
  box-shadow: $shadow-depth-3;

  &--top {
    transform: translateX(-50%) translateY(-100%);
    margin-top: -5px;
  }

  &--bottom {
    transform: translateX(-50%);
    margin-top: 5px;
  }

  &--left {
    transform: translateX(-100%) translateY(-50%);
    margin-left: -5px;
  }

  &--right {
    transform: translateY(-50%);
    margin-left: 5px;
  }

  &--top-left {
    transform: translateX(-100%) translateY(0%);
    margin-left: -5px;
  }

  &--top-right {
    margin-left: 5px;
  }

  &--bottom-left {
    transform: translateX(-100%) translateY(-100%);
    margin-left: -5px;
  }

  &--bottom-right {
    transform: translateY(-100%);
    margin-left: 5px;
  }

  &--active {
    display: inline-block;
    justify-content: center;
  }

  .w-tooltip__message:empty {
    display: none;
  }

  .w-tooltip__title {
    padding: 5px $spacing-medium;
    display: block;
    width: 100%;
    font-weight: bold;
    color: $color-gray-dark;
    text-align: center;
    white-space: nowrap;
  }

  .w-tooltip__title + .w-tooltip__message:not(:empty) {
    min-width: 150px;
    border-top: 1px solid $color-gray-lighter;
    padding: $spacing-small $spacing-medium;
    width: 100%;
    display: block;
    text-align: left;
    font-weight: $font-weight-semibold;
  }
}
