.search-bar {
  width: 100%;
}

.search-bar__form {
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-bar__value {
  position: relative;
  width: 100%;
}

.search-bar__icon {
  color: var(--w-color-gray-light);
  left: 5px;
  position: absolute;
  top: calc(50% - 11px);
}

.search-bar__input {
  -webkit-appearance: none;
  border: 1px solid var(--w-color-gray-lighter);
  border-radius: 3px;
  color: var(--w-color-primary);
  font-size: 1rem;
  height: 34px;
  min-width: 14rem;
  padding: 5px 8px 5px 33px;
  text-overflow: ellipsis;
  width: 100%;
}

.search-bar__input:focus {
  outline: none;
}

.search-bar__input::placeholder {
  color: var(--w-color-gray-light);
  font-style: italic;
}

.search-bar__submit {
  display: none;
}
