@keyframes keyframe__debug-record--selected {
	0% {
		border-color: var(--w-color-white);
		box-shadow: unset;
	}

	30% {
		border-color: var(--w-color-white);
		box-shadow: 0 0 0 2px var(--w-color-primary);
	}

	70% {
		border-color: var(--w-color-white);
		box-shadow: 0 0 0 2px var(--w-color-primary);
	}

	100% {
		border-color: var(--w-color-white);
		box-shadow: unset;
	}
}

.debug-record {
	display: block;
	text-decoration: none;
}

.debug-record--highlight:hover {
	border-color: var(--w-color-white);
	box-shadow: 0 0 0 2px var(--w-color-primary);
	text-decoration: none;
}

.debug-record--highlight .w-icon:hover {
	cursor: pointer;
}

.debug-record--hidden {
	display: none;
}

.debug-record--stacked:hover {
	border-color: var(--w-color-gray-lighter);
	box-shadow: initial;
	cursor: default;
}

.debug-record--stacked .debug-record__tile {
	cursor: default;
}

.debug-record__tile {
	background: #fff;
	border: 1px solid var(--w-color-gray-lighter);
	color: var(--w-color-gray-light);
	display: grid;
	grid-template:
		"head side" 50px
		"main side" 50px
		"foot side" 25px
		/ minmax(auto, 700px) 100px;
	max-width: 750px;
	min-width: 340px;
	width: calc(100vw - 510px);
}

.debug-record__tile--selected {
	animation: keyframe__debug-record--selected 5s;
}

.debug-record__head,
.debug-record__main,
.debug-record__foot {
	padding: 0 1rem;
}

.debug-record__head {
	align-items: center;
	display: flex;
	justify-content: space-between;
	grid-area: head;
}

.debug-record__head-left,
.debug-record__head-right {
	display: flex;
}

.debug-record__head-left {
	align-items: center;
}

.debug-record__icon {
	cursor: pointer;
}

.debug-record__icon--devid {
	color: #3bc9db;
}

.debug-record__icon--ssess {
	color: #f783ac;
}

.debug-record__icon--uid {
	color: #ffa94d;
}

.debug-record__icon--swid {
	color: var(--w-color-alternative);
}

.debug-record__icon--default {
	color: var(--w-color-gray-light);
}

.debug-record__title {
	color: var(--w-color-gray);
	font-size: 1.6rem;
	font-weight: 400;
	max-width: 435px;
	overflow: hidden;
	padding: 0 1rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100vw - 822px);
}

.debug-record__title--subtle {
	color: var(--w-color-gray-light);
}

.debug-record__title--orphan {
	color: var(--w-color-gray-lighter);
}

.debug-record__main {
	align-items: center;
	display: flex;
	grid-area: main;
}

.debug-record__description {
	font-size: 1.2rem;
	max-width: 585px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100vw - 672px);
}

.debug-record__flag {
	color: var(--w-color-primary);
	padding-left: .5rem;
}

.debug-record__foot {
	align-items: center;
	display: flex;
	justify-content: space-between;
	grid-area: foot;
}

.debug-record__rule-name {
	max-width: 60%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.debug-record__side {
	align-items: center;
	border-left: 1px solid var(--w-color-gray-lighter);
	display: flex;
	flex-direction: column;
	grid-area: side;
	position: relative;
}

.debug-record__side-top {
	display: flex;
	margin-top: 1.6rem;
}

.debug-record__side-bottom {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 2.7rem;
}

.debug-record__progress {
	position: absolute;
	top: .5rem;
	left: 24px;
}
