.debug-ts-request__title {
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
}

.debug-ts-request__title__right {
  display: flex;
  flex: row;
  align-items: center;
}

.debug-ts-request__title__right__ad {
  position: relative;
  text-align: center;
  width: 4rem;
}

.debug-ts-request__title__right__ad-img {
  left: 16px;
  position: absolute;
  top: -12px;
}

.debug-ts-request__title__right__sgmnt {
  width: 8rem;
  text-align: center;
}

.debug-ts-request__title__right__chunkdur {
  width: 8rem;
  text-align: center;
}

.debug-ts-request__title__right__bitrate {
  width: 8rem;
  text-align: center;
}

.debug-ts-request__title__right__time__icon {
  font-size: 12px;
  font-weight: var(--w-font-weight-semibold);
  margin-right: 2px;
}

.debug-ts-request__title__right__time {
  width: 1.5rem;
  text-align: right;
}

.debug-ts-request__title__right__playback {
  display: flex;
  flex: row;
  justify-content: flex-end;
  align-items: center;
  width: 4rem;
}

.debug-ts-request__title__right__playback__play-icon {
  font-size: var(--w-font-size-large);
}

.debug-ts-request__title__right__playback__play-icon:hover {
  color: var(--w-color-primary);
  cursor: pointer;
}

.debug-ts-request__title__right__playback__text {
  font-size: var(--w-font-size-medium);
  color: var(--w-color-primary);
}

.debug-ts-request__title__right__playback__error-icon {
  font-size: var(--w-font-size-large);
  opacity: 0.5;
}
