@import 'helpers';

.w-app-bar__subject {
  align-items: center;
  align-content: center;
  display: flex;
  text-align: left;
  max-width: 100%;

  &__details {
    flex: 1;
    overflow-x: hidden;

    &__primary,
    &__secondary {
      width: 100%;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__primary {
      color: $color-gray-dark;
    }

    &__secondary {
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
      color: $color-gray;
    }
  }
}

.w-app-bar .w-app-bar__subject .w-app-bar__subject__navigation {
  margin-right: 10px;
  border: 0;
  flex-grow: 0;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: $color-gray-light;

  &__icon {
    cursor: pointer;
  }

  &:hover {
    border: 0;
    border-image-width: 0;
    text-decoration-line: none;
  }
}
