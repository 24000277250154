.creatives-view {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.creatives-view__header {
    display: flex;
    flex-direction: column;
    height: 79px; /* safari */
    width: 100%;
}

.creatives-view__header-middle {
    align-items: center;
    display: flex;
    width: 100%;
}

.creatives-view__header-middle .search-bar__value {
    margin-left: 35px;
}

.creatives-view__pending {
    display: flex;
    flex-direction: row;
    position: relative;
}

.creatives-view__pending .w-progress {
    position: absolute;
    z-index: 999;
}

.creatives-view__updated {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.creatives-view__updated-label {
    color: var(--w-color-gray-dark);
    font-weight: 500;
}

.creatives-view__updated-duration {
    color: var(--w-color-gray-light);
    font-size: 11px;
    font-weight: 500;
}

.creatives-view__download {
    min-width: 175px;
    width: 175px;
}

.creatives-view__download-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 20px;
}

.creatives-view__download-btn {
    width: 135px;
}

.creatives-view__download-btn--failed {
    background-color: var(--w-color-white);
    border-color: var(--w-color-danger);
    color: var(--w-color-gray-dark);
}

.creatives-view__download-text-container {
    align-items: center;
    display: flex;
}

.creatives-view__download-text-container .w-progress-circular {
    padding-top: 10px;
}

.creatives-view__download-text {
    padding: 0 3px 0 0;
}

.creatives-view__download-text--pending {
    padding: 0 9px 0 0;
}

.creatives-view__download-icon {
    color: var(--w-color-danger);
    padding: 0 5px;
}

.creatives-view__controls {
    display: flex;
    justify-content: center;
    width: 100%;
}

.creatives-view__controls--newer .creative-dates {
    margin-top: -20px;
}

.creatives-view__controls-container {
    display: flex;
    flex-direction: column;
    height: 90px;
    position: relative;
    max-width: 1250px;
    width: 100%;
}

.creatives-view__newer {
    display: flex;
    height: 21px;
    justify-content: center;
    width: 100%;
}

.creatives-view__newer-prompt {
    background: var(--w-color-primary);
    border-radius: 1rem;
    color: var(--w-color-white);
    cursor: pointer;
    height: 1.35rem;
    line-height: 1.35rem;
    padding: 0 .5em;
    z-index: 1;
}

.creatives-view__content {
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    overflow-y: scroll; /* safari */
}

.creatives-view__content-container {
    display: flex;
    max-width: 1280px;
    width: 100%;
}

.creatives-view__content-lhs {
    margin: 0 0 0 15px;
    min-width: 200px;
    width: 200px;
}

.creatives-view__content-rhs {
    margin: 0 15px 0 0;
    min-width: 200px;
    width: 200px;
}

.creatives-view__content-main {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 0;
}

.creatives-view__filters {
    display: flex;
    justify-content: center;
}

.creatives-view__records-wrapper {
    position: relative;
}

.creatives-view__records {
    margin: 2px 10px 0 10px;
}

.creatives-view__loader {
    display: flex;
    height: 21px;
    justify-content: center;
    padding: 2rem 0 5rem;
    width: 100%;
}

.creatives-view__loader-prompt {
    background: var(--w-color-primary);
    border-radius: 1rem;
    color: var(--w-color-white);
    cursor: pointer;
    height: 1.35rem;
    line-height: 1.35rem;
    padding: .2em .5em;
}


.creatives-view__cancel {
    border: none;
    border-radius: 14px;
    bottom: 265px;
    box-shadow: none;
    display: flex;
    height: 24px;
    justify-content: center;
    padding: 0;
    position: fixed;
    right: 40px;
    width: 24px;
}

.creatives-view__cancel:focus {
    box-shadow: none;
}

.creatives-view__cancel-icon {
    color: var(--w-color-gray-dark);
    font-size: 20px;
}

.creatives-view__cancel:hover .creatives-view__cancel-icon {
    color: var(--w-color-primary);
}

.creatives-view__copied {
    background: var(--w-color-white);
    border-radius: 12px;
    box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .15);
    color: var(--w-color-primary);
    font-size: 11px;
    font-weight: 500;
    padding: 4px 10px;
    position: absolute;
    width: 124px;
    z-index: 1;
}
