@import '../../../wham/scss/utilities/variables';

.nav {
  display: flex;

  &_back {
    align-items: center;
    color: $color-gray-dark;
    display: flex;
    font-size: $font-size-large;
    padding-left: 2rem;
    width: 250px;

    &_icon {
      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}
