html {
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $color-white;
  font-size: $font-size-base;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: $font-render-mode;
  -moz-font-feature-settings:"kern" 1;
  -ms-font-feature-settings:"kern" 1;
  -o-font-feature-settings:"kern" 1;
  -webkit-font-feature-settings:"kern" 1;
  font-feature-settings:"kern" 1;
  font-kerning: normal;
  letter-spacing: 0.015em;
  word-spacing: 0.001em;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $font-family-sans-serif;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: $font-family-monospace;
}

body {
  color: $color-gray;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: 1.5;
  background: $color-off-white;
}

// Inline

a {
  color: $color-primary;
  font-size: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: $transition-fast;
  -webkit-text-decoration-skip: ink;

  &:hover {
    text-decoration: underline;
  }

  &.small {
    font-size: $font-size-small;
  }
}


hr {
  background-color: $color-gray-lighter;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.5rem 0;
}

img {
  max-width: 100%;
}

small {
  font-size: .8em;
}

p + p {
  margin-top: 1.5em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  font-weight: $font-weight-bold;
}

// Block

pre {

  font-size: 1em;
  white-space: pre;
  word-wrap: normal;

  code {
    @include overflow-touch;

    background: none;
    color: inherit;
    display: block;
    font-size: 1em;
    overflow-x: auto;
    padding: 1.25rem 1.5rem;
  }
}

table {
  width: 100%;

  td,
  th {
    text-align: left;
    vertical-align: top;
  }

}

body,
html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: geometricPrecision;
  backface-visibility: hidden;
}

.is-marginless {
  margin: 0 !important;
}

.is-paddingless {
  padding: 0 !important;
}

.is-unselectable {
  @include unselectable;
}

::placeholder,
::-webkit-input-placeholder,
:-ms-input-placeholder {
  color: $color-gray-light;
}

.w-show-scroll {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    border-radius: 9px
  }

  &::-webkit-scrollbar-thumb {
    background: $color-gray;
  }

  &::-webkit-scrollbar-track {
    background: #c00;
  }
}
