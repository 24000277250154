@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse-placeholder-content {
  0% {
    color: rgba($color-gray-lighter, 1);
  }

  50% {
    color: rgba($color-gray-lighter, .4);
  }

  100% {
    color: rgba($color-gray-lighter, 1);
  }
}
