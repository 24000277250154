@import 'helpers';

.w-slab {
  display: flex;
  width: 620px;
  border: 1px solid $color-gray-lighter;
  box-sizing: border-box;
  background: $color-white;
  transition: box-shadow .2s;

  &:hover {
    border-color: $color-white;
    box-shadow: 0 0 0 2px $color-primary;
  }

  .w-slab__marker {
    min-width: 60px;
    max-width: 60px;
    padding: $spacing-medium $spacing-small;
    background: #c00;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }

  .w-slab__content {
    flex-grow: 1;
    box-sizing: border-box;
    padding: $spacing-medium;

    .w-slab__header {
      font-size: $font-size-large;
      line-height: 1.25;
    }

    .w-slab__caption {
      margin-top: $spacing-xsmall;
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
      color: $color-gray-light;
    }
  }

  .w-slab__info {
    min-width: $spacing-xxlarge;
    max-width: $spacing-xxlarge;
    box-sizing: border-box;
    padding: $spacing-medium $spacing-small;
    border-left: 1px solid $color-gray-lighter;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  & + .w-slab {
    margin-top: 5px;
  }
}
