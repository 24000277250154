.debug-filters-refresh {
	align-items: center;
	display: flex;
}

.debug-filters-refresh__button {
	background-color: var(--w-color-off-white);
	border: none;
	border-radius: 2px;
	box-shadow: none;
	color: var(--w-color-success-light);
	height: 25px;
	padding: 0;
	width: 25px;
}

.debug-filters-refresh__button:focus {
	box-shadow: none;
	color: var(--w-color-success);
}

.debug-filters-refresh__button:hover {
	color: var(--w-color-success);
}
