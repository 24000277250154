@import '../../wham/scss/utilities/variables';

@mixin copy-to-clipboard {
  position: relative;

  &:hover {
    cursor: copy;
  }
}

@mixin copied-to-clipboard {
  &:after {
    background: $color-white;
    border-radius: 12px;
    bottom: -23px;
    box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .15);
    color: #008bcc;
    content: 'Copied to clipboard';
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    left: 10px;
    padding: 4px 10px;
    pointer-events: none;
    position: absolute;
    width: 124px;
    z-index: 13;
  }

  &:hover {
    cursor: default;
  }
}
