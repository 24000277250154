.key-value-pair-modal {
	align-items: center;
	background-color: var(--w-color-white);
	box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
	display: flex;
	flex-direction: column;
	left: 15%;
	position: absolute;
	top: 12.5%;
	width: calc(100vw - 30%);
	z-index: 10012;
}

.key-value-pair-modal__background {
	background-color: var(--w-color-gray-dark);
	height: 100vh;
	left: 0;
	opacity: 0.3;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 10011;
}

.key-value-pair-modal__close {
	color: var(--w-color-white);
	cursor: pointer;
	position: absolute;
	right: 34px;
	top: 20px;
	z-index: 10011;
}

.key-value-pair-modal__title {
	border-bottom: 1px solid var(--w-color-gray-lighter);
	color: var(--w-color-gray-dark);
	font-size: 20px;
	font-weight: 700;
	padding: 20px 0;
	text-align: center;
	width: 100%;
}

.key-value-pair-modal__preview-container {
	align-items: center;
	display: flex;
	height: 27px;
	margin: 2rem 20px;
}

.key-value-pair-modal__preview {
	color: var(--w-color-gray-dark);
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	justify-content: center;
}

.key-value-pair-modal__preview-label {
	margin: 0 .5rem 0 0;
}

.key-value-pair-modal__preview-error {
	color: var(--w-color-danger);
}

.key-value-pair-modal__preview-key {
	color: var(--w-color-success);
}

.key-value-pair-modal__preview-value {
	color: var(--w-color-primary-dark);
}

.key-value-pair-modal__preview-value-macro {
	color: var(--w-color-alternative);
}

.key-value-pair-modal__preview-optional {
	color: var(--w-color-danger-dark);
}

.key-value-pair-modal__form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.key-value-pair-modal__content {
	display: flex;
	padding: 20px;
	width: 100%;
}

.key-value-pair-modal__fields {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin: 0 10px 0 0;
	width: 100%;
}

.key-value-pair-modal__fields--split {
	width: 50%;
}

.key-value-pair-modal__interactives {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 50%;
}

.key-value-pair-modal__interactive {
	margin: 0 0 12px 10px;
}

.key-value-pair-modal__group {
	display: flex;
	flex-direction: column;
	height: 90px;
	margin: 0 0 1rem 0;
	max-width: 600px;
	width: 100%;
}

.key-value-pair-modal__group--inline {
	align-items: center;
	display: flex;
	flex-direction: row;
}

.key-value-pair-modal__group--inline .key-value-pair-modal__label {
	margin: 0 .5rem 0 0;
}

.key-value-pair-modal__label {
	font-weight: 600;
}

.key-value-pair-modal__input {
	background-color: var(--w-color-white);
	border: 1px solid var(--w-color-gray-lighter);
	border-radius: 3px;
	padding: 4px 8px;
	font-size: 1rem;
	height: 34px;
}

.key-value-pair-modal__input:focus {
	border-color: var(--w-color-primary);
	outline: none;
}

.key-value-pair-modal__input:hover {
	border-color: var(--w-color-gray-light);
}

.key-value-pair-modal__input::placeholder {
	color: var(--w-color-gray-light);
	font-style: italic;
}

.key-value-pair-modal__input--error,
.key-value-pair-modal__input--error:focus,
.key-value-pair-modal__input--error:hover {
	border: 1px solid var(--w-color-danger);
}

.key-value-pair-modal__message {
	color: var(--w-color-white);
	height: 35px;
	padding: 4px 0;
}

.key-value-pair-modal__message--error {
	color: var(--w-color-danger);
}

.key-value-pair-modal__controls {
	border-top: 1px solid var(--w-color-gray-lighter);
	display: flex;
	justify-content: flex-end;
	padding: 20px;
	width: 100%;
}

.key-value-pair-modal__submit {
	box-shadow: none;
	height: 34px;
	padding: 0 1.4286rem;
}
