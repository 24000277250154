@import '../../../scss/mixins/clipboard';
@import '../../../scss/mixins/utilities';

.key {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  text-align: right;
  vertical-align: middle;
  width: 75px;
}

.value {
  font-size: 13px;
  padding: 0 0 0 10px;
  vertical-align: middle;
  width: auto;
}

.text {
  @include ellipsis-truncate;
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.upper {
  padding-top: 5px;
}

.lower {
  padding-bottom: 5px;
}

.copyable {
  @include copy-to-clipboard;
}

.copied {
  @include copied-to-clipboard;
}
