.whitelist-add {
	display: flex;
	flex-direction: column;
}

.whitelist-add__header {
	color: var(--w-color-gray-dark);
	font-weight: 600;
	margin: 0 0 .5rem 0;
}

.whitelist-add__upper,
.whitelist-add__lower {
	display: flex;
	margin: 0 0 2rem 0;
}

.whitelist-add__field {
	margin: 0 .7rem 0 0;
}

.whitelist-add__field:last-child {
	margin: 0;
}

.whitelist-add__field-group {
	width: 100%;
}

.whitelist-add__field-label {
	color: var(--w-color-gray-dark);
	display: block;
	font-weight: 600;
	margin: 0 0 .5rem 0;
}

.whitelist-add__input {
	width: 100%
}

.whitelist-add__input--duplicate input,
.whitelist-add__input--duplicate input:focus,
.whitelist-add__input--duplicate input:hover {
	box-shadow: 0 0 0 2px var(--w-color-danger);
}

.whitelist-add__type {
	width: 7.6rem;
}

.whitelist-add__type .w-select select {
	z-index: 4;
}

.whitelist-add__label {
	display: flex;
	align-items: center;
	position: relative;
	width: 50%;
}

.whitelist-add__lookup {
	display: flex;
	align-items: center;
	position: relative;
	width: 21rem;
}

.whitelist-add__lookup .whitelist-add__input {
	width: calc(100% - 25px - 10px); /* allow for width of lookup button plus spacing */
}

.whitelist-add__label--lookup .whitelist-add__input {
	width: calc(100% - 25px - 10px); /* allow for width of lookup button plus spacing */
}

.whitelist-add__value {
	width: calc(100% - 34rem); /* combined width of other 3 columns with margins */
}

.whitelist-add__actions {
	align-items: flex-end;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 5rem;
}

.whitelist-add__actions-group {
	display: flex;
	justify-content: space-between;
}

.whitelist-add .whitelist-add__save {
	border: 1px solid var(--w-color-gray-light);
	box-shadow: none;
	height: 34px;
	padding: 0 1.4286rem;
	width: 72px;
}

.whitelist-add .whitelist-add__save--error {
	border-color: var(--w-color-danger);
	background-color: var(--w-color-white);
}

.whitelist-add .whitelist-add__save--error-icon {
	color: var(--w-color-danger);
}

.whitelist-add .whitelist-add__save .w-progress-circular {
	height: 26px;
}
