.default {
  color: var(--w-color-gray);
}

.ready {
  color: var(--w-color-success);
}

.blocked {
  color: var(--w-color-danger);
}

.pending {
  color: var(--w-color-alternative);
}

.retranscode {
  color: var(--w-color-warning);
}
