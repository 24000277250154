@import '../../../wham/scss/utilities/variables';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.line {
  display: flex;
}

.action {
  color: $color-primary;
  cursor: pointer;
  padding: 0 4px;
}
