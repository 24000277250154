.debug-filters-meta {
	align-items: center;
	display: flex;
	height: 2.5rem;
	width: 100%;
}

.debug-filters-meta__label {
	font-weight: 600;
}

.debug-filters-meta__value {
	margin: 0 0 0 3px;
}
