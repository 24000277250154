@import 'helpers';

.w-app-bar-2 {
  &:hover,
  &:focus-within {
    .w-section-selector__selected {
      &::after {
        @include arrow($color-gray-light);
        content: '';
        z-index: 4;
        bottom: 10px;
        left: 50%;
        margin-left: -.25em;
      }
    }
  }
}

.w-section-selector {
  height: 70px;
  user-select: none;
  cursor: pointer;

  &:hover {
    .w-section-selector__selected {
      background: $color-primary;
      color: $color-white;

      &::after {
        border-color: $color-white;
      }
    }
  }

  &__selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding: 0 $spacing-medium;
    position: relative;

    &__content {
      font-weight: bold;
    }
  }

  &__menu {
    position: absolute;
  }

  &--active {
    box-shadow: $shadow-depth-3;
    z-index: 10;

    .w-section-selector__selected {
      background: $color-primary;
      color: $color-white;
      width: 70px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;


      .w-icon {
        display: block;
      }

      &::after {
        display: none !important;
      }
    }
  }

  &:focus {
    outline: 0;
  }

  .w-menu {
    text-align: left;
  }
}
