.debug-key-value-table__items {
	margin: -20px 0 0 0;
	max-width: 100%;
	width: auto;
}

.debug-key-value-table__item {
	border-bottom: 1px solid var(--w-color-gray-lighter);
}

.debug-key-value-table__item-key {
	font-weight: 700;
	max-width: 50%;
	padding: 18px 14px 7px 0;
	white-space: nowrap;
	text-align: right;
}

.debug-key-value-table__item-value {
	padding: 18px 0 7px 14px;
	text-align: left;
	width: 100%;
	word-break: break-word;
}
