.key-value-pair-interactive {
	background-color: var(--w-color-white);
	display: flex;
	flex-direction: column;
	/*margin: 0 0 12px 10px;*/
	transform: translate(0, 0); /* preserve rounded corners during drag */
}

.key-value-pair-interactive__title {
	font-size: 1rem;
	font-weight: 600;
}

.key-value-pair-interactive__cloud {
	border: 1px solid var(--w-color-gray-lighter);
	border-bottom: none;
	height: 100%;
	overflow-y: scroll;
}

.key-value-pair-interactive__hint {
	border: 1px solid var(--w-color-gray-lighter);
	border-top: none;
	text-align: center;
}

.key-value-pair-interactive__hint-text {
	color: var(--w-color-gray-light);
	font-style: italic;
}

.key-value-pair-interaction.w-btn {
	background-color: var(--w-color-alternative-light);
	border: 1px solid var(--w-color-alternative-light);
	border-radius: 8px;
	box-shadow: none;
	color: var(--w-color-white);
	cursor: pointer;
	display: inline-block;
	font-weight: 600;
	margin: 7px;
}

.key-value-pair-interaction.w-btn:focus {
	box-shadow: none;
}

.key-value-pair-interaction.w-btn:hover,
.key-value-pair-interaction.w-btn:focus {
	background-color: var(--w-color-alternative);
	border: 1px solid var(--w-color-alternative);
	color: var(--w-color-white);
}

.key-value-pair-interactive__tooltip {
	border: 1px solid var(--w-color-gray-lighter);
	box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
	border-radius: 14px;
}

.key-value-pair-interactive__tooltip .w-tooltip__title {
	color: var(--w-color-gray-light);
}
