@import 'helpers';

.w-screen {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  color: $color-gray;
  display: flex;

  &--fit-to-screen {
    max-height: 100vh;
    overflow-y: auto;
    border: $spacing-small solid $color-gray;
    background: $color-off-white;

    .w-screen__content {
      max-height: 100vh;
    }
  }

  &--inverse {
    border-color: $color-white;
    background: $color-gray-dark;
    color: $color-white;
  }

  &__content {
    min-height: 100%;
    width: 100%;
  }

  &--waiting {
    cursor: wait;
    
    .w-screen__content {
      user-select: none;
      pointer-events: none;
    }
  }
}
