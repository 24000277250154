@import '../../views';

.main {
  @extend %main;

  :global(.transaction-navigation-tab) {
    left: 0;
    position: absolute;
    top: 73px;
  }

  :global(.doc-link) {
    position: fixed;
  }
}

.header {
  @extend %header;
}

.pending {
  @extend %pending;
}

.container {
  @extend %content;

  :global(.results-content) {
    position: unset;
  }

  :global(.w-layout__column__content) {
    padding-top: 36px;
  }
}
