@import '../../wham/scss/utilities/variables';
@import '../../scss/mixins/buttons';
@import '../../scss/mixins/clipboard';
@import '../../scss/mixins/utilities';

.summary {
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0 0 25px 0;

  &_title {
    color: $color-gray;
    font-size: 28px;
    font-weight: 300;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details,
.vasts {
  margin: 0 0 50px 0;
  width: 50%;
}

.row {
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  height: 35px;
  margin: 12px 0 0 0;

  &__expanded {
    height: initial;
  }
}

.cell {
  align-items: center;
  display: flex;
  padding: 0;
  width: 100%;

  &__expanded {
    align-items: initial;
    padding-top: 7px;
    padding-bottom: 5px;
  }
}

.key {
  font-weight: 700;
  justify-content: flex-end;
  padding-right: 5px;
  width: 150px;
}

.value {
  padding-left: 5px;
}

.controls {
  display: flex;
  justify-content: center;
  padding: 0 0 0 5px;
  width: 30px;
}

.button {
  @include control-button;
}

.icon {
  @include control-button-icon;
}

.text {
  display: inline-block;
  max-width: 300px;
  width: 100%;
}

.truncated {
  @include ellipsis-truncate;
}

.copyable {
  @include copy-to-clipboard;
  display: flex;
}

.copied {
  @include copied-to-clipboard;
}

.vast {
  border-collapse: collapse;
  color: $color-gray-dark;

  &_title {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 5px 0;
  }

  &_list {
    counter-reset: vast-counter;
    list-style: none;
    margin: 0 0 0 14px;
  }

  &_item {
    counter-increment: vast-counter;
    position: relative;

    &::before {
      content: counter(vast-counter) ".";
      font-size: 14px;
      left: 0;
      margin: 0 0 0 -12px;
      position: absolute;
      top: 5px;
    }
  }
}
