@import 'helpers';

@keyframes progress-active {
  0% {
    left: 0;
    transform: scaleX(0);
  }

  20% {
    left: 0;
  }

  70% {
    transform: scaleX(1);
  }

  100% {
    left: 100%;
    transform: scaleX(0);
  }
}

.w-progress {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 3px;
  display: block;
  background: rgba($color-primary, .4);

  .w-progress__display {
    max-width: 100%;
    width: 0;
    background: $color-primary;
    height: 3px;
    display: block;
    transition: width .3s; //TODO; Convert to variable
  }

  &--display {
    height: $spacing-medium;
    border-radius: $corner-radii-pill;

    .w-progress__display {
      height: $spacing-medium;
      border-radius: $corner-radii-pill;
    }
  }

  &--indeterminate {
    background: none;

    .w-progress__display {
      opacity: 0;
      transition: opacity .3s;
      width: 100%;
      position: absolute;
      top: 0;
      transform-origin: left top;
      animation-duration: 1.33s;
      animation-name: progress-active;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(.645, .045, .355, 1); //TODO: Make variable
      animation-play-state: paused;
    }

    &.w-progress--active {
      .w-progress__display {
        opacity: 1;
        transform: scaleX(0);
        left: 0;
        animation-play-state: running;
        z-index: $z-index-progress;
      }
    }

    &.w-progress--inverse {
      background: none;
    }
  }

  &--inverse {
    background: rgba($color-white, .4);

    .w-progress__display {
      background: $color-white;
    }

  }
}
