/* Ad Decision */
.debug__ad-decision {
  display: -ms-flexbox;
  display: flex;
  background: var(--w-color-white);
  border: 1px solid var(--w-color-gray-lighter);
  padding: var(--w-spacing-small) 0;
}
.debug__ad-decision .debug__ad-decision__context, .debug__ad-decision .debug__ad-decision__playback {
  min-width: var(--w-spacing-xlarge);
  max-width: var(--w-spacing-xlarge);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.debug__ad-decision .debug__ad-decision__context {
  font-size: var(--w-font-size-large);
  color: var(--w-color-gray-light);
}
.debug__ad-decision .debug__ad-decision__content {
  -ms-flex: 1 1;
  flex: 1 1;
}
.debug__ad-decision .debug__ad-decision__content__primary {
  display: -ms-flexbox;
  display: flex;
}
.debug__ad-decision .debug__ad-decision__content__primary .w-badge {
  margin-right: var(--w-spacing-small);
  background: var(--w-color-white);
  border: 1px solid var(--w-color-gray-light);
  color: var(--w-color-gray-light);
}
.debug__ad-decision__content__primary__ad-id {
  -ms-flex: 9;
  flex: 9;
}
.debug__ad-decision__content__primary__ad-id .w-icon {
  margin-left: 0.3em;
  font-size: var(--w-font-size-small);
  color: var(--w-color-primary);
  opacity: 0;
}
.debug__ad-decision__content__primary__ad-id:hover .w-icon {
  opacity: 1;
}
.debug__ad-decision__content__primary__ade-id {
  -ms-flex: 11;
  flex: 11;
}
.debug__ad-decision .debug__ad-decision__skip-reason {
  -ms-flex: 11;
  align-items: center;
  display: flex;
  flex: 11;
}
.debug__ad-decision .debug__ad-decision__skip-reason i {
  color: var(--w-color-gray-light);
  border: 1px solid var(--w-color-gray-lighter);
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: var(--w-font-size-small);
  padding: 0 0.3rem;
  margin: 0 0 0 .5rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.debug__ad-decision .debug__ad-decision__content__secondary {
  display: -ms-flexbox;
  display: flex;
  margin-top: var(--w-spacing-xsmall);
}
.debug__ad-decision__content__secondary .w-middle-truncate {
  width: 10rem;
}
.debug__ad-decision .debug__ad-decision__duration {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
}
.debug__ad-decision .debug__ad-decision__duration .w-icon {
  font-size: 12px;
  font-weight: var(--w-font-weight-semibold);
  margin-right: 2px;
}
.debug__ad-decision .debug__ad-decision__duration__label {
  font-size: 11px;
  font-weight: var(--w-font-weight-semibold);
}
.debug__ad-decision .debug__ad-decision__trace {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  flex: 6;
}
.debug__ad-decision .debug__ad-decision__trace .w-icon {
  font-size: 12px;
  font-weight: var(--w-font-weight-semibold);
  color: var(--w-color-gray-light);
  margin: 0 2px;
}
.debug__ad-decision .debug__ad-decision__trace__label {
  font-size: 11px;
  font-weight: var(--w-font-weight-semibold);
}
.debug__ad-decision__details {
  display: flex;
  flex: 9;
}
.debug__ad-decision .debug__ad-decision__url {
  display: flex;
  flex: 11;
}
.debug__ad-decision .debug__ad-decision__badge {
  margin-right: var(--w-spacing-small);
  background: var(--w-color-white);
  border: 1px solid var(--w-color-gray-light);
  color: var(--w-color-gray-light);
}
.debug__ad-decision .debug__ad-decision__url__badge {
  margin-right: var(--w-spacing-small);
}
.debug__ad-decision + .debug__ad-decision {
  margin-top: -1px;
}
.debug__ad-decision__playback__play-icon {
  font-size: var(--w-font-size-large);
}
.debug__ad-decision__playback__play-icon:hover {
  color: var(--w-color-primary);
}
.debug__ad-decision__playback__play-icon--playing {
  color: var(--w-color-primary);
}
.debug__ad-decision__playback__text {
  font-size: var(--w-font-size-small);
  position: absolute;
  margin-top: 18px;
}
.debug__ad-decision__playback__error-icon {
  font-size: var(--w-font-size-large);
  opacity: 0.5;
}
