.w-slab {
  margin: 5px auto;
  cursor: pointer;
}

.rule-list {
  overflow-y: auto;
  padding-top: 37.5px;
  display: flex;
  justify-content: center;
}

.rule-list .w-slab__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 450px;
}

.rule-list .sort-button .w-icon {
  cursor: pointer;
}

.rule-list .sort-button .w-menu {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.rule-list .active-filter-toggle {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.rule-list .active-filter-toggle a {
  line-height: 2rem;
  color: var(--w-color-gray-light);
}

.rule-list .active-filter-toggle a.active {
  color: var(--w-color-primary-dark);
}

.rule-list .w-slab__content .w-slab__header {
  color: black;
}

.rule-header-row {
  min-height: 5rem;
}

.rule-header-row-middle {
  display: flex;
  margin: 0 7px;
  width: 100%;
}

.rule-header-row-middle .w-app-bar__context {
  flex: 1;
}

.active-filter-toggle, .sort-button {
  position: sticky;
  padding: 0 3rem;
  top: 0;
}

.w-menu-list__item__link__label {
  font-size: 14px;
}

.rule-card-wrapper {
  display: flex;
  align-items: center;
}

.rule-card-wrapper--dragging {
  pointer-events: all !important;
  cursor: row-resize !important;
}

.rule-card-wrapper--dragging .w-slab {
  pointer-events: all !important;
  cursor: row-resize !important;
}

.rule-card-wrapper .w-icon {
  cursor: row-resize;
}

.rule-card .w-slab .w-slab__info {
  font-size: 14px;
  min-width: auto;
  max-width: none;
  flex: 1.5;
}

.rule-username {
  color: var(--w-color-primary);
}

.rule-list .slab-content {
  padding: 16px 16px 15px 19px;
}

.rule-list .w-slab__marker {
  border-right: 1px solid #e5e5e7;
}

.rule-list .w-slab__marker .priority-header {
  color: var(--w-color-gray-light);
  font-size: 11px;
}

.rule-list .w-slab__marker .priority-number {
  font-size: 20px;
}

.rule-list .w-slab__marker .priority-number.priority-number--deactivated {
  text-decoration: line-through;
}

.rule-list .w-slab__marker {
  color: var(--w-color-gray-light);
}

.rule-list .isActive .w-slab__marker {
  color: black;
}

.rule-list .w-slab__caption {
  display: flex;
  flex-direction: column;
  min-height: 5rem;
}

.global-rule-card .w-slab__marker .priority-header {
  color: white;
}

.rule-list .w-slab__info {
  color: var(--w-color-gray-light);
}

.rule-list .isActive .w-slab__info {
  color: var(--w-color-success);
}

.global-rule-card {
  margin-bottom: 35px;
}

.global-rule-card .w-slab__marker {
  border-right: 0;
  background: var(--w-color-alternative-dark) !important;
  color: white !important;
}

.rule-list .w-slab__marker,
.rule-list .w-slab__content,
.rule-list .w-slab__info {
  padding-top: 1rem;
  padding-bottom: .1rem;
}

.rule-list section header {
  text-align: center;
  font-size: var(--w-font-size-small);
  font-weight: bold;
}

.rule-list section header i {
  font-style: normal;
  color: var(--w-color-gray-light);
  border: 1px solid var(--w-color-gray-lighter);
  border-radius: 50%;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
}

.rule-list section header i + span h4 {
  border-bottom: 1px solid var(--w-color-gray-lighter);
  font-weight: bold;
}

.rule-list section header i + span h4,
.rule-list section header i + span p {
  padding: 0.5rem 1rem;
}

.rule-list section header i + span {
  text-align: left;
  font-weight: normal;
  opacity: 0;
  border: 1px solid var(--w-color-gray-lighter);
  background: white;
  width: 186px;
  font-weight: normal;
  position: absolute;
  box-shadow: 0 3px 21px 2px rgba(0, 0, 0, 0.05);
  transition: opacity 0.5s;
  pointer-events: none;
}

.rule-list section header i:hover + span,
.rule-list section header i + span:hover{
  opacity: 1;
}

.rule-list .loading-rules,
.rule-list .no-rules-message {
  height: 250px;
  border: 1px solid var(--w-color-gray-lighter);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rule-list .no-rules-message header {
  font-weight: normal;
  padding: 0.5rem 0;
  font-size: var(--w-font-size-large);
  color: var(--w-color-gray-light);
}

.rule-card__updated-at {
  font-size: var(--w-font-size-small);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rule-card__expression {
  flex-grow: 1;
  font-size: var(--w-font-size-medium);
  overflow-x: hidden;
}
