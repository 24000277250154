@import 'helpers';

.w-avatar {
  border-radius: $corner-radii-circle;
  width: 2rem;
  height: 2rem;
  border: 1px solid $color-gray-lighter;
  color: $color-gray-light;
  text-transform: uppercase;
  text-align: center;
  line-height: calc(2rem);
  display: inline-block;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
  user-select: none;
  cursor: default;
  transition: $transition-fast;
  background: rgba($color-white, .4);


  &:hover {
    border-color: $color-gray-light;
    color: $color-gray;
    transition: $transition-fast;
  }

  &:active {
    border-color: $color-gray-dark;
    background-color: $color-gray-dark;
    color: $color-white;
    transition: $transition-fast;
  }

  &--you {
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover {
      border-color: $color-primary-dark;
      color: $color-primary-dark;
      transition: $transition-fast;
    }

    &:active {
      border-color: $color-primary-darker;
      background-color: $color-primary-darker;
      color: $color-primary-invert;
      transition: $transition-fast;
    }
  }
}
