.debug-filter-by-session {
	border-bottom: 1px solid var(--w-color-gray-lighter);
	display: flex;
	flex-direction: column;
	position: relative;
}

.debug-filter-by-session__header {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin: 0 0 0 -3px;
	padding: 10px 0;
}

.debug-filter-by-session__title-wrapper {
	align-items: center;
	display: flex;
}

.debug-filter-by-session__title-icon {
	color: var(--w-color-gray-light);
	cursor: pointer;
	font-size: 1.4rem;
}

.debug-filter-by-session__title {
	color: var(--w-color-gray-dark);
	font-weight: 600;
}

.debug-filter-by-session__content {
	display: flex;
	flex-direction: column;
	padding: 0 0 10px;
}

.debug-filter-by-session__option {
	align-items: center;
	display: flex;
}

.debug-filter-by-session__option-input {
	cursor: pointer;
}

.debug-filter-by-session--pending .debug-filter-by-session__option-input {
	cursor: default;
}

.debug-filter-by-session__option-label {
	background-color: var(--w-color-off-white);
	color: var(--w-color-gray-dark);
	cursor: pointer;
	max-width: 200px;
	overflow: hidden;
	padding: 0 0 0 7px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.debug-filter-by-session--pending .debug-filter-by-session__option-label {
	color: var(--w-color-gray-light);
	cursor: default;
}

.debug-filter-by-session__option:hover .debug-filter-by-session__option-label {
	color: var(--w-color-primary);
}

.debug-filter-by-session--pending .debug-filter-by-session__option:hover .debug-filter-by-session__option-label {
	color: var(--w-color-gray-light);
}
