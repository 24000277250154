.whitelist-sidebar {
	background: white;
	border-left: 1px solid #efefef;
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
}

.whitelist-sidebar__header {
	align-self: flex-start;
	border-bottom: 1px solid #efefef;
	margin-bottom: 1rem;
	width: 100%;
}

.whitelist-sidebar__header .whitelist-sidebar__header-head {
	border-bottom: none;
}

.whitelist-sidebar__header .filter-bar {
	padding: 0 1rem 1rem 1rem;
}

.whitelist-sidebar__header-head {
	align-items: center;
	border-bottom: 1px solid #efefef;
	display: flex;
	font-size: 1.2rem;
	justify-content: space-between;
	padding: 1.2rem 2rem;
}

.whitelist-sidebar__title {
	flex: 8;
}

.whitelist-sidebar__settings {
	align-items: center;
	display: flex;
	flex: 2;
	justify-content: center;
}

.whitelist-sidebar__settings-button {
	border: none;
	box-shadow: none;
	height: 25px;
	padding: 0;
	width: 25px;
}

.whitelist-sidebar__settings-icon {
	font-size: 1.25rem;
}

.whitelist-sidebar__header-form {
	display: flex;
}

.whitelist-sidebar__header-type.w-field {
	border-right: 1px solid #efefef;
	display: inline-block;
	margin: 0;
	padding: 0.3rem 2%;
	width: 30%;
}

.whitelist-sidebar__header-type .w-select::after {
	z-index: 0; /* Ensure icon is hidden by Whitelist modal when open */
}

.whitelist-sidebar__header-filter.w-field {
	border: 0;
	display: inline-block;
	margin: 0;
	max-width: none;
	padding: 0.3rem 2%;
	width: 62%;
}

.whitelist-sidebar__header-filter-input {
	border: 0;
	padding-left: 1rem;
}

.whitelist-sidebar__header-filter-input.w-input input,
.whitelist-sidebar__header-filter-input.w-input input:hover {
	box-shadow: none;
}

.whitelist-sidebar__empty-label {
	color: #b3b3b3;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.whitelist-sidebar__loading-label {
	color: #b3b3b3;
	font-size: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.whitelist-sidebar__items {
	height: 100%;
	overflow-y: scroll;
}
