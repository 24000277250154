.dashboard {
	background-color: var(--w-color-off-white);
	display: flex;
	justify-content: center;
	max-width: 1280px;
	width: 100%;
}

.dashboard__tiles {
	display: flex;
	flex-wrap: wrap;
	width: 990px;
	margin: 15px 0 0 0;
}

@media (min-width: 660px) and (max-width: 990px) {
	.dashboard__tiles {
		width: 660px;
	}
}

@media (max-width: 660px) {
	.dashboard__tiles {
		width: 330px;
	}
}

.dashboard__tile {
	display: flex;
}
