@import 'helpers';

.w-menu-list {

  &__item {

    max-width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--selected,
    &--selected:hover {
      .w-menu-list__item__link {
        background-color: $color-primary;
        font-weight: $font-weight-bold;
        color: $color-white;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO30KCS5zdDF7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTAsMGgyNHYyNEgwVjB6Ii8+Cjxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iMTkuNiw0LjIgOSwxNC44IDQuOCwxMC42IDIsMTMuNCA5LDIwLjQgMjIuNCw3ICIvPgo8L3N2Zz4K');
        background-size: 10px auto;
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
      }
    }

    &__link {
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      padding: $spacing-small $spacing-large $spacing-small $spacing-small;
      display: block;

      &:hover {
        background: $color-primary-light;
        text-decoration: none;
      }

      &:active {
        background: $color-primary-darker;
        color: $color-white;
        text-decoration: none;
      }

      &__caption {
        display: none;
      }
    }
  }



  &--spaced {
    .w-menu-list__item__link {
      padding: $spacing-medium $spacing-large;
    }
  }

  &--contextual {
    .w-menu-list__item--selected {
      .w-menu-list__item__link,
      .w-menu-list__item__link:hover {
        .w-menu-list__item__link__caption {
          color: $color-white;
        }
      }
    }

    .w-menu-list__item__link {
      padding: $spacing-medium $spacing-large;

      &:hover {
        .w-menu-list__item__link__caption {
          color: $color-gray;
        }
      }

      &:active {
        .w-menu-list__item__link__caption {
          color: $color-white;
        }
      }

      .w-menu-list__item__link__caption {
        display: block;
        font-size: $font-size-small;
        font-weight: $font-weight-semibold;
        color: $color-gray-light;
      }
    }
  }

  &--small {
    .w-menu-list__item__link {
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
      padding: ($spacing-small / 2) ($spacing-large $spacing-small / 2) $spacing-small;
    }

    &.w-menu-list--spaced {
      .w-menu-list__item__link {
        font-size: $font-size-small;
        padding: $spacing-medium $spacing-large;
      }
    }
  }

  &--subtle {
    .w-menu-list__item__link {
      padding-left: 0;

      &:hover,
      &:active {
        background: transparent;
        color: $color-primary;
      }
    }

    .w-menu-list__item--selected {
      .w-menu-list__item__link {
        color: $color-primary;
        background: transparent;
      }

      &:hover {
        .w-menu-list__item__link {
          color: $color-primary-dark;
        }
      }

    }
  }
}
