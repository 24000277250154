.whitelist-view {
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	width: 100%;
}

.whitelist-view__row {
	display: flex;
	flex-direction: row;
}

.whitelist-view__col {
	align-items: stretch;
	box-sizing: border-box;
	display: flex;
	flex: 1 1;
	height: 100%;
	max-width: 100%;
}

.whitelist-view__content {
	margin: 30px;
	padding: 0;
	position: relative;
	width: 100%;
}
