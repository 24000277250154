@import 'helpers';

.w-checkbox {
  margin-right: .5em;
  position: relative;
  font-size: inherit;
  cursor: pointer;
  line-height: 1.25;
  width: 14px;
  height: 14px;
  max-width: 14px;
  max-height: 14px;
  display: inline-block;
  appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
  top: -1px;
  border: 1px solid $color-gray-light;
  border-radius: $corner-radii-medium;
  transition: $transition-fast;
  transition-property: background, border, color, box-shadow;
  background: $color-white;

  &:hover {
    border-color: $color-gray;
  }

  &:focus {
    box-shadow: $focused-outline;
  }

  &--checked,
  &--indeterminate,
  &:checked {
    background: $color-primary;
    border: 1px solid $color-primary;

    &:hover,
    &:focus {
      border-color: $color-primary-darker;
      background-color: $color-primary-darker;
    }
  }

  &--checked,
  &:checked {
    &::before {
      content: '';
      background: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -6 36 35"><path class="st0" fill="none" d="M0,0h24v24H0V0z"/><polygon class="st1" fill="%23fff"  points="19.6,4.2 9,14.8 4.8,10.6 2,13.4 9,20.4 22.4,7 "/></svg>');
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }


  }

  &--indeterminate {
    &::before {
      content: '';
      background: url('data:image/svg+xml, <svg width="100px" height="30px" viewBox="0 0 100 30" version="1.1" xmlns="http://www.w3.org/2000/svg"><rect fill="%23FFFFFF" x="0" y="0" width="100" height="30"></rect></svg>');
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: 80%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &[disabled] {
    opacity: $opacity-disabled;
    pointer-events: none;
    cursor: not-allowed;
  }

  &[readonly] {
    pointer-events: none;
    border-color: rgba($color-gray-light, $opacity-disabled);

    &::before {

    }

    &.w-checkbox--checked,
    &:checked,
    &.w-checkbox--indeterminate {
      background: $color-white;
    }

    &.w-checkbox--checked,
    &:checked {
      &::before {
        background-image: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -6 36 35"><path class="st0" fill="none" d="M0,0h24v24H0V0z"/><polygon class="st1" fill="%231A1A1A"  points="19.6,4.2 9,14.8 4.8,10.6 2,13.4 9,20.4 22.4,7 "/></svg>');
      }

    }

    &.w-checkbox--indeterminate {
      &::before {
        background-image: url('data:image/svg+xml, <svg width="100px" height="30px" viewBox="0 0 100 30" version="1.1" xmlns="http://www.w3.org/2000/svg"><rect fill="%231A1A1A" x="0" y="0" width="100" height="30"></rect></svg>');
      }
    }
  }
}

.w-field {
  &--error  {
    .w-checkbox {
      border-color: $color-danger;
      height: 100%;

      &:hover {
        border-color: $color-danger-dark;
      }

      &--checked,
      &:checked,
      &--indeterminate {
        &:hover {
          background-color: $color-danger-dark;
          border-color: $color-danger-dark;
        }
      }

      &--checked,
      &:checked {
        background-color: $color-danger;
        border-color: $color-danger;
      }

      &--indeterminate {
        background-color: $color-danger;
        border-color: $color-danger;
      }
    }
  }
}
