.release-notes {
    background: var(--w-color-off-white);
}

.release-notes__content {
    max-width: 60rem;
    margin: 0 auto;
    padding: 2rem;
}

.release-notes__back {
    cursor: pointer;
    padding: 0 2rem;
}

.release-notes__content .w-markdown--template--release-notes > p {
    color: var(--w-color-gray-light);
    font-size: 16px;
    font-weight: 400;
    margin: -1rem 0 .5rem;
}

.release-notes__content .w-tag {
    background-color: var(--w-color-primary);
    color: var(--w-color-white);
    font-size: 16px;
    margin: 1rem 0 .5rem;
    padding: .15rem .75rem;
    height: auto;
}

.release-notes__content .w-markdown__ul {
    font-size: 16px;
    margin: 0 0 0 20px;
}

.release-notes__content .w-markdown__hr {
    margin: 1rem 0;
}
