@import '../../wham/scss/utilities/variables';
@import './icons';

@mixin button {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-gray-light;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  color: $color-gray;
  cursor: pointer;
  display: inline-flex;
  font-weight: 400;
  font-size: 1rem;
  height: 34px;
  justify-content: center;
  line-height: 33px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  transition: .2s cubic-bezier(.645,.045,.355,1);
  transition-property: background-color,border,color,box-shadow;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    border-color: $color-gray;
    text-decoration: none;
  }
}

@mixin control-button {
  @include button;

  background-color: $color-off-white;
  border: none;
  border-radius: 2px;
  color: $color-gray-light;
  height: 25px;
  line-height: 25px;
  padding: 0;
  width: 25px;
}

@mixin control-button-icon {
  @include icon;

  color: $color-gray-light;
  pointer-events: none;
}

@mixin player-button {
  @include button;

  border: none;
  border-radius: 14px;
  bottom: 265px;
  display: flex;
  height: 24px;
  padding: 0;
  position: fixed;
  right: 40px;
  width: 24px;

  &:hover &_icon {
    color: $color-primary;
  }
}

@mixin player-button-icon {
  @include icon;

  color: $color-gray-dark;
  font-size: 20px;
  pointer-events: none;
}

