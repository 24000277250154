.header-debug {
  width: 100%;
}

.header-debug__row {
  display: flex;
  flex-direction: row;
  position: relative;
}

.header-debug__row .w-progress {
  position: absolute;
  z-index: 999;
}

.w-search .w-select {
  margin-left: 2em;
  padding-right: 0;
}

.w-input--clear-button {
  box-shadow: none;
}

.w-app-bar__level--search {
  background: white;
  z-index: 1;
  transition: box-shadow .2s;
  border-bottom: 1px solid #e5e5e7;
}

.w-app-bar__level.w-app-bar__level--search > .container {
  min-height: 3.25rem;
  width: 100%;
  padding: 0 30px;
}

.w-app-bar__level--search .w-app-bar__search__field {
  box-shadow: none;
  min-height: 5rem;
  border-radius: 0px;
}

.w-app-bar__level--search .w-app-bar__search__field:focus {
  outline: none;
}

.w-app-bar__level--search:focus-within {
  box-shadow: 0 5px 34px 3px rgba(0,0,0,.1);
}

.w-app-bar .w-app-bar__level {
  border-bottom: 1px solid #e5e5e7;
}

.w-app-bar .w-app-bar__level--search {
  z-index: 1;
  transition: box-shadow .2s;
}
