.header-product-logo {
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 70px;
	justify-content: center;
	padding: 0 .5rem;
	width: 100px;
	border-right: 1px solid var(--w-color-gray-lighter);
}

.header-product-logo.header-product-logo--legacy {
	cursor: default;
}

.header-product-logo:hover {
	text-decoration: none;
}

.header-product-logo__text {
	color: var(--w-color-white);
	font-size: 28px;
	font-weight: 800;
	font-style: italic;
}
