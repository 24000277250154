.creative-summary-record-controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    grid-area: controls;
    padding: 0 14px;
}

.creative-summary-record-controls__button {
    border: none;
    box-shadow: none;
    display: flex;
    height: 25px;
    justify-content: center;
    padding: 0;
    width: 25px;
}

.creative-summary-record-controls__button:focus {
    box-shadow: none;
}

.creative-summary-record-controls__button:hover {
    color: var(--w-color-primary);
    box-shadow: none;
}

.creative-summary-record-controls__icon {
    font-size: 20px;
}

.creative-summary-record-controls__button .creative-summary-record-controls__icon:hover {
    color: var(--w-color-primary);
    cursor: pointer;
}

.creative-summary-record-controls__play-loading {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
    margin-left: 10.5px;
    padding: 6px 0 0 0;
    width: 25px;
}

.creative-summary-record-controls__play-playing {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
    margin-left: 10.5px;
    padding: 0;
    width: 25px;
    cursor: none;
}

.creative-summary-record-controls__play-error {
    align-items: center;
    color: var(--w-color-warning);
    display: flex;
    font-size: 20px;
    height: 25px;
    justify-content: center;
    margin-left: 10.5px;
    padding: 0 4px 0 1px;
    cursor: none;
}

.creative-summary-record-controls__redo-loading {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: center;
    margin-right: 10.5px;
    padding: 6px 0 0 0;
    width: 25px;
    cursor: none;
}

.creative-summary-record-controls__redo-done {
    align-items: center;
    color: var(--w-color-gray-lighter);
    display: flex;
    font-size: 20px;
    height: 25px;
    justify-content: center;
    margin-right: 10.5px;
    padding: 0 2px 0 3px;
}

.creative-summary-record-controls__redo-error {
    align-items: center;
    color: var(--w-color-warning);
    display: flex;
    font-size: 20px;
    height: 25px;
    justify-content: center;
    margin-right: 10.5px;
    padding: 0 2px 0 3px;
    cursor: none;
}
