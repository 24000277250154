.debug-result .debug-item {
  width: 950px;
  padding: var(--w-spacing-large) var(--w-spacing-medium);
}

.debug-item__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.debug-item__header-text {
	color: var(--w-color-gray-lighter);
	display: inline-block;
	margin: 0 0 0 5px;
}

.debug-item__header-text--value {
	color: var(--w-color-gray-light);
}

.debug-item__header-text--label {
	color: var(--w-color-gray);
}

.debug-item__header-left-upper {
	align-items: center;
	display: flex;
	margin-bottom: 5px;
}

.debug-item__header-left-lower {
	align-items: center;
	display: flex;
}

.debug-item__header-pill {
	display: flex;
	margin: 0 0 0 .5rem;
}

.debug-result .debug-item__icon--devid {
	color: #3bc9db;
}

.debug-result .debug-item__icon--ssess {
	color: #f783ac;
}

.debug-result .debug-item__icon--uid {
	color: #ffa94d;
}

.debug-result .debug-item__icon--swid {
	color: var(--w-color-alternative);
}

.debug-result .debug-item__icon--default {
	color: var(--w-color-gray-light);
}

.debug-result .debug-item__flag {
	color: var(--w-color-primary);
	margin-left: .5rem;
}

.debug-result .debug-item__flag.debug-item__flag--toggle {
	cursor: pointer;
}

.debug-result .debug-item__details {
	margin-top: var(--w-spacing-xlarge);
}

.debug-result .debug-item__details > div > .w-tabs__content {
	min-height: 1000px;
}

.debug-result .debug-item .w-layout__column + .w-layout__column {
	border: 0 !important;
}

.debug-result .debug-item__summary__right-column {
	max-width: 45%;
	margin-left: 5%;
}

.debug-result .debug-item__summary__wrapper {
	width: 50%;
}

.debug-result .debug-item__summary__right-column .w-layout__column__content {
	overflow: visible;
}

.debug-result .debug-item__summary__stats {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.debug-result .debug-item__summary__stats .ad {
	display: inline-block;
	width: var(--w-spacing-medium);
	height: var(--w-spacing-medium);
	background: var(--w-color-primary);
	border-radius: 100%;
	margin-right: var(--w-spacing-small);
	margin-bottom: var(--w-spacing-small);
}

.debug-result .debug-item__summary__stats .ad-selected--not-viewed {
	background: none;
	-webkit-box-shadow: inset 0 0 0 3px var(--w-color-primary);
	box-shadow: inset 0 0 0 3px var(--w-color-primary);
}

.debug-result .debug-item__summary__stats .ad-error {
	background: none;
	-webkit-box-shadow: inset 0 0 0 3px var(--w-color-danger);
	box-shadow: inset 0 0 0 3px var(--w-color-danger);
}

.debug-result .debug-item__summary__stats .ad-warn {
	background: none;
	-webkit-box-shadow: inset 0 0 0 3px var(--w-color-warning);
	box-shadow: inset 0 0 0 3px var(--w-color-warning);
}

.debug-result .debug-item__summary__stats .ad-ok {
	background: none;
	-webkit-box-shadow: inset 0 0 0 3px var(--w-color-success);
	box-shadow: inset 0 0 0 3px var(--w-color-success);
}

.debug-result .debug-item__raw-response {
	border: 1px solid var(--w-color-gray-lighter);
	overflow-y: auto;
	background: var(--w-color-white);
	height: 600px;
	display: flex;
	flex-direction: column;
}

.debug-item__raw-response__bar {
	margin: 5px;
	display: flex;
	justify-content: space-between;
}

.debug-item__raw-response__bar__left {
	display: flex;
	align-items: center;
}

.debug-item__raw-response__bar__right {
	display: flex;
	align-items: center;
}

.debug-item__status {
	color: var(--w-color-danger);
	font-weight: 600;
}

.debug-item__status-message {
	color: var(--w-color-danger);
	font-style: italic;
}
