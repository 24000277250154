.settoken-params {
  margin-bottom: 2rem;
}

.settoken-params__row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.settoken-params__key {
  margin-right: 1rem;
  flex: 1;
}

.settoken-params__key--add select {
  color: var(--w-color-gray-light);
  font-style: italic;
}

.settoken-params__value {
  flex: 2;
}

.settoken-params__remove {
  margin-left: .5rem;
  color: var(--w-color-gray-lighter);
  cursor: pointer;
  opacity: 0;
}

.settoken-params__row:hover .settoken-params__remove {
  opacity: 1;
}
