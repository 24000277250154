@import 'helpers';

.w-app-navigation {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;

  &:focus {
    outline: none;
  }

  &--empty {
    .w-app-navigation__context {
      cursor: default;
    }

    .w-app-navigation__menu-icon {
      display: none;
    }
  }

  &--active {
    .w-app-navigation__app-section {
      display: none;
    }

    .w-app-navigation__menu__container {
      display: block;
    }
  }

  &__context {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    user-select: none;
  }

  &__app-name {
    color: $color-gray-dark;
    font-weight: 600;
    margin-left: .5rem;
  }

  &__app-section {
    margin-left: .5rem;

    &::before {
      content: '|';
      margin-right: .5rem;
      color: $color-gray-lighter;
    }
  }

  &__menu {
    min-width: 200px;
    padding-top: 70px;
    text-align: left;
    box-shadow: $shadow-depth-3;

    &__container {
      position: absolute;
      top: -25px;
      left: -30px;
      z-index: 1;
      display: none;
    }
  }
}
