@import 'helpers';

.w-input {
  display: inline-block;
  position: relative;

  input {
    @include input;

    &[type='date']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    &[type="search"]::-webkit-search-decoration,
    &[type="search"]::-webkit-search-cancel-button,
    &[type="search"]::-webkit-search-results-button,
    &[type="search"]::-webkit-search-results-decoration { display: none; }
    &[type="search"] {
      border-right: 0;
    }
  }

  // Sizes
  &--small {
    input {
      height: 22px;
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
    }
  }

  &--large {
    input {
      height: 44px;
      font-size: $font-size-large;
    }
  }

  // Danger
  &--danger {
    z-index: 2;
    input {
      box-shadow: 0 0 0 1px $color-danger;
    }

    &:hover {
      input {
        box-shadow: 0 0 0 1px $color-danger-dark;
      }
    }

    &:focus,
    &:focus-within,
    &:active {
      input {
        box-shadow: 0 0 0 2px $color-danger-darker;
      }
    }
  }

  &--clear-wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: row;
  }

  &--clear-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0.3rem;
    background: white;
    box-shadow: 0 0 0 1px $color-gray-lighter;
    color: $color-gray-light;
    .w-icon {
      cursor: pointer;
    }
  }

  // Subtle
  &.w-input--subtle {
    input {
      border-radius: 0;
      background: transparent;
      padding-left: 0;
      padding-right: 0;
      box-shadow: inset 0px -1px 0 0px $color-gray-lighter;
    }

    &:hover,
    &:focus {
      input {
        box-shadow: inset 0px -1px 0 0px $color-gray-light;
      }
    }

    &.w-input--danger {
      input {
        box-shadow: inset 0px -1px 0 0px $color-danger;
      }

      &:hover,
      &:focus {
        input {
          box-shadow: inset 0px -1px 0 0px $color-danger-dark;
        }
      }
    }
  }

  &__descriptor {
    position: absolute;
    bottom: 5px;
    right: 10px;
    z-index: 4;
    color: $color-gray-light;
    border-radius: 1px;

    &--interactive {
      color: $color-primary;
      cursor: pointer;
      border: 0;
      padding: 0;
      font-size: 1em;
      user-select: none;

      &:hover {
        color: $color-primary-dark;
      }

      &:focus {
        outline: 0;
        box-shadow: $focused-outline;
      }
    }
  }
}



.w-field--small {
  & .w-input input {
    font-size: $font-size-small;
    height: 22px;
  }
}

.w-input--small .w-input__descriptor {
  bottom: 5px;
}

.w-field--large {
  & .w-input input {
    font-size: $font-size-large;
    height: 44px;
  }
}


// Multiline input (textarea)


.w-input--textarea  {
  textarea {
    @include input;

    width: 100%;
    display: block;
    max-height: 600px;
    max-width: 100%;
    min-width: 100%;
    resize: none;
    padding: 8px $spacing-small;
    font-size: $font-size-medium;
    height: 70px;
    line-height: 1.5;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: $color-gray-light;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      color: $color-gray-light;
      font-style: italic;
    }
  }

  &.w-input--textarea--autoheight textarea {
    padding: 6px $spacing-small;
  }

  &.w-input--small textarea {
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
  }

  &.w-input--large textarea {
    font-size: $font-size-large;
  }

  &.w-input--danger textarea {
    border-color: $color-danger;
  }

  &--autoheight {
    textarea {
      height: auto;
    }
  }

  // Sizes
  &.w-field--small {
    @include control-small;
    padding: 4px 10px;
    height: 50px;
  }

  &.w-field--medium {
    @include control-medium;
  }

  &.w-field--large {
    @include control-large;
    padding: 10px;
    height: 95px;
  }

  // Modifiers
  &--fullwidth {
    display: block;
    width: 100%;
  }

  &--inline {
    display: inline;
    width: auto;
  }

  &--autosize {
    min-height: 3rem;
  }

  &--resizeable {
    resize: vertical;
  }

  &.w-textarea--danger:hover,
  &.w-textarea--danger:active,
  &.w-textarea--danger:focus {
    border-color: $color-danger;
  }

  &.w-input--subtle textarea {
    box-shadow: inset 0px -1px 0 0px $color-gray-lighter;
    border-radius: 0;
    background: transparent;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      box-shadow: inset 0px -1px 0 0px $color-gray-light;
    }

    &:focus {
      box-shadow: inset 0px -1px 0 0px $color-gray;
    }
  }
}

.w-field--danger {
  .w-input--textarea  {
    textarea {
      border-color: $color-danger;
    }
  }
}
