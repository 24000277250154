@import 'helpers';

.w-btn {
  @include control;
  @include unselectable;

  background-color: $color-white;
  border-color: $color-gray-light;
  color: $color-gray;
  cursor: pointer;
  justify-content: center;
  padding: 0 $spacing-medium;
  height: 34px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: $font-weight-normal;
  font-size: $font-size-medium;
  line-height: 33px;
  box-sizing: border-box;
  transition: $transition-fast;
  transition-property: background-color, border, color, box-shadow;

  &:empty {
    display: none;
  }

  & + .w-btn,
  & + .w-combo-btn {
    margin-left: 0.75rem;
  }

  &.w-btn--hidden {
    visibility: hidden;
    position: absolute;
    left: -999px;
  }

  .w-icon {

    &:first-child:not(:last-child) {
      margin-left: calc(-.375em - 1px);
      margin-right: .1875em;
    }

    &:last-child:not(:first-child) {
      margin-left: .1875em;
      margin-right: calc(-.375em - 1px);
    }

    &:first-child:last-child {
      margin-left: calc(-.375em - 1px);
      margin-right: calc(-.375em - 1px);
    }
  }

  // States
  &:hover,
  &--hover {
    border-color: $color-gray;
    color: $color-gray;
    text-decoration: none;

  }

  &:focus,
  &--focus {
    border-color: $color-gray-dark;
    box-shadow: $focused-outline;
    color: $color-gray-dark;
  }

  &:active,
  &--active {
    border-color: $color-gray-dark;
    color: $color-gray-dark;
  }

  &[disabled],
  &--disabled {
    opacity: $opacity-disabled;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  // Colors
  &--link {
    background-color: transparent;
    border-color: transparent;
    color: $color-primary;

    &:hover,
    &--hovered,
    &:focus,
    &--focused,
    &:active,
    &--active {
      color: $color-primary-dark;
      border-color: transparent;
    }

    &[disabled] {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  // Sizes
  &--small {
    height: 22px;
    padding: 0 15px;
    font-size: $font-size-small;
    line-height: 21px;
    font-weight: $font-weight-semibold;
  }

  &--large {
    height: 44px;
    padding: 0 $spacing-large;
    line-height: 43px;
  }

  .w-progress-circular.w-button__progress {
    display: flex;
  }

  &--progress {

    color: transparent;
    cursor: default;
    pointer-events: none;

    &:hover {
      color: transparent;
    }
  }

  //Types

  &--primary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:hover,
    &--hover {
      border-color: $color-primary-dark;
      background-color: $color-primary-dark;
    }

    &:focus,
    &--focus {
      border-color: $color-primary-dark;
      background-color: $color-primary-dark;
      box-shadow: $focused-outline;
    }

    &:active,
    &--active {
      border-color: $color-primary-darker;
      background-color: $color-primary-darker;
    }
  }

  &--subtle {
    background-color: transparent;
    border-color: transparent;
    color: $color-gray;
    font-weight: $font-weight-normal;

    &.w-btn--progress {
      color: transparent;

      &:hover {
        color: transparent;
      }
    }

    &:hover,
    &--hover {
      border-color: $color-gray-lighter;
      color: $color-gray;
      background-color: transparent;
    }

    &:focus,
    &--focus {
      border-color: $color-gray-lighter;
      box-shadow: $focused-outline;
      color: $color-gray-dark;
      background-color: transparent;
    }

    &:active,
    &--active {
      border-color: $color-gray-lighter;
      color: $color-gray-dark;
      background-color: transparent;
    }
  }

  &--danger {
    background-color: $color-danger;
    border-color: $color-danger;
    color: $color-white;

    &:hover,
    &--hover {
      border-color: $color-danger-dark;
      background-color: $color-danger-dark;
    }

    &:focus,
    &--focus {
      border-color: $color-danger-dark;
      box-shadow: $focused-outline;
      background-color: $color-danger-dark;
    }

    &:active,
    &--active {
      border-color: $color-danger-darker;
      background-color: $color-danger-darker;
    }
  }

  &--primary,
  &--danger {
    &:focus,
    &--focus,
    &:hover,
    &--hover,
    &:active,
    &--active {
      color: $color-white;
    }

    &.w-btn--progress {
      color: transparent;

      &:hover {
        color: transparent;
      }
    }
  }

  //Inverse
  &--inverse {
    border-color: rgba($color-white, .8);
    color: $color-white;
    background-color: transparent;

    &:hover {
      border-color: $color-white;
      background-color: transparent;
      color: $color-white;
    }

    &:focus {
      border-color: $color-white;
      box-shadow: 0 0 0 3px rgba($color-white, .5);
      color: $color-white;
    }

    &.w-btn--primary,
    &.w-btn--danger {
      background-color: rgba($color-white, 1);
      border-color: rgba($color-white, 0);
    }

    &.w-btn--primary {
      color: $color-primary;

      &:hover,
      &:focus {
        color: $color-primary-darker;
      }
    }

    &.w-btn--danger {
      color: $color-danger;

      &:hover,
      &:focus {
        color: $color-danger-darker;
      }
    }

    &.w-btn--subtle {
      border-color: rgba($color-white, 0);

      &:hover,
      &:focus {
        border-color: $color-white;
      }
    }

    &.w-btn--link {
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    }

  }

  // Modifiers
  &[disabled] {
    opacity: $opacity-disabled;
  }

  &--fullwidth {
    display: flex;
    width: 100%;
  }

  & + .w-btn--fullwidth {
    margin-left: 0;
    margin-top: .75rem;
  }

  // Adjustment for vertical spacing
  // sass-lint:disable no-qualifying-elements, force-attribute-nesting
  button,
  input[type='submit'].w-btn {
    line-height: 1;
    padding-bottom: .4em;
    padding-top: .35em;
  }

  .w-icon {
    pointer-events: none;
  }
}
