.date-range-picker {
    display: flex;
    width: 100%;
}

.date-range-picker__background {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10011;
}

.date-range-picker__content {
    align-items: center;
    background-color: var(--w-color-white);
    border-radius: 4px;
    box-shadow: 0 10px 10px 4px rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    left: calc(50% - 168.5px);
    position: absolute;
    top: 1px;
    width: 337px;
    height: 357px;
    z-index: 10012;
}

.date-range-picker__fields {
    display: flex;
    padding: 10px 20px;
    width: 100%;
}

.date-range-picker__field-wrapper {
    display: flex;
    width: 100%;
}

.date-range-picker__field-wrapper--end {
    justify-content: flex-end;
}

.date-range-picker__field {
    display: flex;
    flex-direction: column;
}

.date-range-picker__label {
    font-weight: bold;
}

.date-range-picker__input {
    background-color: var(--w-color-white);
    border: 1px solid var(--w-color-gray-lighter);
    border-radius: 4px;
    font-size: 1rem;
    padding: 4px 8px;
    position: relative;
    width: 116px;
}

.date-range-picker__input:focus {
    border-color: var(--w-color-gray-light);
    outline: none;
}

.date-range-picker__input:hover {
    border-color: var(--w-color-primary);
}

.date-range-picker__input.date-range-picker__input--invalid {
    border: 1px solid var(--w-color-danger);
}

.date-range-picker__input.date-range-picker__input--invalid:focus {
    border: 1px solid var(--w-color-danger);
    outline: none;
}

.date-range-picker__input--warn {
    color: var(--w-color-gray-light);
}

.react-datepicker__day--selected.date-range-picker__input--warn {
    background-color: var(--w-color-gray-light);
    color: var(--w-color-white);
}

.react-datepicker__day--in-range.date-range-picker__input--warn {
    background-color: var(--w-color-gray-lighter);
    color: var(--w-color-gray);
}

.react-datepicker__day--in-selecting-range.date-range-picker__input--warn {
    color: var(--w-color-white);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range.date-range-picker__input--warn {
    background-color: var(--w-color-gray-light);
    color: var(--w-color-white);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-selecting-range:not(.date-range-picker__input--warn) {
    background-color: var(--w-color-primary-dark);
    color: var(--w-color-white);
}

.date-range-picker__footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;
}

.date-range-picker__controls {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.date-range-picker__button:focus {
    box-shadow: none;
}
