.debug-dates {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.debug-dates__control {
    align-items: center;
    display: flex;
    margin: 7px 0;
    max-width: 337px;
    min-width: 252px;
    width: 100%;
}

.debug-dates__control--facets {
    margin: 0;
    max-width: initial;
    min-width: initial;
    width: 337px;
}

.debug-dates__control .w-select {
    width: 100%;
}

.debug-dates__control .w-select select {
    z-index: 4;
}

.debug-dates__control--facets .w-select select:focus {
    box-shadow: 0 0 0 1px var(--w-color-gray-lighter);
}

.debug-dates__control--facets .w-select select:hover {
    box-shadow: 0 0 0 1px var(--w-color-primary);
}

.debug-dates__field {
    align-items: center;
    display: flex;
    width: 100%;
}

.debug-dates__label {
    font-weight: bold;
    margin: 0 .5rem 0 0;
}

.debug-dates__select {
    width: 100%
}

.debug-dates__select--custom {
    width: 100%
}

.debug-dates__calendar {
    display: flex;
    padding: 0 0 0 10px;
}

.debug-dates__calendar-btn {
    background-color: var(--w-color-off-white);
    border: none;
    border-radius: 2px;
    box-shadow: none;
    color: var(--w-color-gray-light);
    height: 25px;
    padding: 0;
    width: 25px;
}

.debug-dates__calendar-btn:focus {
    box-shadow: none;
}

.debug-dates__picker {
    position: relative;
    width: 250px;
}

.debug-dates__picker-warning {
    align-items: center;
    display: flex;
    left: -23px;
    position: absolute;
    top: 318px;
    z-index: 10012;
}

.debug-dates__picker-warning-icon {
    color: var(--w-color-warning);
    cursor: context-menu;
}

.debug-dates__picker-warning-text {
    font-size: 10px;
}

.debug-dates__picker-warning-tooltip {
    box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
    border-radius: 3px;
    z-index: 10013;
}
