.debug-facet {
	border-bottom: 1px solid var(--w-color-gray-lighter);
	display: flex;
	flex-direction: column;
	position: relative;
}

.debug-facet__reset-button {
	background-color: var(--w-color-off-white);
	border: none;
	border-radius: 2px;
	box-shadow: none;
	color: var(--w-color-gray-light);
	height: 19px;
	padding: 0;
	width: 19px;
}

.debug-facet__reset-button:focus {
	box-shadow: none;
	color: var(--w-color-gray);
}

.debug-facet__reset-button:hover {
	color: var(--w-color-gray);
}

.debug-facet__header {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	margin: 0 0 0 -3px;
	padding: 10px 0;
}

.debug-facet__title-wrapper {
	align-items: center;
	display: flex;
}

.debug-facet__title-icon {
	color: var(--w-color-gray-light);
	cursor: pointer;
	font-size: 1.4rem;
}

.debug-facet__title {
	color: var(--w-color-gray-dark);
	font-weight: 600;
}

.debug-facet__content {
	display: flex;
	flex-direction: column;
	padding: 0 0 10px 0;
}

.debug-facet__option {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.debug-facet__option-wrapper {
	align-items: center;
	display: flex;
	height: 21px;
	position: relative;
	width: 100%;
}

.debug-facet__option-input {
	cursor: pointer;
}

.debug-facet--pending .debug-facet__option-input {
	cursor: default;
}

.debug-facet__option-label {
	background-color: var(--w-color-off-white);
	color: var(--w-color-gray-dark);
	cursor: pointer;
	max-width: 140px;
	overflow: hidden;
	padding: 0 0 0 7px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.debug-facet--pending .debug-facet__option-label {
	color: var(--w-color-gray-light);
	cursor: default;
}

.debug-facet__option-label.debug-facet__option-label--expanded {
	border-radius: 4px;
	box-shadow: 0 7px 10px 4px rgba(0, 0, 0, .2);
	left: 12px;
	overflow: visible;
	margin: 0 2px;
	max-width: initial;
	min-width: 140px;
	position: absolute;
	padding: 0 5px;
	top: 0;
	width: initial;
	z-index: 1;
}

.debug-facet__option:hover .debug-facet__option-label {
	color: var(--w-color-primary);
}

.debug-facet--pending .debug-facet__option:hover .debug-facet__option-label {
	color: var(--w-color-gray-light);
}

.debug-facet__option-count {
	color: var(--w-color-gray-light);
	font-size: 13px;
}

.debug-facet__option-none {
	color: var(--w-color-gray-light);
	text-align: center;
}

.debug-facet__others {
	display: flex;
	justify-content: space-between;
}

.debug-facet__other-label {
	color: var(--w-color-gray-light);
}

.debug-facet__others-count {
	color: var(--w-color-gray-light);
	font-size: 13px;
}

