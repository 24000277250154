.error-modal .w-message--floating {
	bottom: initial;
	top: 6rem;
}

.error-modal__background {
	background-color: black;
	height: 100%;
	left: 0;
	opacity: .3;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 12;
}

.error-modal__message {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.error-modal__text {
	font-weight: 500;
}
