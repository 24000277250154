.w-mark {
  width: 30px;
  height: 30px;
  display: inline-block;

  svg {
    object-fit: contain;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.w-mark--small {
  width: 20px;
  height: 20px;
}

.w-mark--large {
  height: 50px;
  width: 50px;
}

.w-mark--xlarge {
  height: 80px;
  height: 80px;
}

.w-mark--monochrome .w-mark__element {
  fill: #000;
}

.w-mark--inverse .w-mark__element {
  fill: #fff;
}
