.user-menu--wrapper {
  direction: rtl;
  height: 70px;
  min-width: 68px;
  position: relative;
}

.user-menu--wrapper.active {
  background: var(--w-color-primary);
}

.user-menu--avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1.4286rem;
}

.user-menu--avatar-wrapper .w-avatar,
.user-menu--avatar-wrapper .w-icon {
  cursor: pointer;
}

.user-menu--dropdown {
  position: absolute;
  text-align: left;
  top: 70px;
  z-index: 100;
}
