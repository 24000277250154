@mixin arrow($color) {
  border: 1px solid $color;
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: .5em;
  pointer-events: none;
  position: absolute;
  transform: rotate(-45deg);
  width: .5em;
}

@mixin block {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@mixin clearfix {
  &::after {
    clear: both;
    content: ' ';
    display: table;
  }
}

@mixin center($width, $height: 0) {
  position: absolute;

  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  } @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 2));
  }
}

@mixin delete {
  @include unselectable;

  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba($color-gray-dark, .2);
  border: 0;
  border-radius: $corner-radii-pill;
  cursor: pointer;
  display: inline-block;

  height: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;

  &::before,
  &::after {
    background-color: $color-white;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  &::before {
    height: 2px;
    width: 50%;
  }

  &::after {
    height: 50%;
    width: 2px;
  }

  &:hover,
  &:focus {
    background-color: rgba($color-gray-dark, .3);
  }

  &:active {
    background-color: rgba($color-gray-dark, .4);
  }

  // Sizes
  &.is-small {
    height: 16px;
    width: 16px;
  }

  &.is-medium {
    height: 24px;
    width: 24px;
  }

  &.is-large {
    height: 32px;
    width: 32px;
  }
}

@mixin fa($size, $dimensions) {
  display: inline-block;
  font-size: $size;
  height: $dimensions;
  line-height: $dimensions;
  text-align: center;
  vertical-align: top;
  width: $dimensions;
}

@mixin hamburger($dimensions) {
  cursor: pointer;
  display: block;
  height: $dimensions;
  position: relative;
  width: $dimensions;

  span {
    background-color: $color-gray;
    display: block;
    height: 1px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: 50%;
    transition: none $transition-duration $transition-easing;
    transition-property: background, left, opacity, transform;
    width: 15px;

    &:nth-child(1) {
      margin-top: -6px;
    }

    &:nth-child(2) {
      margin-top: -1px;
    }

    &:nth-child(3) {
      margin-top: 4px;
    }
  }

  &:hover {
    background-color: $color-white;
  }

  // Modifers
  &.is-active {
    span {
      background-color: $color-primary;

      &:nth-child(1) {
        margin-left: -5px;
        transform: rotate(45deg);
        transform-origin: left top;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        margin-left: -5px;
        transform: rotate(-45deg);
        transform-origin: left bottom;
      }
    }
  }
}

@mixin loader {
  animation: spinAround 500ms infinite linear;
  border: 2px solid $color-gray-light;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}

@mixin overflow-touch {
  -webkit-overflow-scrolling: touch;
}

@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

@mixin placeholder {
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $breakpoint-tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $breakpoint-tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $breakpoint-desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $breakpoint-desktop) and (max-width: $breakpoint-widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $breakpoint-widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $breakpoint-widescreen) and (max-width: $breakpoint-fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $breakpoint-fullhd) {
    @content;
  }
}

@mixin font-face(
  $name,
  $font-files,
  $weight: false,
  $style: false
) {
  @font-face {
    font-family: quote($name);
    @if $weight { font-weight: $weight; }
    @if $style { font-style: $style; }
    src: $font-files;
  }
}

@function scrimGradient($startColor: $color-black, $direction: 'to bottom') {

  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  @return linear-gradient(unquote($direction), $stops);

}
