.in-page-modal {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9997;
}

.in-page-modal--background {
  background-color: black;
  height: 100%;
  left: 0;
  opacity: .6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.in-page-modal--wrapper {
  background: white;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
  margin: 5%;
  width: 90%;
}

.in-page-modal--header {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  border-bottom: solid 1px #efefef;
  font-size: 20px;
  font-weight: bold;
  color: #1a1a1a;
}

.in-page-modal--content {
  height: 60vh;
  padding: 20px;
  display: flex;
  overflow-y: auto;
}

.in-page-modal--footer {
  border-top: solid 1px #efefef;
  display: flex;
  justify-content: flex-end;
  padding: 1.4rem;
  width: 100%;
}

.in-page-modal--close {
  color: var(--w-color-white);
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 20px;
}