.w-whats-new__icon {
	cursor: pointer;
}

.w-whats-new__content__release .w-markdown--template--release-notes > p {
	color: var(--w-color-gray-light);
	margin: -1rem 0 .5rem 0;
}

.w-whats-new__content__release .w-tag {
	background-color: var(--w-color-primary);
	color: var(--w-color-white);
	margin: 1rem 0 .5rem 0;
	padding: .15rem .75rem;
	height: auto;
}

.w-whats-new__content__release .w-markdown__ul {
	margin: 0 0 0 20px;
}

.w-whats-new__content__release .w-markdown__hr {
	margin: 1rem 0;
}
