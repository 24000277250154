@import '../../wham/scss/utilities/variables';

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav {
  align-items: center;
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  height: 50px;
  margin: 0 0 10px 0;
  width: 100%;

  &_title {
    color: $color-gray;
    font-size: 28px;
    font-weight: 300;
    padding: 0 10px 0 0;
  }

  &_list {
    display: flex;
  }

  &_item {
    padding: 0 10px;
  }

  &_link {
    color: $color-gray-light;
    font-size: 20px;
    font-weight: 400;
    padding: 0 0 12px 0;

    &:hover {
      text-decoration: none;
    }
  }

  &_active {
    border-bottom: 1px solid $color-gray;
    color: $color-gray;
  }
}

.trafficking {
  display: none;

  &__active {
    display: flex;
    flex-direction: column;
    margin: 0 0 75px 0;

    & header {
      display: none;
    }
  }
}

.validation {
  display: none;

  &__active {
    display: flex;
    flex-direction: column;

    & h2 {
      display: none;
    }
  }
}
