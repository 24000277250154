@import 'helpers';

.w-badge {
  background: $color-gray;
  color: $color-white;
  height: 19px;
  line-height: 19px;
  padding: 0 .25em;
  border-radius: $corner-radii-medium;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  display: inline-block;

  &--primary {
    background: $color-primary;
  }

  &--warning {
    background: $color-warning;
  }

  &--success {
    background: $color-success;
  }

  &--danger {
    background: $color-danger;
  }

  &--small {
    font-size: $font-size-small;
    height: 15px;
    line-height: 15px;
  }

  &--inverse {
    background: $color-white;
    color: $color-gray;

    &.w-badge--primary {
      color: $color-primary;
    }

    &.w-badge--success {
      color: $color-success;
    }

    &.w-badge--warning {
      color: $color-warning;
    }

    &.w-badge--danger {
      color: $color-danger;
    }
  }
}
