@import 'helpers';

.w-container {
  border: 1px solid $color-gray-lighter;
  width: 100%;
  background: $color-white;

  &--collapsible {
    .w-container__header {
      cursor: pointer;
      padding-left: $spacing-large;
      user-select: none;

      &::before {
        @include arrow($color-gray-light);
        transform: rotate(-45deg);

        margin-top: -.375em;
        left: 10px;
        top: 50%;
        z-index: 4;
        transition: $transition-fast;
      }
    }

    &.w-container--collapsed {
      .w-container__header {
        &::before {
          transform: rotate(-135deg);
          margin-top: -.25em;
        }
      }

      .w-container__content {
        max-height: 0;
        overflow: hidden;
        border-color: transparent;
      }
    }
  }

  &__header {
    position: relative;
    padding: $spacing-small;
    color: $color-gray-dark;
    font-weight: $font-weight-semibold;
  }

  &__content {
    box-sizing: border-box;
    overflow: auto;
    transition: $transition-fast;

    &__box {
      padding: $spacing-small;
    }
  }

  & + .w-container {
    margin-top: -1px;
  }
}
