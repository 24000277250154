@import 'helpers';

.w-switch {
  display: flex;
  align-items: center;
  flex-direction: row;

  &.w-switch--readonly {
    pointer-events: none;
  }

  &--disabled {
    opacity: .4;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.w-switch__label {
  margin-left: $spacing-small;
}

.w-switch__container {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 0 0 50px;
  width: 50px;
  height: 24px;
  background-color: $color-white;
  border-radius: 24px;
  margin-top: 1px;
}

.w-switch__input {
  appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 24px;
  border-radius: 24px;

  &.w-checkbox--checked {
    &::before {
      display: none;
    }
  }

  &:focus {
    outline: none;
    box-shadow: $focused-outline;
  }

  &[readonly] {
    pointer-events: none;

    & + .w-switch__input__label {
      pointer-events: none;
    }
  }
}

.w-switch__input__label {
  position: relative;
  display: block;
  height: inherit;

  text-transform: uppercase;
  background: $color-white;
  border-radius: inherit;
  border: 1px solid $color-gray-light;
  transition: $transition-fast;

  .w-switch__input:hover + &,
  .w-switch__input:focus + & {
    border-color: $color-gray;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    font-weight: bold;
    transition: $transition-fast;
  }

  &::before {
    right: 11px;
    color: $color-gray-dark;

  }

  &::after {
    left: 11px;
    color: $color-white;
    opacity: 0;
  }

  .w-switch__input.w-checkbox--checked ~ & {
    background: $color-primary;
    border-color: $color-primary;

    &::before { opacity: 0; }
    &::after { opacity: 1; }
  }

  .w-switch__input.w-checkbox--checked:hover ~ & {
    background-color: $color-primary-dark;
    border-color: $color-primary-dark;
    transition: $transition-fast;
  }
}

.w-switch__handle {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  background: $color-gray;
  background-image: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -6 36 35"><path class="st0" fill="none" d="M0,0h24v24H0V0z"/><polygon class="st1" fill="%23008BCC"  points="19.6,4.2 9,14.8 4.8,10.6 2,13.4 9,20.4 22.4,7 "/></svg>');
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;
  background-position: -20px center;
  border-radius: 10px;
  transition: background-color $transition-fast, left $transition-fast, background-position .3s;
  transition-timing-function: linear, ease-in, ease-out;

  .w-switch__input.w-checkbox--checked ~ & {
    left: calc(100% - 18px - 3px);
    background-color: $color-white;
    background-position: 0 center;
  }

  .w-switch__input:focus {
    box-shadow: $focused-outline;
  }
}

.w-field--disabled {
  opacity: $opacity-disabled;
  cursor: not-allowed;
  pointer-events: none;

  .w-switch {
    opacity: 1;
  }
}

.w-field--error,
.w-switch--danger {
  .w-switch__input__label {
    border-color: $color-danger;

    &::before {
      color: $color-danger;
    }
  }

  .w-switch__input.w-checkbox--checked ~ .w-switch__input__label {
    background-color: $color-danger;
    border-color: $color-danger;
    color: $color-white;
  }

  .w-switch__handle {
    background-color: $color-danger;
    background-image: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -6 36 35"><path class="st0" fill="none" d="M0,0h24v24H0V0z"/><polygon class="st1" fill="%23E8402A"  points="19.6,4.2 9,14.8 4.8,10.6 2,13.4 9,20.4 22.4,7 "/></svg>');

    .w-switch__input.w-checkbox--checked ~ & {
      background-color: $color-white;
    }
  }

  .w-switch__input:hover {
    + .w-switch__input__label {
      border-color: $color-danger-dark;

      &::before {
        color: $color-danger-dark;
      }
    }

    &.w-checkbox--checked ~ .w-switch__input__label {
      background-color: $color-danger-dark;
      border-color: $color-danger-dark;
    }

    & ~ .w-switch__handle {
      background-color: $color-danger-dark;
    }

    &.w-checkbox--checked ~ .w-switch__handle {
      background-color: $color-white;
    }
  }
}

.w-field--readonly .w-switch,
.w-switch.w-switch--readonly {
  .w-switch__container {
    flex: 0 0 24px;
    width: 24px;
    position: relative;
  }

  .w-switch__input__label {
    width: 24px;
    border: 2px solid $color-gray;
    position: relative;

    &::before {
      content: '';
      display: block;
      height: 22px;
      width: 2px;
      background-color: $color-gray;

      position: relative;

      transform: translateX(20px) translateY(-4px) rotate(45deg);
      z-index: 2;
    }
  }

  .w-switch__handle {
    display: none;
  }

  .w-switch__input.w-checkbox--checked {
    & ~ .w-switch__handle {
      display: block;
      background-color: #fff;
      background-image: url('data:image/svg+xml, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="-6 -6 36 35"><path class="st0" fill="none" d="M0,0h24v24H0V0z"/><polygon class="st1" fill="%235e5e5e"  points="19.6,4.2 9,14.8 4.8,10.6 2,13.4 9,20.4 22.4,7 "/></svg>');
    }

    & ~ .w-switch__input__label {
      background-color: #fff;
    }
  }


}
