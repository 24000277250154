@import 'helpers';

.w-whats-new {
  position: relative;

  &--active {
    .w-whats-new__icon {
      color: $color-success-darker;
      animation: none;
    }
  }
  .w-popover {
    z-index: $z-index-modal;
  }
}

.w-whats-new__icon {
  line-height: 1.5;
  color: $color-success;
  animation-duration: 3s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

.w-whats-new__content-container {
  position: absolute;
  top: 50px;
}

.w-popover.w-whats-new__content {
  margin-top: 0;
  width: 400px;
  position: relative;
  margin-top: 0 !important;
  text-align: left;
  padding:0;
  flex-direction: column;

  &.w-popover--active {
    display: flex;
  }
}

.w-whats-new__content__release__notes {
  margin-top: 10px;
}

.w-whats-new__content__release__notes li + li {
  margin-top: 10px;
}

.w-whats-new__content__release__block-header {
  margin-top: 30px;
}

.w-whats-new__content__release__date {
  margin-top: -20px;
  color: $color-gray-light;
  display: block;
  margin-bottom: 10px;
}

.w-whats-new__content__release {
  padding: 30px;
  overflow-y: auto;
  max-height: 500px;
  height: 70vh;
}

.w-whats-new__content__controls {
  border-top: 1px solid $color-gray-lighter;
  padding: 20px 30px;
  display: flex;
  align-items: center;

  a {
    justify-self: flex-end;
    text-align: right;
    flex-grow: 1;
  }
}
