.header-partner-menu {
	align-items: center;
	display: flex;
	height: 70px;
	justify-content: center;
	position: relative;
	width: 70px;
}

.header-partner-menu .w-partner-selector .w-menu {
	min-width: 250px;
}

.header-partner-menu:hover::after {
	border: 1px solid var(--w-color-white);
	border-right: 0;
	border-top: 0;
	content: " ";
	display: block;
	height: 7px;
	pointer-events: none;
	position: absolute;
	-webkit-transform: rotate(-45deg);
	margin-top: -5.25px;
	left: calc(50% - 3.5px);
	bottom: 15%;
	transform: rotate(-45deg);
	width: 7px;
	z-index: 4;
}
