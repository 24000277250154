.header {
  background-color: var(--w-color-white);
  border-bottom: 1px solid var(--w-color-gray-lighter);
  display: flex;
  height: 70px;
  justify-content: space-between;
  width: 100%;
}

.header__left {
  align-items: center;
  display: flex;
  justify-content: center;
}

.header__middle {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.header__middle .filter-bar__value,
.header__middle .search-bar__value {
  max-width: 24rem;
  min-width: 14rem;
}
