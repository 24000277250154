@import '../../wham/scss/utilities/variables';

.validation {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  width: 100%;

  &_title {
    color: $color-gray;
    font-size: 28px;
    font-weight: 300;
  }

  &_result {
    color: $color-success;
    font-size: 20px;

    &__failed {
      color: $color-danger;
    }

    &_message {
      padding: 0 7px 0 0;
    }

    &_description {
      font-style: italic;
    }
  }
}

.content {
  width: 100%;
}

.loader {
  display: flex;
  width: 100%;
}

.spec {
  :global(.validator_spectable-profiles_button) {
    position: absolute;
    bottom: 46px;
  }

  :global(.validator_spectable-profiles_button--hide-profiles) {
    width: 190px;
    left: calc(50% - 95px);
  }

  :global(.validator_spectable-profiles_button--show-profiles) {
    width: 140px;
    left: calc(50% - 70px);
  }
}
