@import 'helpers';

.w-markdown {
  &__blockquote {
    font-size: 1.5em;
    margin-left: 1em;
    color: $color-gray;
    quotes: "\201C""\201D""\2018""\2019";
    font-style: italic;

    p::before {
      content: open-quote;
    }

    p::after {
      content: close-quote;
    }
  }

  &__ul, &__ol {
    margin-left: 20px;

    li + li {
      margin-top: .75em;
    }

    ul, ol {
      li {
        margin-top: .75em;
      }
    }
  }

  &__ul {
    list-style: disc;
  }

  p {
    margin: 1em 0;
  }
}

.w-markdown--small {
  .w-markdown__h1 {
    font-size: 28px;
  }

  .w-markdown__h2 {
    font-size: 20px;
    font-weight: normal;
  }

  .w-markdown__h3 {
    font-size: 14px;
    font-weight: bold;
  }

  .w-markdown__h4 {
    font-size: 14px;
  }

  .w-markdown__h5, .w-markdown__h6 {
    font-size: 10px;
    font-weight: bold;
  }
}

.w-markdown--large {
  font-size: 1.25em;
  line-height: 1.75;

  &__ul, &__ol {

    li + li {
      margin-top: 1.5em;
    }

    ul, ol {
      li {
        margin-top: 1.5em;
      }
    }
  }
}

.w-markdown--template--release-notes {
  .w-markdown__h3 {
    margin-top: 2em;
    margin-bottom: 1em;
    font-weight: normal;
    font-size: inherit;
    padding: .15em .75em;
    height: auto;
  }

  .w-markdown__hr {
    opacity: 0;
    margin: 5em 0;
  }

  .w-markdown__h4 {
    margin-top: -1em;
    margin-bottom: 1.5em;
    color: $color-gray-light;
  }

  .w-markdown__ul {
    list-style: none;
    margin-left: 0;

    li + li {
      margin-top: 1.25em;
    }

    .w-markdown__ul {
      margin-left: 2em;
      list-style: disc;

      li + li {
        margin-top: .75em;
      }
    }
  }
}
