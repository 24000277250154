@import '../../wham/scss/utilities/variables';
@import '../../scss/mixins/utilities';

.trafficking {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  display: flex;
  margin: 0 0 10px 0;
  width: 100%;

  &_title {
    color: $color-gray;
    font-size: 28px;
    font-weight: 300;
  }
}

.empty {
  color: $color-gray-dark;
  font-size: 18px;
  font-weight: 400;
}

.error {
  &_text {
    color: $color-danger;
    font-size: 18px;
    font-weight: 400;
  }

  &_again {
    color: $color-gray-light;
    padding: 0 0 0 10px;
  }

  &_action {
    color: $color-primary;
    cursor: pointer;
    padding: 0 4px;
  }
}

.left {
  padding: 0 25px 0 0;
  width: 50%;
}

.loader {
  display: flex;
  width: 100%;
}

.table {
  table-layout: fixed;
}

.row {
  border-bottom: 1px solid $color-gray-lighter;
  display: flex;
  height: 35px;
  margin: 12px 0 0 0;
}

.cell {
  display: block;
  width: 285px;
}

.key {
  font-weight: 700;
  padding: 8px 5px 0 0;
  min-width: 150px;
  max-width: 150px;
  text-align: right;
}

.value {
  padding: 8px 0 0 5px;
}

.truncate {
  @include ellipsis-truncate;
}
