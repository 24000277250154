.dashboard-view {
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	width: 100%;
}

.dashboard-view__row {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
