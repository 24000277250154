.key-value-pair-table {
	background-color: var(--w-color-white);
	flex: 1;
	overflow-y: scroll;
}

.key-value-pair-table__url-container {
	margin: 0 0 28px 0;
}

.key-value-pair-table__url-header {
	display: flex;
	flex-direction: column;
}

.key-value-pair-table__url {
	font-weight: 400;
}

.key-value-pair-table__url-title {
	background: var(--w-color-gray);
	border-radius: 2px;
	color: var(--w-color-white);
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	height: 15px;
	line-height: 15px;
	padding: 0 3px;
}

.key-value-pair-table__params-header {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.key-value-pair-table__params-add {
	border: none;
	border-radius: 2px;
	box-shadow: none;
	height: 25px;
	padding: 0;
	width: 25px;
}

.key-value-pair-table__params-add:focus {
	box-shadow: none;
}

.key-value-pair-table__params-title {
	background: var(--w-color-gray);
	border-radius: 2px;
	color: var(--w-color-white);
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	height: 15px;
	line-height: 15px;
	padding: 0 3px;
}

.key-value-pair-table__row {
	border-bottom: 1px solid var(--w-color-gray-lighter);
	cursor: pointer;
}

.key-value-pair-table__row .key-value-pair-table__delete-icon.w-icon {
	font-size: 18px;
}

.key-value-pair-table__cell {
	height: 30px;
	vertical-align: middle;
}

.key-value-pair-table__col-key {
	text-align: right;
}

.key-value-pair-table__col-value {
	width: 100%;
}

.key-value-pair-table__col-delete {
	position: relative;
}

.key-value-pair-table__key {
	color: var(--w-color-success);
	font-size: 11px;
	font-weight: 700;
	padding: 0 5px 0 0;
	text-align: right;
	width: 100%;
}

.key-value-pair-table__value {
	align-items: center;
	display: flex;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 480px;
}

.key-value-pair-table__value--offset {
	width: calc((100vw - 600px) / 2);
}

.key-value-pair-table__value-part {
	color: var(--w-color-primary-dark);
	font-size: 13px;
	padding: 0;
	text-align: left;
}

.key-value-pair-table__value-part:first-child {
	padding: 0 0 0 5px;
}

.key-value-pair-table__value-part--macro {
	color: var(--w-color-alternative);
}

.key-value-pair-table__delete {
	border: none;
	border-radius: 2px;
	box-shadow: none;
	color: var(--w-color-gray-lighter);
	height: 25px;
	padding: 0;
	position: absolute;
	top: 2px;
	right: 0;
	width: 25px;
}

.key-value-pair-table__delete:focus {
	box-shadow: none;
	color: var(--w-color-gray-lighter);
}

.key-value-pair-table__delete:hover {
	color: var(--w-color-danger);
}
