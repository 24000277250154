@import 'helpers';

.w-app-bar-2 {
  z-index: $z-index-appbar;
  background: rgba($color-white, .93);
  width: 100%;

  .w-app-bar__level {
    align-items: stretch;
    display: flex;
    height: 3.25rem;
    position: relative;
    text-align: center;
    border-bottom: 1px solid $color-gray-lighter;

    & > .container {
      align-items: stretch;
      display: flex;
      min-height: 3.25rem;
      width: 100%;
      margin: 0 auto;
      // padding: 0 $spacing-large;
    }
  }

  .w-app-bar__nav-item {
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.5;
    padding: .5rem $spacing-medium;
    max-width: 100%;

    &:only-child {
      padding: .5rem 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      right: 0;
    }
    a {
      flex-grow: 1;
      flex-shrink: 0;
    }

    img {
      max-height: 1.75rem;
    }

    .tag {
      &:first-child:not(:last-child) {
        margin-right: .5rem;
      }

      &:last-child:not(:first-child) {
        margin-left: .5rem;
      }
    }

    // Responsiveness
    @include mobile {
      justify-content: flex-start;
    }
  }

  .w-app-bar__nav-left,
  .w-app-bar__nav-right {
    @include overflow-touch;

    align-items: stretch;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    //max-width: 35%;
    overflow: visible;

    @include widescreen {
      flex-basis: 0;
    }
  }

  .w-app-bar__nav-left {
    justify-content: flex-start;
    white-space: nowrap;
  }

  .w-app-bar__nav-right {
    justify-content: flex-end;
    margin-right: $spacing-medium;

    > * + > *:not(:last-child) {
      margin-right: $spacing-small;
    }

    .w-app-bar__nav-item {
      padding-right: 0;
    }
  }

  .w-app-bar__context,
  .w-app-bar__nav-center {
    align-items: stretch;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 30%;
  }

  .w-app-bar__nav-center {
    .w-app-bar__context {
      display: block;
      max-width: none;
    }
  }

  .w-app-bar__level--nav {
    z-index: 2;
  }

  .w-app-bar__level--nav,
  .w-app-bar__level--search {
    min-height: 5rem;
  }

  .nav-right * {
    margin-left: .5em; // TODO: Remove magic number
  }


  &--danger {

    .w-app-bar__level--nav {
      background: rgba($color-danger-lighter, .4);
      border-bottom-color: $color-danger;
    }

    .w-app-bar__context__primary--relates-state,
    .w-app-bar__context__secondary--relates-state {
      color: $color-danger;
    }
  }

  &--warning {

    .w-app-bar__level--nav {
      background: rgba($color-warning-lighter, .4);
      border-bottom-color: $color-warning;
    }

    .w-app-bar__context__primary--relates-state,
    .w-app-bar__context__secondary--relates-state {
      color: $color-warning;
    }
  }
}
