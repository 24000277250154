.filter-bar {
	width: 100%;
}

.filter-bar__form {
	align-items: center;
	display: flex;
	justify-content: center;
}

.filter-bar__value {
	position: relative;
	width: 100%;
}

.filter-bar__icon {
	color: var(--w-color-gray-light);
	left: 5px;
	position: absolute;
	top: calc(50% - 11px);
}

.filter-bar__input {
	-webkit-appearance: none;
	border: 1px solid var(--w-color-gray-lighter);
	border-radius: 3px;
	color: var(--w-color-primary);
	font-size: 1rem;
	height: 34px;
	min-width: 14rem;
	padding: 5px 8px 5px 33px;
	text-overflow: ellipsis;
	width: 100%;
}

.filter-bar__input:focus {
	outline: none;
}

.filter-bar__input::placeholder {
	color: var(--w-color-gray-light);
	font-style: italic;
}

.filter-bar__submit {
	display: none;
}
