.creative-dates {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.creative-dates__control {
    align-items: center;
    display: flex;
    margin: 0;
    width: 337px;
}

.creative-dates__control .w-select {
    width: 100%;
}

.creative-dates__control .w-select select {
    z-index: 4;
}

.creative-dates__control--facets .w-select select:focus {
    box-shadow: 0 0 0 1px var(--w-color-gray-lighter);
}

.creative-dates__control--facets .w-select select:hover {
    box-shadow: 0 0 0 1px var(--w-color-primary);
}

.creative-dates__field {
    align-items: center;
    display: flex;
    width: 100%;
}

.creative-dates__label {
    font-weight: bold;
    margin: 0 .5rem 0 0;
}

.creative-dates__select {
    width: 100%
}

.creative-dates__select--custom {
    width: 100%
}

.creative-dates__calendar {
    display: flex;
    padding: 0 0 0 10px;
}

.creative-dates__calendar-btn {
    background-color: var(--w-color-off-white);
    border: none;
    border-radius: 2px;
    box-shadow: none;
    color: var(--w-color-gray-light);
    height: 25px;
    padding: 0;
    width: 25px;
}

.creative-dates__calendar-btn:focus {
    box-shadow: none;
}

.creative-dates__picker {
    position: relative;
    width: 250px;
}
