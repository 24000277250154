@import '../../wham/scss/utilities/variables';

@mixin icon {
  direction: ltr;
  display: inline-block;
  font-family: Material Icons;
  -webkit-font-feature-settings: "liga";
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}
