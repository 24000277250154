@import 'helpers';

.w-box {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  box-sizing: border-box;

  &--padding {
    &-none {
      padding: 0;
    }

    &-small {
      padding: $spacing-small;
    }

    &-medium {
      padding: $spacing-medium;
    }

    &-large {
      padding: $spacing-large;
    }

    &-xlarge {
      padding: $spacing-xlarge;
    }
  }

  &--align-vert {
    &-top {
      align-items: flex-start;
    }

    &-bottom {
      align-items: flex-end;
    }

    &-center {
      align-items: center;
    }

    &-fill {
      align-items: stretch;
    }
  }

  &--align-horiz {
    &-left {
      justify-content: flex-start;
    }

    &-right {
      justify-content: flex-end;
    }

    &-center {
      justify-content: center;
    }

    &-fill {
      .w-box__content {
        flex: 1;
      }
    }
  }

  .w-box__content {
    display: inline-block;
  }
}
