@import 'helpers';

.w-code {
  padding: $spacing-medium;
  white-space: pre-wrap;
}

.hljs {
  display: block;
  padding: .5em;
  color: $color-gray-dark;
  overflow-x: auto;
  word-wrap: break-word;
}

.hljs-comment,
.hljs-meta {
  color: $color-gray;
}

.hljs-string,
.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
  color: desaturate( $color-danger, 20% );
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
  color: desaturate( $color-warning, 20% );
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
  color: desaturate( $color-primary, 40% );
}

.hljs-section,
.hljs-name {
  color: desaturate( $color-success, 20% );
}

.hljs-tag {
  color: $color-gray-dark;
}

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: desaturate( $color-primary, 20% );
}

.hljs-addition {
  color: $color-success;
  background-color: $color-success-lighter;
}

.hljs-deletion {
  color: $color-danger;
  background-color: $color-danger-lighter;
}

.hljs-link {
  text-decoration: underline;
}
