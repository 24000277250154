.insights-beta-view {
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-height: 100vh;
	max-width: 100vw;
	overflow-y: scroll;
	width: 100%;
}

.insights-beta-view__header-row {
	display: flex;
	justify-content: center;
}

.insights-beta__controls-row {
	display: flex;
	justify-content: center;
  margin: .5rem 0;
}

.insights-beta-view__content-row {
  height: 100%;
  margin: 0 auto .5rem auto;
}

.insights-beta__loading-layer {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
}

.insights-beta__loading-message {
  background: var(--w-color-primary);
  border-radius: 1rem;
  color: var(--w-color-white);
  font-size: var(--w-font-size-medium);
  padding: .2em .5em;
}

.insights-beta__widgets-layer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.insights-beta__iframe-wrapper {
  height: 270px;
  margin: .5rem;
  width: 500px;
}

.insights-beta__iframe {
  height: 100%;
  width: 100%;
}
