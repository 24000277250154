.header-debug-controls {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 0 10px;
	width: 100%;
}

.header-debug-controls__whitelist {
	margin: 0 0 0 1rem;
	width: 135px;
}

.header-debug-controls__whitelist.w-btn:focus {
	box-shadow: none;
}
