@import 'helpers';

.w-table {
  color: $color-gray-dark;
  margin-bottom: 1.5rem;
  width: 100%;
  font-variant-numeric: lining-nums tabular-nums;

  thead,
  .w-table__group__header {
    th {
      font-size: $font-size-small;
      text-align: left;
      border-bottom: 1px solid $color-gray-light;
      color: $color-gray;
      font-weight: $font-weight-semibold;
      padding: 0 $spacing-large / 2;
      vertical-align: middle;
      height: 22px;

      &:first-child {
        padding-left: $spacing-small;
      }

      &:last-child {
        padding-right: $spacing-small;
      }
    }
  }

  td {
    min-height: 50px;
    vertical-align: middle;
    padding: $spacing-small;
    border-top: 1px solid transparent;

    &:first-child {
      padding-left: $spacing-small;
    }

    &:last-child {
      padding-right: $spacing-small;
    }
  }

  .w-table__cell--number {
    text-align: right;
    width:1%;
    white-space:nowrap;
  }

  tr {
    &:hover {
      td {
        background-color: rgba($color-primary-light, .33);
      }

      .w-table__cell--select *,
      .w-table__cell--actions * {
        visibility: visible;
        pointer-events: all;
      }
    }

    &.w-table__row--selected {
      &:hover {
        td {
          background-color: $color-primary;
        }

        .w-table__cell--actions * {
          visibility: hidden;
          pointer-events: none;
        }
      }

      td {
        background-color: $color-primary;
        color: $color-primary-invert;
      }

      .w-table__cell--select {
        * {
          visibility: visible;
          pointer-events: all;
        }
      }

      .w-checkbox {
        box-shadow: 0 0 0 1px rgba($color-white, .5);
        border-radius: $corner-radii-small;
      }

    }

    &.w-table__row--active td {
      background-color: $color-primary-darker;
      color: $color-white;
    }
  }

  &__cell--select,
  &__cell--actions {
    * {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__cell--select {
    width: 1rem;

    .w-checkbox {
      margin-right: 0;
    }
  }

  &__cell--actions {
    text-align: right;
  }
}

// Table groups
.w-table__group {
  .w-table__group__header {
    th {
      padding-top: $spacing-large;
      border-bottom-color: $color-gray-lighter;
    }
  }
}


// Sorting
.w-table__head {
  &__label {
    &--sort-down,
    &--sort-up {
      .w-table__head__label__sort-arrow {
        position: relative;
        top: -15%;
        margin-left: $spacing-small;

        &::after {
          @include arrow($color-gray-light);
          display: inline-block;
          position: relative;

        }
      }
    }

    &--sort-up {
      top: 15%;

      &::after {
        transform:  rotate(-225deg);
      }
    }
  }
}

/* Experimental -- commenting out for now
------------------------------------------
@media (max-width: 25em) {
  table, caption, tbody, tr, th, td {
    display: block;
    text-align: left;
  }
  thead, th:empty, td:empty {
    display: none;
    visibility: hidden;
  }

  td {
    padding: 0 $spacing-small !important;
    height: auto !important;
    margin: 0 .33rem;

  }

  td:first-child {
    font-weight: $font-weight-bold;
    margin-bottom: .5rem;
  }

  tr {
    padding-bottom: 1rem;
  }

  tr + tr {
    padding-top: 1rem;
    border-top: 1px solid $color-gray-lighter;
  }

  th[data-title]:before, td[data-title]:before {
    content: attr(data-title) ": ";
    display: inline-block;
    width: 5.5em;
    text-align: left;
  }
}
*/
