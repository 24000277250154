.header-user {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: flex-end;
}

.header-user__wrap {
	align-items: center;
	border-left: 1px solid var(--w-color-gray-lighter);
	display: flex;
	height: 35px;
	padding: 0 10px;
}

.header-user__notes {
	display: flex;
	justify-content: center;
	width: 50px;
}

.header-user__auto-enrollment-alert {
	color: var(--w-color-danger);
}
