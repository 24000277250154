@import '../../scss/mixins/icons';
@import '../views';

.section {
  @extend %main;
}

.header {
  @extend %header;
}

.nav {
  &_link {
    color: $color-primary;
    display: inline-flex;
    padding: 0 28px;
    position: absolute;
    top: 73px;

    &:hover {
      text-decoration: none;
    }
  }

  &_icon {
    @include icon;
    pointer-events: none;
  }

  &_text {
    font-size: 16px;
    padding: 0;
  }

  &_prev {
    left: 0;
  }

  &_next {
    right: 0;
  }
}

.pending {
  @extend %pending;
}

.article {
  @extend %content;
  justify-content: center;
}

.loader {
  align-items: center;
  display: flex;
  height: calc(100vh - 80px);
  justify-content: center;
  width: 100%;
}

.failed {
  padding: 36px 0 0 0;
}
