.debug-filters-reset {
	align-items: center;
	display: flex;
}

.debug-filters-reset__button {
	background-color: var(--w-color-off-white);
	border: none;
	border-radius: 2px;
	box-shadow: none;
	color: var(--w-color-gray-light);
	height: 25px;
	padding: 0;
	width: 25px;
}

.debug-filters-reset__button:focus {
	box-shadow: none;
	color: var(--w-color-gray);
}

.debug-filters-reset__button:hover {
	color: var(--w-color-gray);
}
