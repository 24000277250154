@import '../wham/scss/utilities/variables';

%main {
  background-color: $color-off-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;

  :global(.doc-link) {
    position: fixed;
  }
}

%header {
  display: flex;
  flex-direction: column;
  height: 79px; /* safari */
  width: 100%;
}

%pending {
  display: flex;
  flex-direction: row;
  position: relative;
}

%content {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}
