.whitelist-table {
	margin: 1.5rem 0;
}

.whitelist-table__wrapper {
	min-height: 25rem; /* Ensure lookup drop-down has enough space */
	position: relative;
}

.whitelist-table__wrapper--pending {
	min-height: initial;
}

.whitelist-table__wrapper .w-table .w-table__group__header th, .w-table thead th {
	border-bottom: none;
	padding: 0 .7143rem;
}

.whitelist-table .whitelist-table__head {
	font-size: 1rem;
	font-weight: bold;
}

.whitelist-table__head-type {
	width: 4rem;
}

.whitelist-table__head-label {
	width: 25rem;
}

.whitelist-table__head-value {
	width: auto;
}

.whitelist-table__head-actions {
	width: 5.7rem;
}

.whitelist-table__pending {
	display: flex;
	justify-content: center;
	width: 100%;
}

.whitelist-table__actions {
	display: flex;
	justify-content: center;
}

.whitelist-table .whitelist-table__cell.whitelist-table__icon {
	padding: 0;
}

.whitelist-table__icon .w-icon {
	padding: 13px;
	pointer-events: none;
}

.whitelist-table__cell-copyable {
	cursor: copy;
	position: relative;
}

.whitelist-table__cell-copyable--copied:after {
	animation: copied-display 2s .2s;
	background: rgba(255,255,255,.95);
	border-radius: 334243423px;
	bottom: -20px;
	box-shadow: 0 2px 10px rgba(0,0,0,.15);
	color: #008bcc;
	content: 'Copied to clipboard';
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	left: 10px;
	min-width: 122px;
	opacity: 0;
	padding: 2px 10px;
	pointer-events: none;
	position: absolute;
	z-index: 13;
}

.whitelist-table__count {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 0 .7rem;
	position: absolute;
	right: 0;
	top: 0;
}

.whitelist-table__count-label {
	font-size: 1rem;
	font-weight: bold;
}

.whitelist-table__count-value {
	font-size: 1rem;
	font-weight: normal;
	margin: 0 0 0 .2rem;
}
