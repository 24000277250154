@charset 'UTF-8';
@import 'helpers';

.w-close-btn {
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: $font-size-medium;
  position: relative;
  border-radius: $corner-radii-circle;

  &::before {
    content: '\00D7';
    display: block;
    font-size: 1em;
    line-height: 1em;
    background-color: transparent;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translateX(.0125em);
    position: relative;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px $color-gray;
  }

  &:active {
    background-color: $color-gray;
    &::before {
      color: $color-white;
    }
  }

  &--small {
    font-size: $font-size-small;

    &::before {
      font-weight: $font-weight-semibold;
    }
  }

  &--large {
    font-size: $font-size-large;
  }

  &--inverse {
    &::before {
      background-color: transparent;
      color: $color-white;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px $color-white;
    }

    &:active {
      background: $color-white;
      &::before {
        color: $color-gray-dark;
      }
    }
  }
}
