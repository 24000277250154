.playback-button__error {
  font-size: var(--w-font-size-large);
  opacity: 0.5;
}

.playback-button__layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.playback-button__play {
  font-size: var(--w-font-size-large);
  cursor: pointer;
}

.playback-button__play--playing {
  color: var(--w-color-primary);
}

.playback-button__play:hover {
  color: var(--w-color-primary);
}

.playback-button__playing-text--normal {
  font-size: var(--w-font-size-small);
  position: absolute;
  margin-top: 18px;
}

.playback-button__playing-text--compact {
  font-size: var(--w-font-size-small);
  color: var(--w-color-primary);
}
