@import 'helpers';

.w-tabs {

  &__content {
    margin-top: $spacing-medium;
  }

  &__nav {
    display: block;
    border-bottom: 1px solid $color-gray-lighter;
    user-select: none;

    &__tab,
    &__title {
      display: inline-block;
      padding: $spacing-xsmall $spacing-small;
      color: $color-gray-light;

      & + .w-tabs__nav__tab {
        //margin-left: $spacing-medium;
      }

      &--active {
        border-bottom: 1px solid $color-gray-dark;
        margin-bottom: -1px;
        .w-tabs__nav__tab__link {
          color: $color-gray-dark;
        }

      }

      &__link {
        color: $color-gray-light;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__title {
      font-weight: $font-weight-bold;
      color: $color-gray-dark;
      padding-left: 0;
    }
  }

  &--small {
    & > .w-tabs__nav .w-tabs__nav__tab__link {
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
    }
  }

  &--large {
    & > .w-tabs__nav .w-tabs__nav__title {
      font-size: $font-size-xlarge;
      font-weight: $font-weight-light;
      padding-bottom: 0;
    }

    & > .w-tabs__nav .w-tabs__nav__tab__link {
      font-size: $font-size-large;
      font-weight: $font-weight-normal;
    }
  }


}
