.ts-tab__new-transactions {
	margin: 1.4rem auto;
	text-align: center;
}

.ts-tab__controls {
	display: flex;
	margin: 1.4rem 0;
}

.ts-tab__controls .expand-collapse__button {
	background-color: transparent;
}

.ts-tab__controls .expand-collapse__button:focus {
	border-color: initial;
	box-shadow: initial;
	color: initial;
}

.ts-tab__container .w-container__content__box {
	padding-bottom: 2.5rem;
}

.ts-tab__form {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.ts-tab__select {
	margin: 0 0 0 4rem;
	min-width: 10rem;
}

.ts-tab__requests {
	display: flex;
	flex-direction: column;
}

.ts-tab__header {
	border-top: 1px solid var(--w-color-gray-lighter);
	border-right: 1px solid var(--w-color-gray-lighter);
	border-left: 1px solid var(--w-color-gray-lighter);
	color: var(--w-color-gray-dark);
	display: flex;
	font-weight: 500;
	height: 43px;
	justify-content: space-between;
}

.ts-tab__header-left,
.ts-tab__header-right,
.ts-tab__header-cell {
	height: 100%;
}

.ts-tab__header-left,
.ts-tab__header-right {
	display: flex;
}

.ts-tab__header-left {
	margin: 0 0 0 30px;
	min-width: 14rem;
	width: auto;
}

.ts-tab__header-right {
	justify-content: flex-end;
	margin: 0 23rem 0 0;
	width: 26rem;
}

.ts-tab__header-cell {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.ts-tab__header-time {
	cursor: pointer;
	width: 11rem;
}

.ts-tab__header-segment {
	cursor: pointer;
	padding: 0 .5rem;
	width: 8rem;
}

.ts-tab__header-sort {
	height: 100%;
	position: relative;
	width: 1.5rem;
}

.ts-tab__header-sort .ts-tab__header-sort--selected {
	color: var(--w-color-gray-dark);
}

.ts-tab__header-sort-up,
.ts-tab__header-sort-down {
	color: var(--w-color-gray-light);
	font-size: 1.5rem;
	position: absolute;
}

.ts-tab__header-sort-up {
	top: calc(50% - 1rem);
}

.ts-tab__header-sort-down {
	top: calc(50% - .5rem);
}

