@import 'helpers';

.w-modal {
  @include overlay;

  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: $z-index-modal;
  pointer-events: none;
  opacity: 0;
  display: flex;
  transition: .3s;
  top: -2rem;
  transform: scale(1.05);

  // Modifiers
  &--active {
    display: flex;
    opacity: 1;
    top: 0;
    pointer-events: all;
    transform: scale(1);
  }

  &__background {
    @include overlay;

    background: $sheen-white;
  }

  &__content {
    margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: 100%;

    // Responsiveness
    @include tablet {
      margin: 0 auto;
      width: 860px;
      max-height: 550px;

      &--large {
        width: 100%;
        margin: 30px;
        max-height: calc(100vh - 60px);
      }

      &--small {
        width: 600px;
        max-height: 400px;
      }
    }
  }

  &__close-btn {
    position: fixed;
    right: $spacing-medium;
    top: $spacing-medium;
  }

  &--inverse {
    .w-modal__background {

      background: $sheen-primary;
    }

    .w-modal__content {
      color: $color-white;
    }
  }

}
