@import 'helpers';

.w-popover {
  display: none;
  padding: $spacing-small;
  height: 0;

  h3 {
    font-size: $font-size-medium;
    color: $color-gray;
    font-weight: $font-weight-semibold;
  }

  p {
    font-size: $font-size-medium;
    color: $color-gray;
    font-weight: $font-weight-normal;
  }

  &.w-popover--spaced {
    padding: $spacing-large;
  }
}

.w-popover.w-popover--active,
.w-popover--active + .w-popover {
  display: block;
  position: absolute;
  z-index: 100;
  height: auto;
}
