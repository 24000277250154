$control-padding-vertical: calc(.375em - 1px) !default;
$control-padding-horizontal: calc(.625em - 1px) !default;

@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: $corner-radii-large;
  box-shadow: none;
  display: inline-flex;
  justify-content: flex-start;
  padding-bottom: $control-padding-vertical;
  padding-left: $control-padding-horizontal;
  padding-right: $control-padding-horizontal;
  padding-top: $control-padding-vertical;
  position: relative;
  vertical-align: top;

  // States
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

// The controls sizes use mixins so they can be used at different breakpoints
@mixin control-small {
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
}

@mixin control-medium {
  font-size: $font-size-medium;
}

@mixin control-large {
  font-size: $font-size-large;
}

@mixin input {
  @include control;

  max-width: 100%;
  width: 100%;
  font-size: $font-size-medium;
  height: 34px;
  background-color: $color-white;
  box-shadow: 0 0 0 1px $color-gray-lighter;
  transition: box-shadow, background $transition-fast;

  &:hover {
    box-shadow: 0 0 0 1px $color-gray-light;
    z-index: 4;
  }

  &:focus,
  &:focus-within,
  &:active {
    box-shadow: 0 0 0 2px $color-primary;
    z-index: 5;
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    color: $color-gray-light;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    color: $color-gray-light;
    font-style: italic;
  }

  &[disabled] {
    opacity: .4;
    pointer-events: none;
  }

  &[readonly] {
    box-shadow: 0 0 0 1px $color-gray-lighter;
    background: $color-off-white;
    pointer-events: none;
  }

  &[disabled] {

    @include placeholder {
      color: $color-gray-light;
      font-style: italic;
    }
  }
}
