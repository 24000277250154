@import 'helpers';

.w-search {
  @include input;
  display: flex;
  padding: 0;

  &--large {
    font-size: $font-size-large;
    height: 44px;
  }

  &--small {
    font-size: $font-size-small;
    height: 22px;
  }

  &--subtle,
  &--subtle:hover,
  &--subtle:focus,
  &--subtle:focus-within {
    box-shadow: inset 0px -1px 0 0px $color-gray-lighter;

    input {
      box-shadow: none;
    }
  }

  &--subtle:focus-within {
    box-shadow: none;
  }

  .w-input {
    width: 100%;

  }

  .w-select select,
  .w-input input {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  .w-select {
    margin-left: 1.5em;
    padding-right: 0;
  }

  &:focus-within {

  }
}

.w-search .w-input--small {
  font-size: $font-size-small;
}

.w-search  .w-input--large{
  font-size: $font-size-large;
}

.w-search {
  .w-input input {
    padding-left: 2em;
  }

  .w-select + .w-input input {
    padding-left: 0;
  }

  &::before {
    font-family: 'Material Icons';
    color: $color-gray-light;
    font-size: 1.5em;
    position: absolute;
    left: 0.125em;
    top: 50%;
    transform: translateY(-50%);
    content: 'search';
    z-index: 20;
  }

  &:focus-within {
    &::before {
      color: $color-gray;
    }
  }
}
