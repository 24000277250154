.mezz-form--anchored {
	position: fixed;
	bottom: 0;
	width: 24.8%;
	background: #fafafb;
	left: 0;
	min-width: 350px;
	z-index: 1;
}

.mezz-form__header {
	margin-bottom: .2rem;
	text-align: center;
}

.mezz-form__accepts {
	color: #9b9b9b;
	display: block;
	font-size: .75rem;
	font-weight: bold;
	margin-bottom: 1.4rem;
	text-align: center;
}

.mezz-form__or {
	color: #949497;
	display: block;
	font-style: italic;
	margin-bottom: 1.5rem;
	text-align: center;
}

.mezz-form__field {
	display: flex;
	flex-direction: column;
}

.mezz-form--anchored .mezz-form__field {
	border-top: 1px solid var(--w-color-gray-lighter);
	padding: 2rem 1rem .4rem 1rem;
}

.mezz-form__input {
	border: 1px solid var(--w-color-gray-lighter);
	border-radius: 3px;
	font-size: 1.4rem;
	max-height: 186px;
	padding: 10px 14px;
	resize: none;
	width: 100%;
}

.mezz-form--anchored .mezz-form__input {
	font-size: .85rem;
	max-height: 118px;
}

.mezz-form__input--error {
	border: 2px solid var(--w-color-danger);
	padding: 9px 13px;

}

.mezz-form__input.mezz-form__input--error:focus {
	border: 2px solid var(--w-color-danger);
}

.mezz-form__input:focus {
	border: 2px solid var(--w-color-primary);
	outline: 0;
	padding: 9px 13px;
}

.mezz-form__input::placeholder,
.mezz-form__input::-webkit-input-placeholder {
	color: var(--w-color-gray-light);
	font-style: italic;
}

.mezz-form__field-message {
	height: 16px;
}

.mezz-form__field-text {
	color: var(--w-color-gray);
	font-size: .8rem;
	font-weight: 500;
	padding: 0 0 0 2px;
}

.mezz-form__field-text--error {
	color: var(--w-color-danger);
}