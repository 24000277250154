@import 'helpers';

.w-heading {
  line-height: 1.25;
  margin: 1em 0;
  display: block;
  color: $color-gray-dark;

  &:first-child {
    margin-top: 0;
  }

  &--inverse {
    color: $color-white;
  }

  &--level-1 {
    font-size: $font-size-xxxlarge;
    font-weight: $font-weight-light;
  }

  &--level-2 {
    font-size: $font-size-xxlarge;
    font-weight: $font-weight-light;
  }

  &--level-3 {
    font-size: $font-size-xlarge;
    font-weight: $font-weight-light;
  }

  &--level-4 {
    font-size: $font-size-large;
  }

  &--level-5 {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
  }

}
