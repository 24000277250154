.error-screen {
  background-color: var(--w-color-off-white);
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
  z-index: 9999;
  position: absolute;
}

.error-screen__body {
  flex: 1;
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-screen__title {
  margin-bottom: var(--w-spacing-large);
}

.error-screen__description {
  margin-bottom: var(--w-spacing-large);
  max-width: 400px;
}
