@import 'helpers';

.w-layout {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  max-height: 100%;
  flex-direction: column;

  &--fullwidth {
    max-width: 100vw;
    width: 100%;
  }
  &--fullheight {
    height: 100%;
    max-height: 100vh;
  }

  &__row {
    display: flex;
    flex-direction: row;

    &--fill {
      align-content: stretch;
      flex: 1;
      overflow-x: hidden;

      .w-layout__column {
        height: auto;

        .w-layout__column__content {
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }
  }

  &__column {
    flex: 1;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    height: 100%;

    &--vert-align-center {
      align-items: center;
    }

    &--vert-align-bottom {
      align-items: flex-end;
    }

    &--hor-align-center {
      justify-content: center;

      .w-layout__column__content {
        // width: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }

    &--hor-align-right {
      justify-content: flex-end;

      .w-layout__column__content {
        width: auto;
      }
    }

    &--minor {
      flex: .33;
    }

    &--narrow {
      flex: .25;
    }

    &--sidebar {
      flex: .3;
      max-width: 360px;
      min-width: 200px;
      background: $color-white;

      .w-layout__column__content {
        margin: 0;
        padding: $spacing-large;
      }
    }

    &--hidden {
      max-width: 0;
      border-color: transparent;
    }

    & + .w-layout__column {
      border-left: 1px solid $color-gray-lighter;
    }

    &__content {
      padding: $spacing-large $spacing-large;
      width: 100%;
      position: relative;
    }
  }

  &.w-layout--spacing-none {
    & > .w-layout__row > .w-layout__column > .w-layout__column__content {
      padding: 0;
    }
  }

  &.w-layout--spacing-spaced {
    & > .w-layout__row > .w-layout__column > .w-layout__column__content {
      margin: $spacing-large $spacing-xxlarge;
    }
  }

  &.w-layout--spacing-default {
    & > .w-layout__row > .w-layout__column > .w-layout__column__content {
      padding: $spacing-large $spacing-large;
    }
  }
}
