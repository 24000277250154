@import 'helpers';

.w-field {
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $spacing-medium;
  }

  &--disabled {
    cursor: not-allowed !important;

    & > label {
      cursor: not-allowed !important;
    }

    .w-field__control {
      pointer-events: none;
    }
  }

  // Modifiers
  &--has-addons {
    display: flex;
    justify-content: flex-start;

    .w-field__control + .w-field__control {
      margin-left: -1px;
    }

    .w-field__control {
      max-width: 100%;
      
      .w-btn,
      .w-field__descriptor,
      .w-input input,
      .w-select select {
        border-radius: $corner-radii-small;

        &:hover,
        &--hovered {
          z-index: 2;
        }

        &:focus,
        &--focused,
        &:active,
        &--active {
          z-index: 3;

          &:hover {
            z-index: 4;
          }
        }
      }

      &--expanded {
        flex-grow: 1;
        flex-shrink: 0;

        .w-input,
        .w-select:not(.w-select--autosize),
        input,
        select {
          width: 100%;
        }
      }
    }

    &--has-addons-centered {
      justify-content: center;
    }

    &.has-addons-right {
      justify-content: flex-end;
    }

    &.has-addons-fullwidth {
      .w-field__control {
        flex-grow: 1;
        flex-shrink: 0;
      }
    }
  }

  &__label {
    color: $color-gray-dark;
    display: block;
    font-weight: $font-weight-bold;

    &--optional {

      &::after {
        margin-left: $spacing-small;
        content: 'optional';
        color: $color-gray-light;
      }
    }

    &:not(:last-child) {
      margin-bottom: .5em;
    }

    &:empty {
      display: none;
    }

  }

  &__control {

    position: relative;
    text-align: left;

    > input + label {
      display: inline-block;
    }

    > input:checked + label {
      font-weight: $font-weight-semibold;
      transition: $transition-fast;
    }
  }

  &__help {
    font-size: $font-size-small;
    color: $color-gray-light;
    font-weight: $font-weight-semibold;
    display: block;
    line-height: 1;
    margin-top: 6px;

    @each $name, $pair in $colors {
      $color: nth($pair, 1);

      &--#{$name} {
        color: $color;
      }
    }
  }
}

.w-field__help--danger,
.w-field--error .w-field__help {
  color: $color-danger;
}
