@import 'helpers';

.w-app-bar {


  .w-app-bar__level--search {
    z-index: 1;
    transition: box-shadow .2s;

    &:focus-within {
      //box-shadow: $focused-outline-inset;
      box-shadow: $shadow-depth-3;

      &::before {
        color: $color-gray;
      }
    }
  }

  .w-search.w-app-bar__search__field {
    -webkit-appearance: none;
    height: 100%;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    padding: .5rem .75rem;
    background: none;
    border: 0;

    .w-input {
      width: 100%;

      input:focus,
      input:hover {
        box-shadow: none;
      }
    }

    &:focus,
    &:active,
    &:focus-within {
      color: $color-gray-dark;
      outline: none;
      box-shadow: none;
    }
  }

  .w-app-bar__search__filters {
    align-self: center;
    flex-shrink: 0;

    .w-select {
      select {
        color: $color-gray;
      }

      &::after {
        border-color: $color-gray;
      }
    }
  }

}
