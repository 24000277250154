@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}


// Responsiveness

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$breakpoint-tablet: 769px !default;

// 960px container + 40px
$breakpoint-desktop: 1000px !default;

// 1152px container + 40
$breakpoint-widescreen: 1192px !default;

// 1344px container + 40
$breakpoint-fullhd: 1384px !default;


// Corner radii
$corner-radii-small: 0.075rem !default;
$corner-radii-medium: 0.15rem !default;
$corner-radii-large: 0.225rem !default;
$corner-radii-xlarge: 0.375rem !default;
$corner-radii-pill: 1234567890px !default;
$corner-radii-circle: 100% !default;

// Shadow depths
$shadow-depth-1: 0 1px .15rem 1px rgba(0, 0, 0, .015), 0 0.2rem 1rem 0.1rem rgba(0, 0, 0, .1);
$shadow-depth-2: 0 1px .175rem 1px rgba(0, 0, 0, .0175), 0 0.3rem 1.5rem 0.15rem rgba(0, 0, 0, .1);
$shadow-depth-3: 0 1px .2rem 1px rgba(0, 0, 0, .02), 0 0.5rem 2.5rem 0.25rem rgba(0, 0, 0, .1);
$shadow-depth-4: 0 1px .25rem 1px rgba(0, 0, 0, .025), 0 0.8rem 4rem 0.4rem rgba(0, 0, 0, .125);
$shadow-depth-5: 0 1px .4rem 1px rgba(0, 0, 0, .04), 0 1.3rem 6.5rem 0.65rem rgba(0, 0, 0, .15);

$color-primary: #20A1D3 !default;

// Colors
$color-gray-dark: desaturate(#0A0A2B, 0%) !default;
$color-gray: desaturate(tint(#0A0A2B, 17%), 12%) !default;
$color-gray-light: desaturate(tint(#0A0A2B, 54%), 6%) !default;
$color-gray-lighter: desaturate(tint(#0A0A2B, 89%), 3%) !default;
$color-off-white: desaturate(tint(#0A0A2B, 98%), 1%) !default;
$color-white: #fff;

$color-primary-light: tint($color-primary, 65%) !default;
$color-primary-lighter: tint($color-primary, 75%) !default;
$color-primary-dark: shade(saturate($color-primary, 20%), 25%) !default;
$color-primary-darker: shade(saturate($color-primary, 20%), 35%) !default;


$color-danger: #f5333f !default;

$color-danger-light: tint($color-danger, 30%) !default;
$color-danger-lighter: tint($color-danger, 70%) !default;
$color-danger-dark: shade(saturate($color-danger, 20%), 20%) !default;
$color-danger-darker: shade(saturate($color-danger, 20%), 30%) !default;

// $color-warning: #f5ab00 !default;
$color-warning: #F6B527;

$color-warning-light: tint($color-warning, 30%) !default;
$color-warning-lighter: tint($color-warning, 70%) !default;
$color-warning-dark: shade(adjust-hue(saturate($color-warning, 20%), -3%), 10%) !default;
$color-warning-darker: shade(adjust-hue(saturate($color-warning, 20%), -7%), 12%) !default;


$color-success: #78b330 !default;

$color-success-light: tint($color-success, 30%) !default;
$color-success-lighter: tint($color-success, 70%) !default;
$color-success-dark: shade(saturate($color-success, 20%), 20%) !default;
$color-success-darker: shade(saturate($color-success, 20%), 30%) !default;

$color-warning-invert: find-color-invert($color-warning) !default;
$color-success-invert: find-color-invert($color-success) !default;
$color-primary-invert: find-color-invert($color-primary) !default;
$color-danger-invert: find-color-invert($color-danger) !default;

$color-alternative: #7b5fe3 !default;

$color-alternative-light: tint($color-alternative, 30%) !default;
$color-alternative-lighter: tint($color-alternative, 70%) !default;
$color-alternative-dark: shade(adjust-hue(saturate($color-alternative, 20%), -3%), 10%) !default;
$color-alternative-darker: shade(adjust-hue(saturate($color-alternative, 20%), -7%), 12%) !default;


$colors: ('primary': ($color-primary, $color-primary-invert), 'danger': ($color-danger, $color-danger-invert)) !default;


$color-extended-pink: desaturate(#f06595, 20%) !default;
$color-extended-pink-light: desaturate(#faa2c1, 20%) !default;
$color-extended-pink-dark: desaturate(#d6336c, 20%) !default;

$color-extended-magenta: desaturate(#cc5de8, 20%) !default;
$color-extended-magenta-light: desaturate(#e599f7, 20%) !default;
$color-extended-magenta-dark: desaturate(#ae3ec9, 20%) !default;

$color-extended-cyan: desaturate(#22b8cf, 20%) !default;
$color-extended-cyan-light: desaturate(#66d9e8, 20%) !default;
$color-extended-cyan-dark: desaturate(#1098ad, 20%) !default;

$color-extended-teal: desaturate(#20c997, 20%) !default;
$color-extended-teal-light: desaturate(#63e6be, 20%) !default;
$color-extended-teal-dark: desaturate(#0ca678, 20%) !default;

$color-extended-lime: desaturate(#b3d82c, 20%) !default;
$color-extended-lime-light: desaturate(#d2eb75, 20%) !default;
$color-extended-lime-dark: desaturate(#94b715, 20%) !default;

$color-extended-orange: desaturate(#ff922b, 20%) !default;
$color-extended-orange-light: desaturate(#ffc078, 20%) !default;
$color-extended-orange-dark: desaturate(#f76707, 20%) !default;



// Typography

$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$font-family-monospace: 'Source Code Pro', monospace !default;

$font-render-mode: optimizeLegibility !default;


// Type weights
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 600 !default;


// Type sizes
$font-size-base: 14px;

$font-size-xxxlarge: 4.285715rem; // 60px
$font-size-xxlarge: 2.85714rem;   // 40px
$font-size-xlarge: 2rem;        // 28px
$font-size-large: 1.4286rem;   // 20px
$font-size-medium: 1rem;        // 14px
$font-size-small: 0.785715rem;    // 11px


// Spacing
$spacing-xsmall: 0.35715rem; // 5px
$spacing-small: 0.7143rem; // 10px
$spacing-medium: 1.4286rem; // 20px
$spacing-large: 2.1428571rem; // 30px
$spacing-xlarge: 3.571428rem; // 50px
$spacing-xxlarge: 5.7142857rem; // 80px


//Opacity
$opacity-disabled: .4;


// Transitions
$transition-easing: cubic-bezier(.645, .045, .355, 1) !default;
$transition-duration: .2s !default;
$transition-medium: .4s $transition-easing;
$transition-fast: $transition-duration $transition-easing;
$transition-faster: $transition-duration .05s;


// Z-indices
$z-index-rock-bottom: 1;
$z-index-formblock-focus: 10;
$z-index-appbar: 1000;
$z-index-progress: $z-index-appbar + 1000;
$z-index-overlay: $z-index-progress + 1000;
$z-index-tooltip: $z-index-overlay + 1000;
$z-index-action-button: $z-index-tooltip + 1000;
$z-index-pane: $z-index-action-button + 1000;
$z-index-message: $z-index-pane + 1000;
$z-index-callout: $z-index-message + 1000;
$z-index-walkthrough: $z-index-callout + 1000;
$z-index-modal: $z-index-walkthrough + 1000;
$z-index-arbitrarily-high: 2147483647;

// Miscelaneous

//Focused outline
$focused-outline: 0 0 0 0.21428rem $color-primary-light;
$focused-outline-inset: inset 0 0 0 3px $color-primary-lighter;

$sheen-primary: linear-gradient(217deg, rgba($color-primary,.95), rgba($color-primary,.1) 70.71%),
            linear-gradient(127deg, rgba($color-primary,.95), rgba($color-primary,.1) 70.71%),
            linear-gradient(336deg, rgba($color-primary,.95), rgba($color-primary,.1) 70.71%);

$sheen-white: linear-gradient(217deg, rgba($color-white,.95), rgba($color-white,.1) 70.71%),
            linear-gradient(127deg, rgba($color-white,.95), rgba($color-white,.1) 70.71%),
            linear-gradient(336deg, rgba($color-white,.95), rgba($color-white,.1) 70.71%);


$gradient-gray-dark: ig-easing-gradient(
        linear,
        -5deg,
        $color-gray-dark,
        ease-in-out,
        desaturate(lighten($color-gray-dark, 5%), 30%)
    );

$gradient-gray: ig-easing-gradient(
        linear,
        -5deg,
        $color-gray,
        ease-in-out,
        lighten($color-gray, 7.5%)
    );

$gradient-gray-light: ig-easing-gradient(
        linear,
        -5deg,
        $color-gray-light,
        ease-in-out,
        lighten($color-gray-light, 7.5%)
    );

$gradient-gray-lighter: ig-easing-gradient(
        linear,
        -5deg,
        $color-gray-lighter,
        ease-in-out,
        lighten($color-gray-lighter, 7.5%)
    );

$gradient-primary: ig-easing-gradient(
        linear,
        -5deg,
        $color-primary-dark,
        ease-in-out,
        $color-primary
    );

$gradient-danger: ig-easing-gradient(
        linear,
        -5deg,
        $color-danger-dark,
        ease-in-out,
        $color-danger
    );

$gradient-warning: ig-easing-gradient(
        linear,
        -5deg,
        $color-warning-dark,
        ease-in-out,
        $color-warning
    );

$gradient-success: ig-easing-gradient(
        linear,
        -5deg,
        $color-success-dark,
        ease-in-out,
        $color-success
    );
