.debug-log-group {
	position: relative;
}

.debug-log-group__control {
	left: -70px;
	position: absolute;
	top: 0;
}

.debug-log-group__control-container {
	position: relative;
}

.debug-log-group__control-container-upper {
	align-items: center;
	display: flex;
	position: absolute;
	top: 52px;
}

.debug-log-group__control-container-lower {
	align-items: center;
	display: flex;
	left: 30px;
	position: absolute;
}

.debug-log-group__control-count {
	cursor: default;
	display: inline-block;
	font-weight: 400;
	margin-right: 5px;
	text-align: right;
	width: 25px;
}

.debug-log-group__records {
	position: relative;
}

.debug-log-group__thread-line {
	border-top: 1px solid var(--w-color-primary);
	border-left: 1px solid var(--w-color-primary);
	border-bottom: 1px solid var(--w-color-primary);
	height: 100%;
	position: absolute;
	right: 12px;
	top: 0;
	width: 100%;
	z-index: -12;
}

.debug-log-group__thread-mask {
	background-color: var(--w-color-off-white);
	height: calc(100% + 2px);
	position: absolute;
	right: 6px;
	top: -1px;
	width: 100%;
	z-index: -11;
}
