@import 'helpers';

.w-app-bar-2 {
  &:hover,
  &:focus-within {

    .w-partner-selector__selected {
      &::after {
        @include arrow($color-gray-light);
        content: '';
        z-index: 4;
        bottom: 10px;
        left: 50%;
        margin-left: -.25em;
      }
    }
    .w-partner-selector__selected__content {
      transition: 0;
      position: relative;
    }

    .w-partner-selector--active {
      position: relative;
      .w-partner-selector__selected {

        &::after {
          display: none;
        }

        &__content {
          transition: 0;
          padding: 0;
        }
      }
    }
  }
}

.w-partner-selector {
  width: 70px;
  height: 70px;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;

  &__selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    position: relative;
    border-right: 1px solid $color-gray-lighter;

    &__content {
      transition: .1s;
    }
  }

  &__menu {
    position: absolute;
  }

  &:hover {
    .w-partner-selector__selected {
      background: $color-primary;
      color: $color-white;
      border-right-color: transparent;

      &::after {
        border-color: $color-white;
      }

      &__content {
        .w-mark__element {
          fill:  $color-white;
        }
      }
    }
  }

  &--active {
    box-shadow: $shadow-depth-3;
    z-index: 10;

    .w-partner-selector__selected {
      background: $color-primary;
      color: $color-white;
      width: 70px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right-color: transparent;

      &__content {
        padding: 0;

        &::after {
          display: none !important;
        }
      }

      .w-icon {
        cursor: pointer;
        display: block;
      }
    }
  }

  .w-mark {
    display: block;
  }

  &:focus {
    outline: 0;
  }

  .w-menu {
    min-width: 200px;
    .w-partner-selector__menu__item {
      padding: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &__text {
        display: inline-block;
        height: 30px;
        line-height: 30px;
      }

      &:hover {
        background-color: $color-primary-light;
      }

      .w-mark {
        margin-right: 20px;
      }

      &--selected {
        background-color: $color-primary;
        color: $color-white;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO30KCS5zdDF7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTAsMGgyNHYyNEgwVjB6Ii8+Cjxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iMTkuNiw0LjIgOSwxNC44IDQuOCwxMC42IDIsMTMuNCA5LDIwLjQgMjIuNCw3ICIvPgo8L3N2Zz4K');
        background-size: 10px auto;
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;

        .w-mark__element {
          fill: $color-white;
        }

        &:hover {
          background-color: $color-primary;
        }
      }
    }
  }
}
