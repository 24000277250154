.simulator__form {
	width: 60%;
	margin-right: 10px;
}

.simulator__form-columns {
	display: flex;
}

.simulator__form-field {
	width: 100%;
}

.simulator__form-field input, .simulator__form-field select {
	margin-top: .5rem;
}

.simulator__settokenurl-field__wrapper {
	display: flex;
	align-items: center;
}

.simulator__settokenurl-edit {
	margin-left: .5rem;
	cursor: pointer;
}

.simulator__settokenurl-edit--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.simulator__settokenurl-field {
	flex-grow: 1;
}

.simulator__label {
	font-weight: bold;
}

.simulator__sublabel {
	font-size: x-small;
	font-weight: lighter;
}

.simulator__button-wrapper {
	margin-top: 2rem;
}

.simulator__form-error {
	font-size: small;
	font-style: italic;
	color: var(--w-color-danger);
	margin-top: .5rem;
	margin-bottom: .5rem;
}

.simulator__form-error--hidden {
	visibility: hidden;
}

.simulator__radio-buttons label {
	margin-left: .5em;
	margin-right: 3em;
}
