@import 'helpers';

.w-tag {
  background: $color-gray-lighter;
  color: $color-gray;
  height: 19px;
  line-height: 19px;
  padding: 0 .5em;
  border-radius: $corner-radii-pill;
  font-size: $font-size-medium;
  display: inline-block;
  transition: $transition-fast;

  &--primary {
    background: $color-primary;
    color: $color-white;
  }

  &--danger {
    background: $color-danger;
    color: $color-white;
  }

  &--warning {
    background: $color-warning;
    color: $color-white;
  }

  &--inverse {
    background: $color-white;
    &.w-tag--primary {
      color: $color-primary;
    }

    &.w-tag--danger {
      color: $color-danger;
    }

    &.w-tag--warning {
      color: $color-warning;
    }
  }

  &--selectable {
    user-select: none;
    cursor: pointer;

    &:hover {
      background: $color-gray;
      color: $color-white;

      &.w-tag--primary {
        background: $color-primary-darker;
      }

      &.w-tag--danger {
        background: $color-danger-darker;
      }
    }
  }

  &--selected {
    background: $color-gray;
    color: $color-white;

    &.w-tag--primary {
      background: $color-primary-darker;
    }

    &.w-tag--danger {
      background: $color-danger-darker;
    }

  }

  &--small {
    font-size: $font-size-small;
    font-weight: 500;
    height: 15px;
    line-height: 15px;
  }

  & + .w-tag {
    margin-left: 3px;
  }

  &__close {
    margin-left: $spacing-xsmall;
    margin-right: -4px;
  }
}
