.simulator {
  width: 80%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.simulator__message {
  width: 100%;
  text-align: center;
}

.simulator__message > .w-message__content {
  color: var(--w-color-white);
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.simulator__message--saved > .w-message__content {
  background-color: var(--w-color-warning)
}

.simulator__message-action {
  color: var(--w-color-white);
  padding-left: .8rem;
  text-decoration: underline;
}

/* this used to live in src/views/debug/debug-transaction/legacy/style.css:1 */
.debug-result {
  overflow-x: unset;
}

/* this used to live in src/views/debug/debug-transaction/legacy/style.css:74 */
.results-content {
  position: absolute;
  width: 100%;
}

/* this used to live in src/views/debug/debug-transaction/legacy/style.css:79 */
.w-layout__row--fill .w-layout__column.results-content {
  background-color: #fafafb;
  height: auto;
}
