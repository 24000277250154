.navigate-to-creative {
	display: flex;
}

.navigate-to-creative__button {
	border: none;
	box-shadow: none;
	height: 25px;
	padding: 0;
	width: 25px;
}

.navigate-to-creative__button-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.navigate-to-creative__button-icon {
	font-size: 1.25rem;
}
