@import 'helpers';

.w-copyable {
  position: relative;
  cursor: copy;

  &:after {
    transition: .2s;
    bottom: -50px;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: $color-white;
    font-family: $font-family-sans-serif;
    font-size: $font-size-small;
    font-weight: $font-weight-semibold;
    display: inline-block;
    padding: 3px $spacing-small;
    border-radius: $corner-radii-pill;
    box-shadow: $shadow-depth-2;
    z-index: $z-index-tooltip;
    content: 'Copied';
    user-select: none;
    pointer-events: none;
  }

  &--active {


    &:after {

      opacity: 1;

      bottom: -30px;

    }
  }
}
