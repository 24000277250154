.whitelist {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	top: 70px;
	width: 100%;
	z-index: 5;
}

.whitelist .w-modal__content.w-modal__content--large {
	max-height: calc(100vh - 100px);
}

.whitelist > .w-modal__content {
	margin: 30px 21px 30px 30px;
	padding: 0 9px 0 0;
}

.whitelist > .w-modal__background {
	background-color: var(--w-color-white);
}

.whitelist__title {
	text-align: center;
	margin: 0 0 1rem 0;
}

.whitelist__add,
.whitelist__error,
.whitelist__search,
.whitelist__table {
	width: 100%;
}

.whitelist__add {
	border-bottom: 1px solid var(--w-color-gray-light);
}

.whitelist__ellipsis {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.whitelist__meta {
	display: flex;
	justify-content: space-between;
	margin: 0 0 1rem 0;
}

.whitelist__new-button {
	background-color: var(--w-color-primary);
	border: none;
	border-radius: 12px;
	box-shadow: none;
	height: 25px;
	margin: 0 0 0 .65rem;
	padding: 0;
	width: 25px;
}

.whitelist__new-icon {
	color: var(--w-color-white);
	font-size: 1.25rem;
}

.whitelist__count {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 0 0 .7rem;
}

.whitelist__count-label {
	font-size: 1rem;
	font-weight: bold;
}

.whitelist__count-value {
	font-size: 1rem;
	font-weight: normal;
	margin: 0 0 0 .2rem;
}

.whitelist__button {
	border: none;
	box-shadow: none;
	height: 25px;
	padding: 0;
	width: 25px;
}

.whitelist__button--spinner {
	padding-top: 8px;
}

.whitelist__button-icon {
	font-size: 1.25rem;
}

.whitelist__button-icon--error {
	color: var(--w-color-danger);
}

.whitelist__button-cancel,
.whitelist__button-delete,
.whitelist__button-lookup {
	background: transparent;
}

.whitelist__button-lookup {
	margin-left: 3px;
}

.whitelist__button-lookup .whitelist__button-icon {
	font-size: 1.6rem;
}
