@import 'helpers';

.w-menu-container {
  width: 180px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .05);
  padding: $spacing-small 0;
  background-color: $color-white;

  &.menu-list--small {
    width: 150px;
  }

  &.menu-list--contextual {
    width: 200px;
  }

}

.w-menu {
  .w-menu-list__item {
    user-select: none;
  }
}
