@keyframes asset-validator__menu_item--pulse {
  0% { background: var(--w-color-white); }
  30% { background: var(--w-color-primary-lighter); }
  70% { background: var(--w-color-primary-lighter); }
  100% { background: var(--w-color-white); }
}

.asset-validator {
  height: 100vh;
}

.asset-validator__column {
  background-color: var(--w-color-off-white);
}

.asset-validator__header_row {
  height: initial; /* Fix issue with Chrome 73 */
}

.asset-validator__menu_item {
  color: black;
  height: 4.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  position: relative
}

.asset-validator__menu_item.asset-validator__menu_item--selected,
.asset-validator__menu_item.asset-validator__menu_item--selected:hover {
  background: var(--w-color-primary)
}

.asset-validator__menu_item:hover {
  background: var(--w-color-primary-light)
}

.asset-validator__menu_item-icon--warning {
  color: var(--w-color-warning);
}

.asset-validator__menu_item-icon--danger {
  color: var(--w-color-danger);
}

.asset-validator__menu_item-icon--success {
  color: var(--w-color-success);
}

.asset-validator__menu_item:hover .asset-validator__menu_item-icon--success,
.asset-validator__menu_item:hover .asset-validator__menu_item-icon--danger,
.asset-validator__menu_item:hover .asset-validator__menu_item-icon--warning,
.asset-validator__menu_item:hover .delete-icon {
  color: var(--w-color-gray-dark);
}

.asset-validator__menu_item--selected .asset-validator__menu_item-icon--success,
.asset-validator__menu_item--selected:hover .asset-validator__menu_item-icon--success,
.asset-validator__menu_item--selected .asset-validator__menu_item-icon--danger,
.asset-validator__menu_item--selected:hover .asset-validator__menu_item-icon--danger,
.asset-validator__menu_item--selected .asset-validator__menu_item-icon--warning,
.asset-validator__menu_item--selected:hover .asset-validator__menu_item-icon--warning,
.asset-validator__menu_item--selected .delete-icon,
.asset-validator__menu_item--selected:hover .delete-icon  {
  color: var(--w-color-white);
}

.asset-validator__menu_item--repeat {
  animation: asset-validator__menu_item--pulse 1.75s;
}

.media-info, .client-specifications {
  padding: 1em 1em 0 1em;
  margin: 1em 1em 0 1em;
}

.add-uri-list-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.validator_cell {
  padding: 0.5em 1.2em;
  text-overflow: ellipsis;
}

.validator_cell-icon {
  width: 7.5%;
}

.validator_cell-label {
  width: 30%;
}

.validator_cell-value {
  width: 12.5%;
}

.validator_cell-condition {
  width: 50%;
}

#validator_spectable-sidebar {
  flex: 1;
}

#validator_spectable-detail_pane {
  flex: 2;
}

.creative_item-icon {
  padding: 0 1rem 0 0;
}

.asset-validator__menu_item .asset-validator__menu_item-delete {
  position: absolute;
  right: 10px;
  top: 20px;
  display: none;
  text-align: center;
}

.asset-validator__menu_item:hover .asset-validator__menu_item-delete {
  display: inline-block
}

.creative_drop-area {
  display: flex;
  flex: 1 1 0;
}

.creative_drop-area:focus {
  outline: none;
}

.creative_uri-list {
  min-width: 350px;
  max-width: 500px;
}

.creative_uri-list > .w-layout__column__content {
  padding: 0;
}

.w-layout__row--fill .w-layout__column.results-container.creative_uri-list {
  height: auto; /* Fix issue with Chrome 73 */
}

.asset-validator__summary {
  display: flex;
  justify-content: space-between;
}

.asset-validator__summary .w-heading {
  margin: 0;
}

.asset-validator__summary_outcome {
  padding: .5rem 0;
}

.asset-validator__summary_detail {
  margin: 0;
  padding: .5rem 0 .5rem 1rem;
  white-space: nowrap;
}

.asset-validator__summary_message {
  padding: 0 .5rem 0 1rem;
}

.asset-validator__summary_name {
  font-style: italic;
  padding: 0;
  white-space: nowrap;
}

.asset-validator__summary_outcome--success .asset-validator__summary_message {
  color: var(--w-color-success);
}

.asset-validator__summary_outcome--success .asset-validator__summary_name {
  color: var(--w-color-success);
}

.asset-validator__summary_outcome--failure .asset-validator__summary_message {
  color: var(--w-color-danger);
}

.asset-validator__summary_outcome--failure .asset-validator__summary_name {
  display: none;
}

.asset-validator__summary_heading {
  overflow: hidden;
  white-space: nowrap;
}

.asset-validator__link {
  display: flex;
}

.asset-validator__link--expanded {
  flex-direction: column;
}

.asset-validator__link--expanded .asset-validator__link_url {
  padding: 0;
}

.asset-validator__link--collapsed {
  flex-direction: row;
}

.asset-validator__link--collapsed .asset-validator__link_url {
  padding: 0 1rem 0 0;
}

.asset-validator__link_url {
  overflow-wrap: break-word;
  word-break: break-word;
}

.asset-validator__link_expand {
  color: var(--w-color-gray);
}

/* spec-table */

#validator_spectable {
  background: white;
  display: flex;
  height: 100%;
}

.sidebar_header {
  padding: 20px;
  font-size: 11px;
  font-weight: var(--w-font-weight-semibold);
  border-bottom: solid 1px var(--w-color-gray-lighter);
}

.sidebar_container {
  border-bottom: solid 1px var(--w-color-gray-lighter);
  display: table-row;
  width: 100%;
  background: white;
}

.sidebar_container.sidebar_container--selected {
  background: var(--w-color-primary)
}

.sidebar_container a {
  display: table-cell;
  padding: 10px 20px;
  width: 100%;
  color: black;
  vertical-align: middle;
}
.sidebar_container--selected a {
  color: white
}

.sidebar_container .right_cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  width: 100%;
}

.sidebar_container .failure_badge {
  color: white;
  background: var(--w-color-danger);
  width: 1.5rem;
  border-radius: .75rem;
  height: 1.5rem;
  margin-right: 5px;
  display: inline-block;;
  text-align: center;
}

.sidebar_container--selected .failure_badge {
  color: var(--w-color-danger);
  background: white
}

.validator_spectable-detail_pane {
  background-color: var(--w-color-off-white);
  flex-grow: 2;
  padding: 0 0 3rem 0;
}

.validator_spectable-detail_pane--failure {
  padding: 0 0 9rem 0;
}

.validator_spectable-detail_pane .w-rule.w-rule--labeled {
  margin: 3.25rem 0;
}

.validator_spectable-detail_table tr:last-child {
  border-bottom: none;
}

.validator_spectable-detail_row {
  background: white;
  border-bottom: solid 1px var(--w-color-gray-lighter);

}

.validator_spectable-detail_row.validator_spectable-detail_row--failure {
  padding: 10px;
  background: var(--w-color-danger);
  border-bottom: none
}

.validator_spectable-detail_row td {
  padding: 10px;
  vertical-align: middle;
}

.validator_spectable-detail_row td:nth-child(2) {
  text-align: right;
  font-weight: bold;
  color: var(--w-color-gray-darker);
}

.validator_spectable-detail_row td:nth-child(3) {
  color: var(--w-color-success)
}

.validator_spectable-detail_row.validator_spectable-detail_row--failure td{
  color: white
}

.validator_spectable-match {
  color: var(--w-color-gray-darker);
  font-weight: bold;
}

.validator_spectable-error_count {
  color: var(--w-color-danger);
  font-weight: bold;
  padding: 0 10px;
}

.validator_spectable-detail_wrapper {
  background-color: var(--w-color-off-white);
  padding: 0 0 2rem 0;
}

.validator_spectable-detail_wrapper:last-child {
  padding: 0;
}

.validator_spectable-detail_wrapper--closest {
  padding: 0;
}

.validator_spectable-detail_padding {
  background-color: var(--w-color-white);
  border: solid 1px var(--w-color-gray-lighter);
  padding: 10px;
}

.validator_spectable-profiles_wrapper {
  background-color: var(--w-color-off-white);
  padding: 5rem 0 1.4rem;
  margin: 0 0 2rem 0;
  text-align: center;
}

.validator_spectable-profiles_button {
  border: none;
  border-radius: 1.2rem;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.5);
  color: var(--w-color-primary);
  position: fixed;
  bottom: 1.4rem;
}

.validator_spectable-profiles_button:focus {
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.5);
  color: var(--w-color-primary)
}

.validator_spectable-profiles_button--show-profiles {
  right: calc((75% - (75% / 2)) - 4.5rem);
}

.validator_spectable-profiles_button--hide-profiles {
  right: calc((75% - (75% / 2)) - 6.5rem);
}

@media (max-width: 1400px) {
  .validator_spectable-profiles_button--show-profiles {
    right: calc(((100% - 350px) / 2) - 4.5rem)
  }

  .validator_spectable-profiles_button--hide-profiles {
    right: calc(((100% - 350px) / 2) - 6.5rem)
  }
}

@media (min-width: 2880px) {
  .validator_spectable-profiles_button--show-profiles {
    right: calc(((100% - 720px) / 2) - 4.5rem)
  }

  .validator_spectable-profiles_button--hide-profiles {
    right: calc(((100% - 720px) / 2) - 6.5rem)
  }
}
