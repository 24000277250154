.dialog {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9997;
}

.dialog--background {
  background-color: black;
  height: 100vh;
  left: 0;
  opacity: .6;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.dialog--wrapper {
  background: white;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.17);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.dialog--header {
  border-bottom: solid 1px #efefef;
  color: #1a1a1a;
  font-size: 20px;
  font-weight: bold;
  padding: 1rem 0;
  text-align: center;
}

.dialog--content {
  display: flex;
  overflow-y: auto;
  padding: 1rem 0;
}

.dialog--footer {
  border-top: solid 1px #efefef;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 0 0;
}

.dialog--close {
  color: var(--w-color-white);
  cursor: pointer;
  position: absolute;
  right: 34px;
  top: 20px;
}
