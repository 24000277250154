@import 'helpers';

.w-rule {
  border-top: 1px solid $color-gray-lighter;
  display: block;
  width: 100%;
  margin: $spacing-large 0;

  &--labeled {
    display: flex;
    align-items: center;
    border-top: 0;

    .w-rule__label {
      font-size: $font-size-small;
      font-weight: $font-weight-semibold;
      color: $color-gray;
      display: inline-block;
      white-space: nowrap;
      padding: 0 $spacing-small;
    }

    &::before,
    &::after {
      content: '';
      background: $color-gray-lighter;
      display: inline-block;
      width: 100%;
      height: 1px;
    }
  }
}
