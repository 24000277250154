@import 'helpers';

@include font-face(
  'Mock Flow',
  inline-font-files('MockFlowFont1.woff', 'woff'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-Regular.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-Italic.woff2', 'woff2'),
  $font-weight-normal,
  italic
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-Medium.woff2', 'woff2'),
  $font-weight-semibold,
  normal
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-MediumItalic.woff2', 'woff2'),
  $font-weight-semibold,
  italic
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-Bold.woff2', 'woff2'),
  $font-weight-bold,
  normal
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-BoldItalic.woff2', 'woff2'),
  $font-weight-bold,
  italic
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-Light.woff2', 'woff2'),
  $font-weight-light,
  normal
);

@include font-face(
  'Roboto',
  inline-font-files('Roboto-LightItalic.woff2', 'woff2'),
  $font-weight-light,
  italic
);

@include font-face(
  'Roboto Mono',
  inline-font-files('RobotoMono-Regular.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Roboto Mono',
  inline-font-files('RobotoMono-Medium.woff2', 'woff2'),
  $font-weight-semibold,
  normal
);

@include font-face(
  'Material Icons',
  inline-font-files('MaterialIcons-Regular.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Spark Dot Medium',
  inline-font-files('spark-dot-medium.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Spark Dot Small',
  inline-font-files('spark-dot-small.woff2', 'woff2'),
  $font-weight-normal
  normal
);

@include font-face (
  'Spark Bar Narrow',
  inline-font-files('spark-bar-narrow.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

@include font-face(
  'Spark Dotline Narrow',
  inline-font-files('spark-dotline-medium.woff2', 'woff2'),
  $font-weight-normal,
  normal
);

.material-icons {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
