.expand-collapse {
	display: flex;
	align-items: center;
}

.expand-collapse__button {
	background-color: transparent;
	border: none;
	box-shadow: none;
	height: 25px;
	padding: 0;
	width: 25px;
}

.expand-collapse__button:focus {
	box-shadow: none;
}

.expand-collapse__button-icon {
	font-size: 1.25rem;
}
