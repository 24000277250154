.whitelist-add-lookup__spinner {
	margin: 7px 0 0 8px;
}

.whitelist-add-lookup__background {
	bottom: 0;
	left: 0;
	pointer-events: all;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 4;
}

.whitelist-add-lookup__list {
	background: white;
	border: 1px solid transparent;
	border-radius: 3px;
	box-shadow: 0 0 0 1px var(--w-color-gray-lighter), 0 30px 30px rgba(0,0,0,.2);
	cursor: pointer;
	max-height: 15rem;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: .5rem;
	position: absolute;
	right: 35px;
	top: 35px;
	width: calc(100% - 25px - 10px); /* allow for width of lookup button  plus spacing */
	z-index: 5;
}

.whitelist-add-lookup__item {
	border-radius: .3rem;
	line-height: 1.8rem;
	padding: 0 .3rem;
	overflow: hidden;
}

.whitelist-add-lookup__item:hover {
	background: var(--w-color-primary-light);
}
