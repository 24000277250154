.debug-view {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
}

.debug-view__header {
	display: flex;
	height: 79px; /* safari */
	width: 100%;
}

.debug-view__content {
	display: flex;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	overflow-y: scroll; /* safari */
}

.debug-view__content-lhs {
	margin: 0 0 0 15px;
	min-width: 200px;
	position: relative; /* for legacy sidebar */
	width: 200px;
}

.debug-view__content-rhs {
	margin: 0 15px 0 0;
	min-width: 200px;
	width: 200px;
}

.debug-view__controls {
	display: flex;
	justify-content: center;
	width: 100%;
}

.debug-view__content-container {
	display: flex;
	max-width: 1280px;
	width: 100%;
}

.debug-view__pending {
	display: flex;
	height: 21px;
	justify-content: center;
	width: 100%;
}

.debug-view__pending-prompt,
.debug-view__pending-placeholder {
	height: 1.35rem;
	line-height: 1.35rem;
	padding: 0 .5em;
}

.debug-view__pending-prompt {
	background: var(--w-color-primary);
	border-radius: 1rem;
	color: var(--w-color-white);
	cursor: pointer;
}

.debug-view__filters {
	display: grid;
	grid-template: "platforms assets dates fills" auto / 300px 300px auto 300px;
	width: 100%;
}

.debug-view__filters.debug-view__filters--facets {
	display: flex;
	justify-content: center;
}

.debug-view__platform-filter,
.debug-view__asset-filter,
.debug-view__date-filter,
.debug-view__fill-filter {
	display: flex;
	justify-content: center;
}

.debug-view__platform-filter {
	grid-area: platforms;
}

.debug-view__asset-filter {
	grid-area: assets;
}

.debug-view__date-filter {
	grid-area: dates;
}

.debug-view__date-filter.debug-view__date-filter--facets {
	width: 400px;
}

.debug-view__date-picker {
	grid-area: dates;
}

.debug-view__date-picker.debug-view__date-picker--facets {
	width: 400px;
}

.debug-view__fill-filter {
	grid-area: fills;
}

.debug-view__controls-container {
	display: flex;
	flex-direction: column;
	height: 90px;
	position: relative;
	max-width: 1250px;
	width: 100%;
}

.debug-view__controls-container--facets {
	height: 75px;
}

.debug-view__transactions {
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
	z-index: 0;
}

.debug-view__side {
	background-color: var(--w-color-off-white);
	display: flex;
	flex-direction: column;
	justify-self: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 200px;
	z-index: 1;
}

.debug-view__side .debug-group-filter {
	justify-content: center;
}

.debug-view__side .debug-flagged-filter {
	justify-content: center;
}

.debug-view__meta {
	display: flex;
	justify-content: center;
	margin: 2.5rem 0 0 0;
}

.debug-view__toggles {
	margin: 2.5rem 0 0 0;
}

.debug-view__toggle {
	margin: 0 0 1rem 0;
}

.debug-view__reset {
	display: flex;
	justify-content: center;
	margin: 2.5rem 0 0 0;
}

.debug-view__reset-button {
	color: var(--w-color-gray-light);
	border-color: var(--w-color-gray-light);
}

.debug-view__reset-button:hover {
	color: var(--w-color-gray);
	border-color: var(--w-color-gray);
}

.debug-view__notify {
	min-width: 35rem;
}

.debug-view__message {
	color: #b3b3b3;
	font-size: 2rem;
	margin-top: 15%;
	text-align: center;
	width: 100%;
}

.debug-view__loader {
	display: flex;
	height: 21px;
	justify-content: center;
	padding: 2rem 0 5rem;
	width: 100%;
}

.debug-view__loader-prompt {
	background: var(--w-color-primary);
	border-radius: 1rem;
	color: var(--w-color-white);
	cursor: pointer;
	height: 1.35rem;
	line-height: 1.35rem;
	padding: .2em .5em;
}

.debug-view__sidebar {
	height: calc(100vh - 70px);
	margin-right: -400px;
	opacity: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	transition: all 0.3s ease;
	width: 400px;
	z-index: 5;
}

.debug-view__sidebar--open {
	box-shadow: -5px 10px 15px var(--w-color-gray-light);
	margin-right: 0;
	opacity: 1;
}
