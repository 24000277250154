.reporting {
  display: flex;
  flex-flow: column;
  height: 80vh;
}

.reporting__label {
  font-weight: bold;
}

.reporting__field {
  margin-top: .1rem;
  margin-bottom: 1rem;
}

.reporting__field__select {
  width: 100%;
}

.reporting__field__icon {
  margin-left: .5rem;
  cursor: pointer;
}

.reporting__heading {
  margin-bottom: var(--w-spacing-large);
}

.reporting__range-item {
  margin-bottom: var(--w-spacing-small);
  display: flex;
  flex-flow: row;
}

.reporting__range-item__radio {
  margin-top: 3px;
  margin-right: var(--w-spacing-small);
}

.reporting-specific-month__selected-month {
  margin-top: var(--w-spacing-small);
  align-self: stretch;
  justify-self: stretch;
}

.reporting__custom {
  display: flex;
  flex-flow: column;
}

.reporting__custom__date-label {
  margin-top: var(--w-spacing-xsmall);
  font-size: var(--w-font-size-small);
  font-weight: bold;
}

.reporting__picker {
  position: relative;
  top: -1rem;
}

.reporting__download-button {
  margin-top: var(--w-spacing-small);
}

.reporting__utc {
  margin-top: var(--w-spacing-small);
  font-size: x-small;
  text-align: center;
}
