@import 'helpers';

.w-select {
  display: inline-block;
  position: relative;

  &::after {
    @include arrow($color-gray-light);

    margin-top: -.375em;
    right: 1.125em;
    top: 50%;
    z-index: 4;
  }

  select,
  .w-select__value {
    @include input;

    cursor: pointer;
    padding-right: 2.25em !important;

    option:not(:disabled) {
      color: $color-gray;
    }

    option:disabled {
      color: $color-gray-light;
    }

    &::-ms-expand {
      display: none;
    }
  }

  // States
  &:hover {
    &::after {
      border-color: $color-gray-dark;
    }
  }

  .w-select__value {
    display: inline-block !important;
    visibility: hidden;
    height: 0;
    position: absolute !important;
    user-select: none;
    pointer-events: none;
    width: auto !important;
    white-space: nowrap;
    overflow-x: visible;
    max-width: none !important;
  }

  // Modifiers
  &--autosize {
    width: auto;
  }

  &--readonly {
    &::after {
      display: none;
    }
  }

  &--danger {
    z-index: 2;

    &::after {
      border-color: $color-danger;
    }

    &:hover::after {
      border-color: $color-danger-dark;
    }

    select {
      box-shadow: 0 0 0 1px $color-danger;

      &:hover {
        box-shadow: 0 0 0 1px $color-danger-dark;
      }

      &:focus,
      &:focus-within,
      &:active {
        box-shadow: 0 0 0 2px $color-danger-darker;
      }
    }
  }

  // Sizes
  &--small {
    &::after {
      width: .35em;
      height: .35em;
      margin-top: -.125em;
      right: .75em;
    }

    select,
    .w-select__value {
      font-size: $font-size-small;
      height: 22px;
      font-weight: $font-weight-semibold;
    }
  }

  &--large {
    select,
    .w-select__value {
      font-size: $font-size-large;
      height: 44px;
    }
  }

  &.w-select--subtle select {
    box-shadow: none;
    background: transparent;
    padding-left: 0;
    color: $color-gray-light;

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}
